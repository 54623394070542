// tslint:disable:max-line-length
import React, { ReactNode, Ref} from "react";
import { Col, Form, Nav, Row,Button, Tab, Popover, Dropdown, ButtonGroup } from "react-bootstrap";

import CollapseCard from "./CollapseCard"
import Dropzone from "react-dropzone";

import { flatCardList, getFirstTabKey, getTabItems, IUiSchemaCardArgs, IUiSchemaElemArgs, IUiSchemaSetValueResult, logTime, renderSchema, Self, updateValues } from "./SchemaController";
import { IUiActionButton, IUiSchema, IUiSchemaPanelOptions, UiSchemaTrust } from "../UiJsonSchemaTypes";


import { evalExpr, evalString, IExprScope } from "./SchemaTools";
// Controllers

import { HoverOverlay } from "./HoverOverlay";
import { isMobile } from "react-device-detect";
import { registeredExtensionCardHandlers, registeredLayoutComponents, registerExtensionCardHandler } from "./SchemaExtensions";
import DateRangePicker from "./ExtDateRangePicker";

import { embedObject, onDropFile } from "./SchemaEmbedObject";
import { fa, formatText, ITextContext, ITextOptions } from "./SchemaTextParser";


const sizeClass = {
	"col-3": "schema-engine-card-width-column3",
	"col-2": "schema-engine-card-width-column2",
	"col-1": "schema-engine-card-width-column1",
	"fill-100%": "schema-engine-card-100-percent"
};


// This should be moved to the style CSS.
const loaderStyle: any = {
	position: 'absolute',
	top: "50%",
	left: "50%"
}
const loaderMobileStyle: any = {
	position: 'absolute',
	top: "40%",
	left: "45%"
}



/**
 * card handlers
 */
function addCollapsibleCardHandle(args: IUiSchemaCardArgs, children: ReactNode) {

	const { layoutOptions, key, self, hasError, title, readOnly } = args;
	const { objects } = self;

	const scope = { readOnly };
	const collapsible = layoutOptions.cardStyle === "uncollapsed" || layoutOptions.cardStyle === "collapsed";
	const cardState   = objects.values["__cardstate_" + key] ?? layoutOptions.cardStyle === "uncollapsed";
	const showError   = collapsible && !cardState && hasError;
	const titleObj    = (title || collapsible) ? 
		<div className="schema-engine-card-header-title">
			{args.stringToComponent(title)}
			{showError ? " " : ""}
			{showError ? fa("fa-circle-exclamation", {color:"red"}) : null}
		</div> : null;

	const bodyWrapClass = {
		"dashboard":        "schema-engine-dashboard-card-layout-1",
		"dashboard-center": "schema-engine-dashboard-card-layout-2",
		"dashboard-flex":   "schema-engine-dashboard-card-layout-3",
	}[layoutOptions.cardStyle];

	const gridCard  = layoutOptions.cardStyle?.startsWith("dashboard");
	const cardClass = (layoutOptions.cardStyle?.startsWith("dashboard") ? "schema-engine-dashboard-card" : "schema-engine-card")
						+ (gridCard ? " grid-stack-item-content" : "") 
						+ " " + sizeClass[layoutOptions.cardSize];

	const reactActionButtons = getActionButtons(args.actionButtons, self, scope,
									args.updateValues, 
									(trust: UiSchemaTrust, b: string, c: string) => args.stringToComponent(b)) ;

	const body = bodyWrapClass ? (<div className={bodyWrapClass}>{children}</div>) : children;
	const card = (
		<CollapseCard id={key} key={key} header={titleObj} headerRight={reactActionButtons} body={body}
				className={cardClass}
				state={cardState}
				collapsible={collapsible}
				onFinishAnimation={args.updateLayout}
				onChangeState={() => args.updateValues({ values: { ["__cardstate_" + key]: !cardState }})}
		/>
	);

	return gridCard ? <div className="grid-stack-item" id={key} key={key}>{card}</div> : card;
}

function addNoFrameCardHandle(args: IUiSchemaCardArgs, children: ReactNode) {

	const { key } = args;
	return <div key={key} id={key} >{children}</div>;
}

registerExtensionCardHandler("normal",           addCollapsibleCardHandle);
registerExtensionCardHandler("collapsed",        addCollapsibleCardHandle);
registerExtensionCardHandler("uncollapsed",      addCollapsibleCardHandle);
registerExtensionCardHandler("dashboard",        addCollapsibleCardHandle);
registerExtensionCardHandler("dashboard-center", addCollapsibleCardHandle);
registerExtensionCardHandler("dashboard-flex",   addCollapsibleCardHandle);
registerExtensionCardHandler("noframe",          addNoFrameCardHandle);


interface IUiActionButtonExt extends IUiActionButton {
	ref?: Ref<any>;
} 

/**
 * getActionButtons take an array of actionButtons (IUiActionButton) and generate the actual
 * react buttons
 * 
 */

function getActionButtons(actionButtons: IUiActionButtonExt[], self: Self, scope: IExprScope,
						  updateValues: (update: IUiSchemaSetValueResult) => void,
						  parseAndFormatText: (trust: UiSchemaTrust, str: string, key: string) => ReactNode,
						  subButton?: "dropdown" | "button-group") {

	if (!actionButtons || actionButtons.length === 0) { return null; }

	const reactButtons: ReactNode[] = [];
	const ms = subButton === "button-group" ? "" : " ms-1";		// Margin style to use for the buttons

	// Render the button array. This implementation using array will make it easy to add more custom buttons
	// later...
	for (let idx = 0; idx < actionButtons.length; idx++) {

		const but = actionButtons[idx];
		const butUi = but.uiSchema || {};
		const trust = but.uiSchema.trust;

		if (butUi.hidden === true 
			|| (typeof butUi.hidden === "string" && evalExpr(trust, butUi.hidden, self.lib, self.objects, scope, false))) {
			continue
		}

		const evReadOnly = typeof butUi.readOnly === "boolean" ? butUi.readOnly
							: typeof butUi.readOnly === "string"
								? evalExpr(trust, butUi.readOnly, self.lib, self.objects, scope, false) : null;
		const readOnly = typeof evReadOnly === "boolean" ? evReadOnly : self.objects.control.readOnly;

		const value = butUi.getValue    && evalExpr(trust, butUi.getValue, self.lib, self.objects, scope, false);
		const title = but.title       && parseAndFormatText(but.uiSchema?.trust, but.title, "action-button-txt-" + idx);
		const desc  = but.description && parseAndFormatText(but.uiSchema?.trust, but.description, "action-button-desc-" + idx);
		let   onClick = !butUi.setValue ? null : () => updateValues(evalExpr(trust, butUi.setValue, self.lib, self.objects, scope, {}) || {});

		const wrapHelp = (obj: ReactNode, key: string) => {
			return desc ? (<HoverOverlay
				key={key}
				big={false}
				overlay={(<>
					<Popover.Header>Help</Popover.Header>
					<Popover.Body>{desc}</Popover.Body>
					</>)}
				>
				<span>{obj}</span>
			</HoverOverlay>) : obj;
		}

		let obj: ReactNode = null;
		const key = "action-button-" + idx;

		if (but.buttons) {

			const type = butUi.type === "button-group" ? "button-group" : "dropdown"
			const buttons = getActionButtons(but.buttons, self, scope, updateValues, parseAndFormatText, type);

			if (type === "button-group") {

				reactButtons.push(
					wrapHelp(
						<ButtonGroup className={ms} key={key}>
							{buttons}
						</ButtonGroup>,
						key
					)
				);

			} else {
				reactButtons.push(
					wrapHelp(
						<Dropdown as={ButtonGroup} key={key}>
							<Dropdown.Toggle 
								className={butUi.type === "borderless" ? "schema-engine-dropdown-no-frame py-0" : ms}
								variant={butUi.type === "borderless" ? "dark-link" : "outline-dark"}>
								{title}
							</Dropdown.Toggle>
							<Dropdown.Menu>
								{buttons}
							</Dropdown.Menu>
						</Dropdown>,
						key
					)
				);
			}

		} else if (butUi.type as any === "radio") {

			const args: IUiSchemaElemArgs = {
				key,
				fullkey: key,
				elem: {},
				uiElem: butUi,
				readOnly,
				elemReadOnly: readOnly,
				required: false,
				title: null,
				description: null,
				helpLink: null,
				value,
				values: {},
				error: null,
				errors: null,
				enums: butUi.enum,
				enumLabels: butUi.enumLabels?.reduce((p, c) =>  ({ ...p, [c.value]: c.label }), {}),
				update: (update) => butUi.setValue && updateValues(evalExpr(butUi.trust,
																   butUi.setValue, self.lib, self.objects, { ...scope, value: update.value }, {}) || {}),
				type: butUi.type,
				objects: self.objects,
				lib: self.lib,
				self,
				embedObject: (obj: JSX.Element, flex) => embedObject(args, obj, flex),
				getSettings: null,
				stringToComponent: text => parseAndFormatText(butUi.trust, text, key),
			};

			obj = self.componentHandlers[butUi.type](args);

		} else if (butUi.type === "switch" || butUi.type === "checkbox") {

			obj = wrapHelp(
				<div className="m-2 float-start" key={"action-button-" + idx}>
					<Form.Check
						id={key}
						key={key}
						checked={value}
						type={butUi.type === "switch" ? "switch" : "checkbox"}
						reverse={true}
						label={title}
						disabled={readOnly}
						onChange={(ev) => {
							if (!readOnly) {
								ev.stopPropagation();
								const value = ev.target.checked;
								updateValues(evalExpr(butUi.trust, butUi.setValue, self.lib, self.objects, { ...scope, value }, {}) || {})
							}
						}}
					/>
				</div>,
				key
			);

		} else if (butUi.type === "borderless") {

			if (but.ref) {

				// TODO: should be made more general.
				// This is used for the special case of the readonly button. Here we encapsulate button in another div that will be used
				// to highlight the button.
				obj = wrapHelp(
					<div ref={but.ref} key={key} className="p-2 schema-engine-highlight-button">
						<a key={"action-button-" + idx} onClick={onClick} >
							{title}
						</a>
					</div>,
					key
				);

			} else {

				if (subButton === "dropdown") {
					reactButtons.push(
						wrapHelp(<Dropdown.Item key={key} as="div" eventKey={idx} onClick={onClick} >{title}</Dropdown.Item>, key)
					);
				} else {
					obj = wrapHelp(
						<a key={key} onClick={onClick} >)
							{title}
						</a>,
						key
					);
				}
			}


		} else if (butUi.type as any === "date-range-picker") {

			obj = wrapHelp(<DateRangePicker
				className={ms}
				key={key}
				title={title}
				onChange={(value) => {
					if (butUi.setValue) {
						updateValues(evalExpr(butUi.trust, butUi.setValue, self.lib, self.objects, { ...scope, value }, {}) || {})
					}
				}}
				range={value}
			/>, key);

		} else {

			obj = wrapHelp(<Button
				key={key}
				onClick={onClick}
				variant={(butUi.type === "primary" ? "dark" : "outline-dark") + ms}
				disabled={readOnly}
			>
				{title}
			</Button>, key);

		}

		if (obj) {
			if (subButton === "dropdown") {
				reactButtons.push(<Dropdown.Item key={key} as="div" eventKey={idx}>{obj}</Dropdown.Item>)
			} else {
				reactButtons.push(obj);
			}
		}

	}

	return (reactButtons.length === 0 ? null : reactButtons.length === 1 ? reactButtons[0] : reactButtons) as ReactNode;
}





/**
 * The main render function for the engine
 * 
 * @param self 
 * @returns 
 */


export function render(self: Self) {

	if (!self) { return null; }

	const { lib, objects } = self;
	const control = objects.control;

	// While not ready we do no further rendering
	if (!control.ready) {
		return (
			<Col sm={12} style={isMobile ? loaderMobileStyle : loaderStyle} className="LoaderWrapper">
				<i className="fa-regular fa-spinner fa-2x fa-spin"></i>
			</Col>
		);
	}

	self.updateLayout(300);		// we trigger an post layout update of the masonry after 300ms: FIXME: I think we can remove this now.
	const ts0 = Date.now();

	const rootCondJsonSchema = objects.rootCondJsonSchema;
	const uiSchema: IUiSchema = objects.rootJsonSchema?.$uiSchema || {};

	const layoutOptions = {...self.props.defaultLayoutOptions, ...uiSchema.layoutOptions };
	const activeTabKey = control.activeTab || getFirstTabKey(self) || "";
	const uiTabItems = getTabItems(self, activeTabKey, objects);
	const readOnly = !!control.readOnly;

	// const uiHeader = this.renderSchema("$header", condJsonSchema, layoutOptions);
	// const uiBody = this.renderSchema("$body");


	const defPanels: {
		[key: string]: IUiSchemaPanelOptions;
	} = {
		defpanel: {
			title: "",
			cards: Object.keys(uiSchema.cards || {}),
		},
	};

	const tabElems: JSX.Element[] = [];
	const tabview = uiTabItems.length > 0;		// FIXME: make this optional
	const panels = (tabview ? uiSchema.panels : defPanels) || {};
	let panelContent: ReactNode;

	for (const tabkey of Object.keys(panels)) {
		const panel = panels[tabkey];
		const reactCards: ReactNode[] = [];
		const tabLayoutOptions = {...layoutOptions, ...panel.layoutOptions};

		// If we are rendering a tabbed panel view, there are three options to decide if the inactive
		// panels are rendered or not; render-always, render-when-visible and render-after-visible (default).
		if (tabview) {
			if (tabLayoutOptions.panelRenderMode !== "render-always" && activeTabKey !== tabkey && 
					(!self.loadedPanels[tabkey] || tabLayoutOptions.panelRenderMode === "render-when-visible")) {
				tabElems.push(<Tab.Pane key={tabkey} eventKey={tabkey}><div></div></Tab.Pane>);
				continue;
			}
			self.loadedPanels[tabkey] = true;
		}


		// First we process all the cards regardless of the layout structure, so we first extract all the
		// card is from the list
		const panelCardsMap = {};
		flatCardList(panelCardsMap, panel.cards);

		for (const cardkey of Object.keys(panelCardsMap)) {
			if (!uiSchema.cards || !uiSchema.cards[cardkey]) { continue; }

			const card = uiSchema.cards[cardkey];
			const hidden: boolean | null = card.hidden == null ? null
						: typeof card.hidden === "boolean" ? card.hidden
							: typeof card.hidden === "string" && card.hidden
								? evalExpr(card.trust, card.hidden, lib, objects, { readOnly }, false) : null;
			if (hidden === true) { continue; }
			const cardLayoutOptions = {...tabLayoutOptions, ...card.layoutOptions}

			// Check if we need to render this card, depending on it collapsed state and the renderMode options.
			const cardState = objects.values["/__cardstate_" + cardkey];
			let renderCard = true;
			if (cardState === false || (cardState == null && cardLayoutOptions.cardStyle === "collapsed")) {
				// Content is hidden. 
				if (cardLayoutOptions.cardRenderMode === "render-when-visible" ||
					(cardLayoutOptions.cardRenderMode === "render-after-visible" && !self.loadedCards[cardkey])) {
						renderCard = false;
				}
			}
			if (renderCard) { self.loadedCards[cardkey] = true; }

			// TODO: when renderCard === false, we should not call this function, but use a lighter one that
			// render check only error the card.

			const cardData = renderSchema(self, cardkey, rootCondJsonSchema, cardLayoutOptions);
			
			// Normally there is only one card, BUT, it is possible in some dynamically generate 
			// additional cards, so we have to loop over the cards again.

			for (let subCardIdx = 0; subCardIdx < cardData.cards.length; subCardIdx++) {
				const subCard = cardData.cards[subCardIdx];

				if (subCard.jsxElements.length > 0 || hidden === false) {

					if (registeredExtensionCardHandlers[cardLayoutOptions.cardStyle]) {

						const args: IUiSchemaCardArgs = {
							key: cardkey + (subCardIdx > 0 ? ":" + subCardIdx : ""),
							readOnly,
							title: card.title,
							hasError: cardData.hasError,
							layoutOptions: cardLayoutOptions,
							actionButtons: card.actionButtons,
							
							self,
							getSettings: self.props.getSettings,

							updateValues: (values) => updateValues(self, values, "", ""),
							updateLayout: () => self.updateLayout(),

							embedObject: (obj: JSX.Element, flex) => embedObject(args as any, obj, flex),		// FIXME:
							stringToComponent: (text: string) => {
								const txt = evalString(card.trust, text, lib, objects, { readOnly }) + "";
								const jsx = formatTextArgs(self, card.trust, txt, null, cardkey);
								return jsx;
							}
						}

						reactCards.push(registeredExtensionCardHandlers[cardLayoutOptions.cardStyle](args, renderCard ? subCard.jsxElements : []));

					} else {

						reactCards.push(subCard.jsxElements);
					
					}

				}
			}
		}


		const SchemaLayoutComponent = registeredLayoutComponents[tabLayoutOptions.panelLayout];
		
		if (SchemaLayoutComponent) {
			const layoutKey = "__panel_" + tabkey + "_layoutData";
			const onLayoutDataChange = (layoutData: any) => {
				updateValues(self, { values: { [layoutKey]: layoutData } }, "", "");
			};
			panelContent = (
				<SchemaLayoutComponent ref={self.reLayoutCtrl.layoutRef} cardList={panel.cards} layoutData={objects.values[layoutKey] || {}} onLayoutDataChange={onLayoutDataChange}>
					{ reactCards }
				</SchemaLayoutComponent>
			);
		} else if (tabLayoutOptions.panelLayout === "none") {
			panelContent = reactCards;		// FIXME: fix the types
		} else {
			panelContent = undefined;
		}

		if (tabview) {
			tabElems.push(
				<Tab.Pane key={tabkey} eventKey={tabkey}>{panelContent}</Tab.Pane>
			);
		}
	}

	logTime(self, "render", Date.now() - ts0);



	const dropZoneEvt = (innerObj: ReactNode) => {
		if (uiSchema.modal?.dropFile && !control.readOnly) {
			return (
				<Dropzone noClick={uiSchema.modal.dropFile.openFileDialogOnClick !== true} 
					onDrop={files => {
						onDropFile(self, files, uiSchema.modal.dropFile, "root-dropfile", null)}
					}
				>
					{({getRootProps, getInputProps}) => (
						<section>
							<div {...getRootProps()}>
								<input {...getInputProps()} />
								{innerObj}
							</div>
						</section>
					)}
				</Dropzone>
			)
		}
		return innerObj;
	}


	if (tabview && tabElems.length > 1) {
		
		const scrollToTop = () => {
			const scrollElement:any =document.getElementById('schema-tab-modal')
			if(scrollElement){
				scrollElement.scrollTo({
					top: 0,
					behavior: "smooth",
				});
			}				
		}

		// FIXME: The uiTabItems below is of incompatible type

		return dropZoneEvt(
			<Tab.Container defaultActiveKey={getFirstTabKey(self)} onSelect={(e) => {scrollToTop()}}>
				<Row className="schema-engine-tabs mx-0">
					<Col sm={2} className="px-0">
						<Nav variant="pills" className="flex-sm-column flex-xs-row schema-tab-list">
							{uiTabItems as any}
						</Nav>
					</Col>
					<Col sm={10} className="px-0">
						<Tab.Content className="schema-engine-tabs-content" id="schema-tab-modal">
							{tabElems}
						</Tab.Content>
					</Col>
				</Row>
			</Tab.Container>
		);

	} else {
		return dropZoneEvt(panelContent);
	}

}	// render()





/**
 * 
 * @param tabKey 
 * @param isActive 
 * @param hasError 
 * @param title 
 * @param onClick 
 * @returns 
 */

export function addTabElem(tabKey: string, isActive: boolean, hasError: boolean, title: ReactNode, onClick: () => void) {
	return (
		<Nav.Item key={tabKey}>
			<Nav.Link
				className={(isActive ? "active" : "") + (hasError ? " has-error" : "")}
				eventKey={tabKey}
				onClick={onClick}
			>
				{title}
				{hasError ? " " : null}
				{hasError ? fa("fa-circle-exclamation", {color:"red"}) : null}
			</Nav.Link>
		</Nav.Item>
	);
}






export function updateLayout(self: Self, delay?: number) {
	delay = delay || 50;
	clearTimeout(self.reLayoutCtrl.updateLayoutTimer);
	self.reLayoutCtrl.updateLayoutTimer = setTimeout(() => {
		if (self.reLayoutCtrl.layoutRef.current) {
			(self.reLayoutCtrl.layoutRef.current as any).reLayout();
		}
	}, delay || 50);
}




/**
 * highlightUnlock() will put a highlight box over the unlock button
 * 
 * @param self 
 * @param target 
 * @returns 
 */
export function highlightUnlock(self: Self, target = 1) {

	const hl = self.highlightCtrl;
	if (target === hl.highlightTarget) { return; }
	hl.highlightTarget = target;
	
	const update = () => {

		clearTimeout(hl.highlightTimer);
		hl.highlightTimer = undefined;

		if (hl.highlightTarget !== hl.highlightValue && hl.overlayRef?.current && hl.unlockDivRef?.current) {

			hl.highlightValue += (hl.highlightValue > hl.highlightTarget ? -1 : 1) * 0.2;
			hl.highlightValue  = Math.max(0, Math.min(1, hl.highlightValue));

			hl.overlayRef.current.style.display      = hl.highlightValue > 0 ? "block" : "none";
			hl.overlayRef.current.style.background   = `rgba(0,0,0,${hl.highlightValue / 2})`;
			hl.unlockDivRef.current.style.zIndex     = hl.highlightValue > 0 ? "99999" : "1";
			hl.unlockDivRef.current.style.background = `rgba(255,255,255,${hl.highlightValue})`;

			if (hl.highlightValue !== hl.highlightTarget) {
				hl.highlightTimer = setTimeout(update, 20);
			} else {
				// After finishing the animation, if the unlock is not highlighted, we schedule the
				// return to normal immediately
				if (hl.highlightTarget) {
					hl.highlightTimer = setTimeout(() => highlightUnlock(self, 0), 1000);
				}
			}
		}
	}

	update();
}



/**
 * Get the control buttons of a schema view
 * 
 * @param self 
 * @param readOnly 
 * @param allowDebug 
 * @param debug 
 * @returns 
 */

export function getControlButtons(self: Self, readOnly: boolean, allowDebug: boolean, debug: boolean) {

	const rootJsonSchema = self.objects.rootJsonSchema

	if (!rootJsonSchema) { return []; }

	const uiSchema: IUiSchema = rootJsonSchema?.$uiSchema || {};
	const hasErrors = Object.keys(self.objects.errors || {}).length > 0;

	const buttons: IUiActionButton[] = [...(uiSchema.modal?.actionButtons ? uiSchema.modal.actionButtons : [])];
	const controlButtons = [];
	const control = self.objects.control;

	if (allowDebug) {
		const debugButton: IUiActionButton = {
			uiSchema: {
				type: "switch",
				getValue: debug ? "true" : "false",
				setValue: "({ debug: " + !debug + " })",
				readOnly: false,
			},
			title: "Debug",
		}
		buttons.unshift(debugButton);
	}

	if (!readOnly && !control.modalReadOnly && control.readOnly) {
		const unlockButton: IUiActionButtonExt = {
			uiSchema: {
				setValue: "({ readOnly: false })",
				type: "borderless",
				readOnly: false,
			},
			title: "[[fa-lock]] " + self.props.localeDictionary.click_to_unlock + " ",
			ref: self.highlightCtrl.unlockDivRef,
		}
		buttons.unshift(unlockButton);


		controlButtons.push(<div ref={self.highlightCtrl.overlayRef} key="readonlyoverlay" className="schema-engine-overlay"
									onClick={() => highlightUnlock(self, 0)} />);
	}

	if (uiSchema.modal?.closeButton !== "") {
		const cancelButton: IUiActionButton = { 
			title: self.props.localeDictionary.cancel,
			uiSchema: {
				setValue: "({ close: true })",
				type: "secondary",
				readOnly: false,
			}
		}
		buttons.push(typeof uiSchema.modal?.closeButton === "string"
			? { ...cancelButton, title: uiSchema.modal?.closeButton }
			: { ...cancelButton, ...uiSchema.modal?.closeButton, uiSchema: {...cancelButton.uiSchema, ...uiSchema.modal?.closeButton?.uiSchema} })
	}
	if (uiSchema.modal?.applyButton != null && !readOnly && !control.modalReadOnly) {
		const applyButton: IUiActionButton = { 
			uiSchema: {
				setValue: "({ apply: true })",
				readOnly: hasErrors || control.readOnly,
				type: "primary",
			},
			title: "", 
		}
		buttons.push(typeof uiSchema.modal?.applyButton === "string"
			? { ...applyButton, title: uiSchema.modal?.applyButton }
			: { ...applyButton, ...uiSchema.modal?.applyButton, uiSchema: {...applyButton.uiSchema, ...uiSchema.modal?.applyButton?.uiSchema} })
	}

	return controlButtons.concat(getActionButtons(buttons, self, { readOnly },
			(values: IUiSchemaSetValueResult) => updateValues(self, values, "", ""),
			(trust: UiSchemaTrust, str: string, key: string) => parseAndFormatText(self, trust, str, key)
	));

}




export function formatTextArgs(self: Self, trust: UiSchemaTrust, text: string, args: IUiSchemaElemArgs, key?: string) {

	const { objects, lib } = self;
	let scope: IExprScope = { readOnly: objects.control.readOnly };

	// FIXME: this is not correct, objects from args is not used.

	if (args) {
		const { value, readOnly, fullkey, error } = args;
		scope = { value, readOnly, fullkey, error };
	}

	const options: ITextOptions = { log: (...args: any[]) => self.log(...args), debug: self.props.debug }
	const context: ITextContext = { args, lib, objects, scope,
				updateValues: (...args) => updateValues(self, ...args) };

	return formatText(trust, text, key || args?.key, options, context);
}


function parseAndFormatText(self: Self, trust: UiSchemaTrust,  text: string, key?: string) {

	if (!text) { return null; }
	const { objects, lib } = self;

	const readOnly = !!(objects.control.modalReadOnly || objects.control.readOnly)
	const txt = evalString(trust, text, lib, objects, { readOnly }) + "";
	const jsx = formatTextArgs(self, trust, txt, null as any, key);

	return jsx;
}


import React, { useState, useEffect, useRef, useContext} from "react";
import { Link, useLocation } from 'react-router-dom';
import L from 'leaflet'
import AsyncSelect from 'react-select/async';
import { strings } from "./../../../../services/Localization";
import _ from 'lodash';
import * as ReactDOMServer from 'react-dom/server';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { OpenStreetMapProvider } from 'leaflet-geosearch';
import { faMapPin } from "@fortawesome/pro-solid-svg-icons";
import SavedMarkerIcon from "../../../../../resources/images/marker-icon.png"
import { GenericDassQuery } from "../../../../services/BasicDassQueries";
import { Button, Form, ListGroup } from "react-bootstrap";
import { HoverOverlay } from "../../../../schemaengine/client/HoverOverlay";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import {  MapContainer, TileLayer, Marker, Popup, ZoomControl, AttributionControl } from 'react-leaflet'
import { TAB_GATEWAY_CONFIGURATION } from '../../../../datatypes/tabsconstants';
import { dateTimeString } from '../../../../../src/utils/filters';
import MarkerClusterGroup from '@changey/react-leaflet-markercluster';
import { getLedIcon, renderHealthTooltip } from "../../../../../src/pages/Devices/DmpDevices";
type MarkerType = "Devices" | "Gateways" | "OmcGateways" | "SavedLocation" | "DmpDevices"

import { MapMarkerColorCodesGateways,
        MapMarkerColorCodesOmcGateways,
        getAppBase,
        nstBaseUrl,
        MapMarkerColorCodesDevices,
        MapMarkerColorCodeDmpDevice,
        MapMarkerColors,
        getIsNst
} from '../../../../utils/consts';

import { DeviceMapMarker, GateWayMapMarker}  from '../../../Common/MapMarkersSvg';
import "./style.css";
import '@changey/react-leaflet-markercluster/dist/styles.min.css';
import "leaflet/dist/leaflet.css";
import MapContext from '../../../../context/MapContext'
import { faSliders } from "@fortawesome/pro-regular-svg-icons";
import AppContext from "../../../../context/AppContext";
import { IConstants } from "src/types";

declare const constants: IConstants;

interface IMarker {
    coordinates: [number, number];
    id: string;
}
interface IMarkerStates {
    MarkersData: {
        DevicesData: any;
        GatewaysData: any;
        type:string;
    },
    Markers: {
        Gateways: any;
        Devices: any;
    };
    Draggable: boolean;
    ShowGateways: boolean;
    ShowDevices: boolean;
    GoToMarker: IMarker;
    
}
interface ICustomMarkerProps {
    infoWinText: string | JSX.Element;
    draggable: boolean;
    alt: string;
    position: L.LatLngTuple;
    icon: L.DivIcon
    markerId: string;
    log?: boolean;
    markerType?:MarkerType;
    showMarker?:boolean;
}

const updateMarkerPosition = async (marker, markerId, markerType) => {
    try {
        const pos = marker.getLatLng();

        const  payload = {
            altitude: 359,
            latitude: pos.lat,
            longitude: pos.lng
        }

        if(markerType === 'Devices' || markerType === "DmpDevices") {
            await GenericDassQuery(`/rest/nodes/${markerId}`, { method: "PUT" , data: payload});
        } else {
            await GenericDassQuery(`/rest/gateways/${markerId}`, { method: "PUT" , data: payload});
        }    

    }  catch(e) {

    }
    
}


const CustomMarker = (props: ICustomMarkerProps) => {
    const MapContextObj = useContext(MapContext);
    const { infoWinText, alt,  position, icon, markerId , markerType, showMarker } = props;
    const markerRef = useRef();
    useEffect(() => {
        MapContextObj.updateGlobalMarkers(position, markerRef.current, markerId);
    });
    
    const eventHandlers = React.useMemo(
        () => ({
          dragend() {
            const marker = (markerRef.current) ? markerRef.current : null;

            if (marker != null) {
                updateMarkerPosition(marker, markerId, markerType)
              
            }
          },
        }),
        [],
    )

    if(!showMarker) {
        return null;
    }

    return (
        <Marker ref={markerRef} eventHandlers={eventHandlers} draggable={false} key={alt} alt={alt} position={position} icon={ icon }>
            <Popup maxWidth={500}>
                <div id={alt}>{infoWinText}</div>
            </Popup>
        </Marker>
    );
  };

interface ICreateMarkersProps {
    map?: L.Map;
    Markers?: any;
    Draggable?: boolean;
}
let markerList = [];


const CreateMarkers = (props: ICreateMarkersProps) => {

    const MapContextObj = useContext(MapContext);
    const mapState =  MapContextObj.mapState;
    const networkState =  MapContextObj.networkState;
    let map: L.Map = props.map;
    const [createMarkers, setCreateMarkers] = useState(false);


    const initState = {
        MarkersData: {
            DevicesData:[],
            GatewaysData:[],
            type:''
        },
        Markers: {
            Gateways: [],
            Devices: []
        },
        ShowDevices: true,
        ShowGateways: true,
        Draggable: false,
        GoToMarker: null
    }
    
    const [state, setState] = useState<IMarkerStates>(initState);

    const renderAlarmCol = (alarm) => {
        const alarmColor = alarm.severity === "critical" ? '#8B0000' : alarm.severity === "warning" ? '#FFA500' : '#0000FF';
        return (
            <ListGroup.Item as="li" key={alarm.alarmName} className="d-flex">
              <div className="w-100">
                <div className="text-wrap text-break my-1">
                  <div className="fa-80 ow-datatable-overflow-ellipsis" title={alarm.alarmName}>{alarm.alarmName}
                  <div style={{ color: alarmColor }} className={`fa-85 ow-datatable-overflow-ellipsis`} title={alarm.severity}>{alarm.severity}</div></div>
                </div>
              </div>
            </ListGroup.Item>
          );
    }

   
    
    const infoWindow  = (markerId: string, markerName: string, type: MarkerType, marker: any ) => {

        try {
            const appBase = getAppBase();

            const markerPopup = (type === "Gateways" || type === "OmcGateways") 
                                ? markerId.toUpperCase() : (type === 'DmpDevices') ? markerId
                                : markerId.match(/.{2}/g)?.join("-").toUpperCase();
            
            const infoUrl = (type === "Gateways" || type === "OmcGateways")
                            ? (appBase === nstBaseUrl ? `${appBase}/omc-gateways/${markerId}/${TAB_GATEWAY_CONFIGURATION}/map` 
                                                      : `${appBase}/my-gateways/${markerId}/gateway-info`)
                            : (type === "DmpDevices") ? `${appBase}/dmp-devices/${markerId}/device-detail`: `${appBase}/my-devices/${markerId}/device-detail`;
                            
            const prevPageUrl = (type === "Gateways" || type === "OmcGateways")
                              ? (appBase === nstBaseUrl ? `${appBase}/network_map` 
                                                        : `${appBase}/network_map`)
                              : `${appBase}/network_map`;

            if (type === 'Gateways' || type === "OmcGateways") {
                return <>
                        <div className="fw-bold mb-2">{markerName} </div>
                        <div className="mb-2" style={{textAlign: 'left'}}>
                            <div id={`marker-${markerId}`}></div>Gateway-ID: <Link to={infoUrl} state={{prevPageUrl, row: marker}}> {markerPopup}</Link>
                        </div> 
                        <div className="mb-2"> {marker.gateway_type ?? ""}</div>
                    </>;
            } else {
                const image = marker?.image_url ? (marker?.image_url.startsWith("storageid:") ? "/storagecache/" + marker?.image_url.substring(10) : marker?.image_url) :  null;
                const lastUpdateTmst = dateTimeString(marker.last_reception || marker.last_update_tmst).length > 2 
                                     ? dateTimeString(marker.last_reception || marker.last_update_tmst)
                                     : strings.LAST_UPDATE_NOT_SEEN;

                const popover = (
                    <>
                        <Popover.Header as="h3" className="mt-0">{strings.ALARM}</Popover.Header>
                        <Popover.Body className="p-2">
                            <ListGroup variant="flush schema-engine-status-list" as="ul">
                                {marker.alarms && marker.alarms.map((alarm) => renderAlarmCol(alarm))}
                            </ListGroup>
                        </Popover.Body>
                    </>
                );

                const handleMoreButtonClick = (ev) => {
                    ev.stopPropagation();
                };
                          
                return <>
                    <div className="px-2"> 
                    { image &&
                        <div className="d-flex justify-content-center"> 
                            <img src={image} className="img-thumbnail mb-2" alt="Example Image" width="80px" height="80px" />
                        </div> 
                    } 
                        <div> 
                            { markerName && 
                                <div className="mb-2" style={{ display: 'flex'}}> 
                                    <span style={{ fontWeight: 'bold', whiteSpace: 'nowrap' }}>{`${strings.MAP_INFO_NAME} :`}</span>  
                                    <div className="max-three-lines"> {markerName} </div>
                                </div> 
                            }
                            { type === "Devices" && 
                                <div className="mb-2" style={{ display: 'flex'}}>
                                    <div id={`marker-${markerId}`}> </div>
                                    <span style={{ fontWeight: 'bold', whiteSpace: 'nowrap' }}>{`${strings.MAP_INFO_DEVEUI} :`}</span>
                                    <div style={{ marginLeft: "3.5px"}}> 
                                        <Link to={infoUrl} state={{prevPageUrl, row: marker}}> {markerPopup}</Link>
                                    </div>
                                </div>
                            } 
                            { type === "DmpDevices" && 
                                <div className="mb-2" style={{ display: 'flex'}}>
                                    <div id={`marker-${markerId}`}></div>
                                    <span style={{ fontWeight: 'bold', whiteSpace: 'nowrap' }}>{`${strings.MAP_INFO_DEVICEUUID} :`}</span> 
                                    <div style={{ marginLeft: "3.5px"}}> 
                                        <Link to={infoUrl} state={{prevPageUrl, row: marker}}> {markerPopup}</Link>
                                    </div>
                                </div>  
                            } 
                            { marker.deviceId && 
                                <div className="mb-2" style={{ display: 'flex'}}>
                                    <span style={{ fontWeight: 'bold', whiteSpace: 'nowrap' }}>{`${strings.MAP_INFO_DEVICEID} :`}</span> 
                                    <div className="max-three-lines"> {marker.deviceId} </div>
                                </div>
                            }
                            { marker.comment && 
                                <div className="mb-2" style={{ display: 'flex'}}>
                                    <span style={{ fontWeight: 'bold', whiteSpace: 'nowrap' }}>{`${strings.MAP_INFO_COMMENT} :`}</span> 
                                    <div className="max-three-lines"> {marker.comment} </div>
                                </div>
                            }
                            { marker.operationalStatus && 
                                <div className="mb-2" style={{ display: 'flex'}}> 
                                    <span style={{ fontWeight: 'bold',  whiteSpace: 'nowrap' }}>{`${strings.MAP_INFO_HEALTH} :`}</span> 
                                    <div style={{ marginLeft: "3.5px"}} title={renderHealthTooltip(marker)} > { getLedIcon(marker)} </div>
                                </div> 
                            }
                            { marker.operationalStatusMessage && 
                                 <div className="mb-2" style={{ display: 'flex'}}> 
                                 <span style={{ fontWeight: 'bold', whiteSpace: 'nowrap' }}>{`${strings.MAP_INFO_HEALTH_MSG} :`}</span>  
                                 <div className="max-three-lines"> {marker.operationalStatusMessage} </div>
                             </div> 
                            }
                            { lastUpdateTmst && 
                                <div className="mb-2" style={{ display: 'flex'}} > 
                                    <span style={{ fontWeight: 'bold', whiteSpace: 'nowrap' }}>{`${strings.MAP_INFO_LAST_UPDATE} :`}</span> 
                                    <div style={{ marginLeft: "3.5px"}}> {lastUpdateTmst} </div>
                                </div> 
                            }
                            {
                                type === "DmpDevices" && marker.alarms &&
                                <div className="mb-2" style={{ display: 'flex'}}>
                                    <span style={{ fontWeight: 'bold', whiteSpace: 'nowrap' }}>{`${strings.MAP_INFO_ALARMS} :`}</span>
                                    <div style={{ marginLeft: "3.5px"}}> 
                                        <HoverOverlay big={true} overlay={popover} className="ow-popover">
                                            <b className="alarm-count" style={{ textDecoration: 'underline'}} onClick={handleMoreButtonClick}>{marker.alarms.length}</b>
                                        </HoverOverlay>
                                    </div>
                                </div>         
                            }
                        </div>
                    </div>  
                </>;
            }
        } catch(e) {
            console.log(e);
        }
        return '';
    }
    
    

    const  compareUpdate = (prevProps, prevState) => {
        try {

            const markers = mapState.isNst > -1 ? {"Gateways": networkState.Gateways } : {"Gateways": networkState.Gateways, "Devices": networkState.Devices }
            const { Draggable } = state;
            const { Gateways, Devices } = networkState;
            
            if (JSON.stringify(state.Markers) !== JSON.stringify(markers)) {
                if (JSON.stringify(state.Markers.Gateways) !== JSON.stringify(Gateways)) {
                    handleState({GatewaysData: Gateways, type: "Gateways"}, Draggable);
                }
                if (JSON.stringify(state.Markers.Devices) !== JSON.stringify(Devices)) {
                    handleState({DevicesData: Devices, type: "Devices"}, Draggable);
                }
            }
            
            if (state.ShowDevices !== networkState.ShowDevices) {
                handleState({ DevicesData: networkState.ShowDevices ? Devices : [], type: "Devices" }, Draggable);
            }
            if (state.ShowGateways !== networkState.ShowGateways) {
                handleState({GatewaysData: networkState.ShowGateways ? Gateways : [], type: "Gateways"}, Draggable);
            }

            if (props.Markers && (state.Draggable !== Draggable)) { }
            if (MapContextObj.mapState && map && (prevProps.GoToMarker !== MapContextObj.mapState.FlyTo)) { }
            if (mapState.GoToMarker  && (prevProps.GoToMarker !== mapState.GoToMarker)) { }
        }catch(e) {
            console.log(e)
        }
    }
        
    const  handleState = (MarkersData, Draggable) => {

        let markers;
        if (mapState.isNst > -1) {
            markers = {"Gateways": networkState.Gateways }
        } else {
            markers = {"Gateways": networkState.Gateways, "Devices": networkState.Devices }
        }

        setState(oldState => {
            return {...oldState, MarkersData:{...oldState.MarkersData, ...MarkersData}, Markers:markers, ShowDevices: mapState.ShowDevices, ShowGateways: mapState.ShowGateways, Draggable: Draggable, GoToMarker: mapState.GoToMarker }
       })
       
   }

   useEffect(() => {
        setCreateMarkers(false)
        markerList = [];
       try {
            compareUpdate(state, props);
    
       }catch(e) {
            console.log(e);
       }
   },[networkState.changed, networkState.TableData])

    //render start here
    let MarkersData = state.MarkersData;
    
    let Data = []
    if (MarkersData.hasOwnProperty('GatewaysData') && MarkersData.hasOwnProperty('DevicesData')) {
        if(networkState.operationalStatusFilter || networkState.TableFilterValue) {
            Data = networkState.TableData;
        }else {
            Data = MarkersData.DevicesData.concat( MarkersData.GatewaysData );
        }  
    } else if (MarkersData.hasOwnProperty('GatewaysData')) {
        Data = MarkersData.GatewaysData.filter((v) => v.uuid == mapState.FlyTo.id);  
    } else {
        if(networkState.operationalStatusFilter || networkState.TableFilterValue) {
            Data = networkState.TableData;
        } else {
            Data = MarkersData.DevicesData;  
        }
    }

  
    if(Object.keys(MapContextObj.globalMarkers).length >= Data.length) {
        MapContextObj.resetGlobalMarkers();
    }
    //&& Object.keys(MapContextObj.globalMarkers).length === 0
    if (!createMarkers) {
        markerList = Data.map((marker, index) => {
            setCreateMarkers(true)
            if (marker) {
                if (marker.latitude && marker.longitude) {
                    // console.log(marker)
                    const markerId: string = (marker.hasOwnProperty('deveui')) ? marker.deveui : (marker.hasOwnProperty('id')) ?  marker.id : (marker.hasOwnProperty('deviceUuid')) ? marker.deviceUuid : marker.uuid;
                    const markerName = (marker.hasOwnProperty('deveui')) ? marker.comment : marker.name;
                    const type: MarkerType = (marker.hasOwnProperty('deveui')) ? 'Devices' : (marker.hasOwnProperty('deviceUuid')) ? 'DmpDevices' : marker.hasOwnProperty('uuid') ? 'OmcGateways' : "Gateways";

                    let infoWinText = infoWindow (markerId, markerName, type, marker);

                    let showMarker;
                    if (type === 'Devices' || type === 'DmpDevices') {
                        showMarker = networkState.ShowDevices
                    } else {
                        showMarker = networkState.ShowGateways
                    }

                    return (
                        <CustomMarker markerId={markerId}
                            infoWinText={infoWinText} draggable={networkState.Draggable}
                            alt={`marker-${markerId}`} key={`marker-${markerId}`}
                            position={[marker.latitude, marker.longitude]}
                            markerType={type}
                            showMarker={showMarker}
                            icon={getMapMarkerType(type, marker, markerId)} />
                    );
                    //46.21025, 2922363
                    //4621025   2922363
                } else {
                    return null;
                }
            } else {
                return null;
            }

        })
    }

    return (<MarkerClusterGroup chunkedLoading={true} chunkInterval={100} maxClusterRadius={28} chunkSize={50} spiderfyOnMaxZoom={true} animate={true} spiderfyDistanceMultiplier={1.5}>{markerList}
    </MarkerClusterGroup>);

    
}


const getMapMarkerType = (type: MarkerType, marker: any, markerId: string) => {

    const defcolor = MapMarkerColors.gray;
    if (type === 'SavedLocation') {

        return L.divIcon({
            iconSize: [25, 41],
            iconAnchor: [12, 41],
            popupAnchor: [1, -41],
            className: "device_icon",
            html: ReactDOMServer.renderToStaticMarkup(<div id={markerId}><img src={SavedMarkerIcon} /></div>)
        });
    } else if(type === 'Devices') {
        const color = marker.traffic_status ? MapMarkerColorCodesDevices[marker.traffic_status] : defcolor;

        return L.divIcon({
            iconSize: [25, 41],
            iconAnchor: [12, 41],
            popupAnchor: [1, -41],
            className: "device_icon",
            html: ReactDOMServer.renderToStaticMarkup(<div id={markerId}><DeviceMapMarker colorCode={color} /></div>)
        });

    } else if(type === 'DmpDevices') {        
        let color = defcolor;
        if(marker.operationalStatus) {
            if(marker.suspended) {
                color = MapMarkerColorCodeDmpDevice['SUSPENDED']
            } else if(marker.device_profile_compile_failed || marker.connection_profile_compile_failed) {
                color = MapMarkerColorCodeDmpDevice['CRITICAL'];
            } else if(marker.operationalStatus == 'na' && (marker.connectionStatus == 'connected' || marker.connectionStatus == 'disconnected')) {
                color = MapMarkerColorCodeDmpDevice['CONNECTING'];
            } else if(marker.operationalStatus == 'na' && marker.connectionStatus == 'error') {
                color = MapMarkerColorCodeDmpDevice['ERROR'];
            }  else {
                color = MapMarkerColorCodeDmpDevice[marker.operationalStatus.toUpperCase()]
            }
        }

        return L.divIcon({
            iconSize: [25, 41],
            iconAnchor: [12, 41],
            popupAnchor: [1, -41],
            className: "device_icon",
            html: ReactDOMServer.renderToStaticMarkup(<div id={markerId}><DeviceMapMarker colorCode={color} /></div>)
        });

    }  else if (type === "Gateways") {
        const color = marker.status ? MapMarkerColorCodesGateways[marker.status] : defcolor;

        return L.divIcon({
            iconSize: [25, 41],
            iconAnchor: [12, 41],
            popupAnchor: [1, -41],
            className: "gateway_icon",
            html: ReactDOMServer.renderToStaticMarkup(<div id={markerId}><GateWayMapMarker colorCode={color} /></div>)
        });

    } else  {
        // This must be OmcGateways
        const color = marker.alarmCounters?.worstSeverity != null ?
                      MapMarkerColorCodesOmcGateways[marker.alarmCounters?.worstSeverity] : defcolor;
            return L.divIcon({
                iconSize: [25, 41],
                iconAnchor: [12, 41],
                popupAnchor: [1, -41],
                className: "gateway_icon",
                html: ReactDOMServer.renderToStaticMarkup(<div id={markerId}><GateWayMapMarker colorCode={color} /></div>)
            });
    }
}

interface Mapv2Props {
    isNst:number;
    mapCenter: L.LatLngExpression;
    mapZoom: number;
    setDefPosition: () =>  void;
}

const Mapv2: React.FC<Mapv2Props> = ( props ) => {
    
    const mapRef = useRef(null);
    const MapContextObj = useContext(MapContext);
    const appContextObj = useContext(AppContext);

    const readonly = appContextObj.user?._readonly;

    const location = useLocation()
    
    const isNst = getIsNst(location) > -1;
    const [position, setPosition] = useState(null);
    const isDmp = constants.server_type === "dmp"
    

    const handleSetMap = (map) => {
        
        if(!_.isEqual(map, MapContextObj.mapState.map)) {
            mapRef.current = map;
        
            MapContextObj.setMapState(prevState => {
                return {...prevState, map:map}
            })
        }
    }

    const toggleEnableMovable = (e) => {
        
        MapContextObj.setNetworkState(prevState => {
            return {...prevState, Draggable:e.target.checked}
        })
        
    }

    const toggleShowDevices = (e) => {
        MapContextObj.setNetworkState(prevState => {
            return {...prevState, ShowDevices:e.target.checked, changed:!prevState.changed}
        })
    }

    const toggleShowGateways = (e) => {
        MapContextObj.setNetworkState(prevState => {
            return {...prevState, ShowGateways:e.target.checked, changed:!prevState.changed}
        })
    }

    

    const flyTo = MapContextObj.mapState.FlyTo;

    const mapCenter: L.LatLngExpression = flyTo ? flyTo?.coordinates : props.mapCenter;

    const Draggable = MapContextObj.networkState.Draggable;
    const ShowDevices = MapContextObj.networkState.ShowDevices;
    const ShowGateways = MapContextObj.networkState.ShowGateways;


    const ShowGatewayDevices = () => {
        return (
            <>
            <div className="d-flex flex-row">
                    <Form.Check  type={`checkbox`} checked={ShowDevices} id={`show-devices`} onChange={(e) => toggleShowDevices(e) }/>&nbsp;
                    <Form.Label className="mb-0" htmlFor="show-devices" >{strings.MAP_SHOW_DEVICES}</Form.Label>
            </div>
            <div className="d-flex flex-row">
                <Form.Check  type={`checkbox`} checked={ShowGateways} id={`show-gateways`} onChange={(e) => toggleShowGateways(e) }/>&nbsp;
                <Form.Label className="mb-0" htmlFor="show-gateways" >{strings.MAP_SHOW_GATEWAYS}</Form.Label>
            </div>
            </>
        )
    }
    
    const popover = (
        <Popover id="popover-basic">
            <Popover.Body>
                {!readonly && <div className="d-flex flex-row">
                    <Form.Check  type={`checkbox`} checked={Draggable} id={`enable-devices`} onChange={(e) => toggleEnableMovable(e) }/>&nbsp;
                    <Form.Label className="mb-0" htmlFor="enable-devices" >{strings.MAP_MOVABLE}</Form.Label>
                </div>}
                {!(isDmp || isNst) && <ShowGatewayDevices />}
            </Popover.Body>
        </Popover>
    );




    /**
     * Extract the map provider settings. Ideally this should be moved into a useEffect but it is pretty
     * light so it is probably ok to keep it here for the moment.
     */
	let mapProvider = { 
		url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", maxNativeZoom: 19, 
		attribution: "&copy; <a href='http://osm.org/copyright'>OpenStreetMap</a> contributors",
		subDomain: ["a", "b", "c"] 
	};
	const env = appContextObj?.user?._environment;
	if (env?.map_provider_tileurl) {
		const { url, attribution, maxNativeZoom, subDomains} =  { 
            url: env.map_provider_tileurl, attribution: env.map_provider_attribution, 
            maxNativeZoom: env.map_provider_max_native_zoom, subDomains: env.map_provider_sub_domains 
        };
        if (url && typeof url === "string" && typeof attribution === "string" && typeof maxNativeZoom === "number" && typeof subDomains === "string") {
			mapProvider  = { url, attribution, maxNativeZoom, subDomain: subDomains.split(",") };
		}
	}



    return (
        <div>
            <div style={{position: "absolute", zIndex: '999', top: 20, right:10}}>
                <MapSearch setPosition={setPosition} />
            </div>

            {!readonly && <div style={{position: "absolute", zIndex: '999', top: 80, right:10}}>
                <Button className="btn-icon shadow" variant="light" onClick={props.setDefPosition}> <FontAwesomeIcon icon={faMapPin} /></Button>
            </div>}

            {((!readonly || !isNst) && !isDmp) && <div style={{position: "absolute", zIndex: '999', top: 120, right:10}}>
                <OverlayTrigger rootClose trigger="click" placement="left" overlay={popover}>
                    <Button className="btn-icon shadow" variant="light"> <FontAwesomeIcon icon={faSliders} /></Button>
                </OverlayTrigger>
            </div>}
            
            <MapContainer attributionControl={false} key={`main_map`} id="leaflet-map" 
                    center={mapCenter} 
                    zoom={props.mapZoom} 
                    scrollWheelZoom={true} 
                    zoomControl={false}
                    ref={(map) => { handleSetMap(map) } }>

            <AttributionControl prefix='<a href="https://leafletjs.com/">Leaflet</a>' />
            <TileLayer
                attribution={mapProvider.attribution}
                url={mapProvider.url}
                subdomains={mapProvider.subDomain}
                maxNativeZoom={mapProvider.maxNativeZoom}
            />
            
            <ZoomControl position="bottomright" zoomInText="+" zoomOutText="-" />
            
            <div style={{position: 'absolute', top:'30px', right:'10px', zIndex:9999, height: '500px'}}>
                <div style={{position: 'relative', top:'0px', right:'0px', height: '500px', backgroundColor: '#ffffff'}}>
                </div>
            </div>
            
            <CreateMarkers {...props} map={mapRef.current} />

            {position && <Marker position={position} alt={`${position[0]} , ${position[1]} `} icon={getMapMarkerType('SavedLocation', null, position)}>
                <Popup>
                    <div id={position}>{`Latitude : ${position[0]} | Longitude: ${position[1]}`}</div>
                </Popup>
            </Marker>}


            </MapContainer>
        </div>
    );
  
    
}

export default Mapv2;




export interface PlanceOption {
    readonly place_id: string;
    readonly label: string;
    readonly position: string[];
}

  

const  MapSearch = ( props ) => {
    const MapContextObj = useContext(MapContext);
    const Map = MapContextObj.mapState.map;

    const provider = new OpenStreetMapProvider();
    

    const filterColors = async (inputValue: string) => {
        const results = await provider.search({ query: inputValue.toLowerCase() });
        const options = _.map(results, (row, index) => {
            let obj = {value: row.label, label: row.label, position:[row.y, row.x]}
            return obj;
        })
        
        return options;
    };
        
    const promiseOptions = (inputValue: string) => {
        return filterColors(inputValue);
    }

    const [selectedOption, setSelectedOption] = React.useState();

    const onChangeSelectedOption = ( e ) => {
        const selectedOption = e; // <--- you can get value from object directly
        setSelectedOption(selectedOption);
        props.setPosition(selectedOption.position)
        Map && Map.setView(selectedOption.position, Map.getZoom(),{})
    };

    
    return (
        <div  style={{width: '250px', zIndex:'99999', opacity:1}}>
            <AsyncSelect 
                value={selectedOption}
                onChange={onChangeSelectedOption}
                className="ow-map-search-filter"
                placeholder={strings.SEARCH_MAP}
                cacheOptions defaultOptions loadOptions={promiseOptions} />
        </div>
    );
  
}
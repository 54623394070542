import React, {useEffect, useState, useContext } from 'react';

import AsyncSelect from 'react-select/async';

import _ from 'lodash';

import {    
    ColumnType   ,
    ScopeType
} from './DataTypes';

import Select from 'react-select';

import { DataTableContext} from "./DataTableState";

import { DataTableChildContext } from "./DataTableChildState";
import { strings } from "../../../services/Localization";

interface optionState {
    label: string;
    value:string;
}


interface ReactSelectSearchProps {
    column: ColumnType;
    scope:ScopeType;
    //searchHandler: (fieldName: string, fieldValue: any, column:columnType) => void;
}

const ReactSelectSearch:  React.FC<ReactSelectSearchProps> = ({ column, scope }) => {

    let DataTableContextObj;
    if(scope === 'child') {
        DataTableContextObj = useContext(DataTableChildContext);
    }else {
        DataTableContextObj = useContext(DataTableContext);
    }

    const [localData, setLocalData] = React.useState<any[]>();

    if(column.defaultFilterValue) {
        var [selectedItem, setSelectedItem] = useState<any>(column.defaultFilterValue);
    }else {
        var [selectedItem, setSelectedItem] = useState<any>();
    }


    let filterField = column.filterField ? column.filterField : '';
    
    let filterParams = (column.filterParams) ? column.filterParams : {};

    const urlValue = DataTableContextObj.getInputField(filterField);

    //let urlValue = getUrlValue(DataTableContextObj, column.filterField);

    const searchInit = ( item ) => {
        
        item = (item === 'No Filter') ? '' : item; //if item = All and search blank
        //searchHandler(filterField, item, column);
        DataTableContextObj.updateSearchFields(filterField, item, column);
    }

    
    let optionFetcher = (filterParams.optionFetcher) ? filterParams.optionFetcher : () => {};

    let data = (filterParams.data) ? filterParams.data : [];

    
    // useEffect(() => {

    //     DataTableContextObj.updateSearchFields(filterField, urlValue, column);

    // },[urlValue]) 

    let defaultFilterValue = (column.defaultFilterValue) ? column.defaultFilterValue : '';

    // useLayoutEffect(() => {

    //     window.setTimeout(() => {
    //         if(defaultFilterValue) {

    //             setLocalData(state => defaultFilterValue);
                
    //             DataTableContextObj.updateSearchFields(filterField, defaultFilterValue, column);
    //         }
    //     }, 6000)
                 
    //  },[])


    useEffect(() =>  {

        (async function() {
            
            if(optionFetcher) {
                var r = async () => {
                    data = await optionFetcher();
                    
                    let optionsArr:any[] = [];

                    if(data) {

                        let obj:optionState = {label: strings.TABLE_COUNT_ALL, value:''};
                        
                        optionsArr.push(obj);

                        data.push(optionsArr);

                        for (let i = 0; i < data.length - 1; ++i) {

                            let r = data[i];

                            if(r.label !== undefined && r.value !== undefined) {
                                let obj:optionState = {label:'', value:''};
                            
                                obj.label =  r.label;
                
                                obj.value =  r.value;
                
                                optionsArr.push(obj);
                            }
                        }
                    }

                    setLocalData(prev => optionsArr);
                    
                    if(defaultFilterValue) {
                        window.setTimeout(() => {
                            
                            DataTableContextObj.updateSearchFields(filterField, defaultFilterValue, column);

                        },0)
                        
                    }
                }
                
                
                //if(DataTableContextObj.dataLoaded === 0) {
                    window.setTimeout(r, 0);
                //}
                
            }else {
                setLocalData(state => data); 
            }

        })();

  }, [])
    
  const handleChange = async (newValue, actionMeta) => {
       if(newValue !== selectedItem) {
        setSelectedItem(state => newValue);
        searchInit(newValue.value);
    }else {
        setSelectedItem(state => '');
        searchInit('');
    }

};


    let filtered:any = '';
    if(localData) {
        filtered = localData.filter(option =>  (option.label === urlValue || option.value === urlValue));
    }

    const selectStyles = {
        menu: (styles: any) => ({
            ...styles,
            zIndex: 999, // Ensure the menu appears above other elements
        }),
        menuPortal: (base: any) => ({
            ...base,
            zIndex: 9999, // Ensure the menu portal appears above other UI components
        })
    };
    const classNames = {
        menu: () => 'custom-select-menu',
        option: (state) =>
            `custom-select-option col-md-12
           ${state.isSelected ? 'custom-select-option--selected' : ''} 
        ${state.isFocused && !state.isSelected ? 'custom-select-option--focused' : ''}`.trim(),
    };


    
    

    return (
            <div className="border-1 ow-react-select-custom" style={{width: '100%', color:'#000'}}>
                <Select 
                     value = {filtered}
                    styles={selectStyles}
                    classNames={classNames}
                    options={localData}
                    onChange={handleChange}
                />
            </div>
        
    )
}






const AsyncSearch:  React.FC<ReactSelectSearchProps> = ({ column, scope }) => {

    let DataTableContextObj;
    if(scope === 'child') {
        DataTableContextObj = useContext(DataTableChildContext);
    }else {
        DataTableContextObj = useContext(DataTableContext);
    }


    if(column.defaultFilterValue) {
        var [selectedItem, setSelectedItem] = useState<any>(column.defaultFilterValue);
    }else {
        var [selectedItem, setSelectedItem] = useState<any>();
    }

    let filterField = column.filterField ? column.filterField : '';
    
    let filterParams = (column.filterParams) ? column.filterParams : {};

    //let urlValue = getUrlValue(DataTableContextObj, column.filterField);

    const searchInit = ( item ) => {
        
        item = (item === 'No Filter') ? '' : item; //if item = All and search blank
        
        DataTableContextObj.updateSearchFields(filterField, item, column);
    }

    
    let optionFetcher = (filterParams.optionFetcher) ? filterParams.optionFetcher : () => {};

    // let data = (filterParams.data) ? filterParams.data : [];

    // let defaultFilterValue = (column.defaultFilterValue) ? column.defaultFilterValue : '';

        
    const promiseOptions = async (inputValue: string) => {

        let results = await optionFetcher();
        let options = [];
        for(let i  = 0; i < results.length; ++i) {
            let obj = {value: results[i], label:results[i]}
            options.push(obj);
        }
        // const options = _.map(results, (row, index) => {
        //     let obj = {value: row.label, label: row.label, position:[row.y, row.x]}
        //     return obj;
        // })
        
        options = [
            { value: 'chocolate', label: 'Chocolate' },
            { value: 'strawberry', label: 'Strawberry' },
            { value: 'vanilla', label: 'Vanilla' },
          ];

        return options;
    }

    
    const onChangeSelectedOption = ( e ) => {
        const selectedOption = e; // <--- you can get value from object directly
        setSelectedItem(selectedOption);
        searchInit(selectedOption.value)
        // if(newValue !== selectedItem) {
        //     setSelectedItem(state => newValue);
        //     searchInit(newValue.value);
        // }else {
        //     setSelectedItem(state => '');
        //     searchInit('');
        // }

    };
    

 

    return (
        <div  style={{width: '250px', zIndex:'999', opacity:1}}>
            <AsyncSelect 
                value={selectedItem}
                onChange={onChangeSelectedOption}
                className="ow-map-search-filter"
                cacheOptions defaultOptions loadOptions={promiseOptions} />
        </div>
    );
  
}

export { ReactSelectSearch }

export { AsyncSearch }
import {  DetailPageActionType, DetailPagePropsType, BreadCrumbType } from '../../datatypes/datatypes';

import { faEdit } from '@fortawesome/free-solid-svg-icons'

import { getAppBase } from '../../utils/consts';

import { TAB_ALARM_INFO } from '../../datatypes/tabsconstants'

const detailPageNav = ( navigate, tab, id, row = null, prevPageUrl) => {
    if(id) {
        navigate(`${getAppBase()}/gateway-alarms/${id}/${tab}`, {state: {row: row, prevPageUrl}})
    }
}

export const GatewayAlarmRowProps: DetailPagePropsType =  {
    getBreadCrumbArr: (breadCrumbArgs) => {
        
        let prevPageUrl = breadCrumbArgs.hasOwnProperty('prevPageUrl') ? breadCrumbArgs['prevPageUrl'] : `${getAppBase()}/gateway-alarms`;
        
        const { row } = breadCrumbArgs;

        const breadCrumb:BreadCrumbType[] = [
            {label: "Gateway Alarms", url: prevPageUrl},
            {label: `Alarm Info`, url:'', comment: row.name}
        ]
        return breadCrumb;
    },
    getActions: (actionArgs, navigate) => {
        let prevPageUrl = actionArgs.hasOwnProperty('prevPageUrl') ? actionArgs['prevPageUrl'] : `${getAppBase()}/gateway-alarms`;
        const { row } = actionArgs;

        const tabs: DetailPageActionType[] = [
            {
                type: "tab",
                text: "Edit Alarm",
                icon: faEdit,
                key: TAB_ALARM_INFO,
                action: (row) => detailPageNav(navigate, TAB_ALARM_INFO, row.id, row, prevPageUrl ),
                content: {
                    type: 'SchemaModal',
                    url: `/rest/omc/alarms/?get_schema=dassui,put`,
                    backUrl: `${prevPageUrl}`
                },
                EditObject: { id: row.id ? row.id : null, ne_type : 'gtw'}
            }
        ];

        return tabs;
    }
}


export const ServerAlarmRowProps: DetailPagePropsType =  {
    getBreadCrumbArr: (breadCrumbArgs) => {
        let prevPageUrl = breadCrumbArgs.hasOwnProperty('prevPageUrl') ? breadCrumbArgs['prevPageUrl'] : `${getAppBase()}/server-alarms`;
        const { row } = breadCrumbArgs;

        const breadCrumb:BreadCrumbType[] = [
            {label: "Server Alarms", url: `${prevPageUrl}`},
            {label: `Alarm Info`, url:'', comment: row.name}
        ]
        return breadCrumb;
    },
    getActions: (actionArgs, navigate) => {

        let prevPageUrl = actionArgs.hasOwnProperty('prevPageUrl') ? actionArgs['prevPageUrl'] : `${getAppBase()}/server-alarms`;
        const { row } = actionArgs;

        const tabs: DetailPageActionType[] = [
            {
                type: "tab",
                text: "Edit Alarm",
                icon: faEdit,
                key: TAB_ALARM_INFO,
                action: (row) => detailPageNav(navigate, TAB_ALARM_INFO, row.id, row, prevPageUrl),
                content: {
                    type: 'SchemaModal',
                    url: `/rest/omc/alarms/?get_schema=dassui,put`,
                    backUrl: prevPageUrl
                },
                EditObject: {id: row.id ? row.id : null, ne_type : 'server'}
            }
        ];

        return tabs;
    }
}



import { LiveLogRecord } from "./LiveLog";

export const openDB = (dbName: string, storeName: string): Promise<IDBDatabase> => {
    return new Promise((resolve, reject) => {
      const request = indexedDB.open(dbName, 1);
      request.onerror = (event) => reject(`Error opening database: ${event}`);
      request.onsuccess = (event) => resolve((event.target as IDBRequest<IDBDatabase>).result);
      request.onupgradeneeded = (event) => {
        const db = (event.target as IDBRequest<IDBDatabase>).result;
        db.createObjectStore(storeName, { keyPath: 'id', autoIncrement: true });
      };
    });
  };
  
  export const readDataFromDB = (db: IDBDatabase, storeName: string): Promise<LiveLogRecord[]> => {
    return new Promise((resolve, reject) => {
      const transaction = db.transaction(storeName, 'readonly');
      const objectStore = transaction.objectStore(storeName);
      const request = objectStore.getAll();
      request.onsuccess = (event) => resolve((event.target as IDBRequest<LiveLogRecord[]>).result);
      request.onerror = (event) => reject(`Error reading data from database: ${event}`);
    });
  };
  
  export const addDataToDB = (db: IDBDatabase, storeName: string, newLog: LiveLogRecord): Promise<void> => {
    return new Promise((resolve, reject) => {
      const transaction = db.transaction(storeName, 'readwrite');
      const objectStore = transaction.objectStore(storeName);
      const countRequest = objectStore.count();
      countRequest.onsuccess = async () => {
        const count = countRequest.result;
        if (count >= 1500) {
          const keysToDelete = await new Promise<number[]>((keysResolve, keysReject) => {
            const getAllKeysRequest = objectStore.getAllKeys(undefined, 100);
            getAllKeysRequest.onsuccess = () => keysResolve((getAllKeysRequest as IDBRequest).result);
            getAllKeysRequest.onerror = (event) => keysReject(`Error getting keys to delete: ${event}`);
          });
          keysToDelete.forEach((key) => objectStore.delete(key));
        }
        const addRequest = objectStore.add(newLog);
        addRequest.onsuccess = () => resolve();
        addRequest.onerror = (event) => reject(`Error adding data to database: ${event}`);
      };
      countRequest.onerror = (event) => reject(`Error counting entries: ${event}`);
    });
  };
  
  export const clearDataInDB = (db: IDBDatabase, storeName: string): Promise<void> => {
    return new Promise((resolve, reject) => {
      const transaction = db.transaction(storeName, 'readwrite');
      const objectStore = transaction.objectStore(storeName);
      const request = objectStore.clear();
      request.onsuccess = () => resolve();
      request.onerror = (event) => reject(`Error clearing data from database: ${event}`);
    });
  };


export function processArray(arr) {
    try {
      if (arr.length % 2 !== 0) {
        return arr;
      }
      const result = {};
      for (let i = 0; i < arr.length; i += 2) {
        const keyPart = arr[i];
        const key = keyPart.slice(0, -1).trim();
        const value = arr[i + 1];
        result[key] = value;
      }
      return result;
    } catch (e) {
      return arr;
    }
  }
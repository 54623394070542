import React, { useEffect, useState, useContext } from "react";
import { strings } from "../../services/Localization";
import { checkLogin } from "../../services/Login";
import { useParams } from 'react-router-dom';
import { IUser } from "../../dassTypes";
import {  dialog } from '../../components/Common';
import AppContext from '../../context/AppContext'
import { BreadCrumbType, PageButtonType } from '../../datatypes/datatypes';
import { ActionType, BulkActionType, ColumnType, DataTableOption  } from '../../components/Common/DataTable/DataTypes';
import { GenericDassQuery } from "../../services/BasicDassQueries";
import { toast } from "../../utils/Toaster";
import { getVisibleActions, actionIcon, dateTimeString, prioritizedTableColumns } from "../../utils/filters";
import{ ID_INPUT_VALIDATION, DEFAULT_RECORD_LIMIT, DEFAULT_INPUT_VALIDATION }  from "../../components/Common/DataTable/DataTableConsts";
import PageContent from "../PageContent";
import { faTrashCan, faRefresh, faTrashAlt, faTimesCircle, faListCheck } from '@fortawesome/pro-regular-svg-icons'
import { trustedFormatText } from "../../schemaengine/client/SchemaTextParser";
import {  UUIDCOLWIDTH } from "../../utils/consts";
import { isMobile } from "react-device-detect";
import { deviceData } from "../../components/Common/DataTable/DataTableState";

interface IAlarmStates {
    loggedUser: IUser | null;
    showAlertModal: boolean;
    editUserId: string;
    pageTitle: string;
    breadCrumbArr: BreadCrumbType[];
    refresh:boolean;
    isRowDeleted?:boolean;
}

interface IRowType {
  alarmUuid: string;
  entityType: string;
  entityName: string;
  entityId: string;
  alarmDefinitionUuid: boolean;
  state: string;
  severity: string;
  details: string;
  created_time: string;
  resolved_time: string;
}


const DMPAlarms: React.FC<{}> = () => {

    const AppContextObj = useContext(AppContext);
    let { id, tabname } = useParams();
    const stateInit = {
        loggedUser: AppContextObj.user,
        pageTitle: strings.NAV_MANAGE_ALARMS,
        showAlertModal: false,
        editUserId: '',
        breadCrumbArr: [{label: strings.NAV_ALERTS, url:''}, {label: strings.NAV_MANAGE_ALARMS, url:''}],
        refresh:false,
        isRowDeleted:false
    };

    const [alarmState, setAlarmState] = useState<IAlarmStates>(stateInit)

    const ruleDefinitionFetcher = async () => {
        if(!deviceData['ruleDefinitions']) {
            const ruleDefinitionsData  = await GenericDassQuery(`/rest/rules`, { method: "GET" });
            deviceData['ruleDefinitions'] = ruleDefinitionsData.data.map(ele => { return { label : ele.name,  value: ele.ruleUuid }})
        }
        
        return deviceData['ruleDefinitions'];

    }


    useEffect(() => {

        if(checkLogin(AppContextObj.user)) {
            setAlarmState(prevState => { return {...prevState, loggedUser: AppContextObj.user }})
        }

        ruleDefinitionFetcher();

    },[AppContextObj.user?.userid, alarmState.refresh])


   const getActions = () => {

        let actions: ActionType[] = [
        {
            type: "action",
            text: strings.DELETE_ALARM,
            visible: () => (AppContextObj?.user?.can_delete_rules && !AppContextObj?.user?._readonly),
            render: (row, title) => actionIcon(row.alarmUuid, title, faTrashAlt.iconName),
            action: (alarm) => deleteAlarm(alarm)
        },
        {
            type: "action",
            text: strings.CLOSE_ALARM,
            visible: (row) => (AppContextObj?.user?.can_update_rules && !AppContextObj?.user?._readonly && row.state !== "resolved"),
            render: (row, title) => actionIcon(row.alarmUuid, title, faTimesCircle.iconName),
            action: (alarm) => closeAlarm(alarm)
        }
    ]


        const bulkActions: BulkActionType<IRowType>[] = [
          {
              type: "header",
              text: strings.DEFAULT_ACTIONS
          },
          {
              type: "action",
              text: strings.DELETE_ALARMS,
              render: (row, title) => actionIcon(row.alarmUuid, title, faTrashCan.iconName),
              action: (selectedIds) => deleteAlarmInBulk(selectedIds),
              visible: () => { 
                  return AppContextObj.user?.account_status === "active" 
                         && !AppContextObj.user?._readonly
                         &&  AppContextObj.user?.can_delete_rules
              } 
          },
           
            {
                type: "action",
                text: strings.CLOSE_ALARMS,
                render: (row, title) => actionIcon(row.alarmUuid, title, faTimesCircle.iconName),
                action: (selectedIds) => closeAlarmInBulk(selectedIds),
                bulkActionVisible: (selectedIds) => visibleCloseAlarm(selectedIds),
            }
        ];

        return {
            actions: actions,
            bulkActions:bulkActions
        }
        
    }


    const deleteAlarmInBulk =  async (selectedRows: IRowType[]) => {
        const alarmUuids = selectedRows.map((row) => {
            return row['alarmUuid'];
        })
        if(alarmUuids && alarmUuids.length > 0) {
            const confirmDialogeSettings = {
                title:  strings.BULK_DELETE_CONFIRM_DIALOAG_ALARMS,
                description: "",
                actionLabel: strings.ACTION_DELETE,
            };

            if (await dialog(confirmDialogeSettings) === true) {
                try {
                    Promise.all(alarmUuids.map((alarmUuid) => {
                        return GenericDassQuery("/rest/alarms/" + alarmUuid, { method: "DELETE" });
                    })).then((values) => {
                        refreshTable(true);
                        toast.success(strings.BULK_DELETE_ALARM_SUCCESS_MESSAGE);
                    });
                } catch (e) {
                    toast.error(e.message);
                }
            }
        }
    }


    const closeAlarmInBulk = async (selectedRows: IRowType[]) => {
        const alarmUuids = selectedRows.map((row) => {
            return row['alarmUuid'];
        })
        if(alarmUuids && alarmUuids.length > 0) {
            const confirmDialogeSettings = {
                title:  strings.BULK_CLOSE_CONFIRM_DIALOG_ALARMS,
                description: "",
                actionLabel: strings.ACTION_CLOSE,
            };

            if (await dialog(confirmDialogeSettings) === true) {
                Promise.all(alarmUuids.map((alarmUuid) => {
                    return GenericDassQuery("/rest/alarms/close/" + alarmUuid, { method: "PUT" });
                })).then((values) => {
                    refreshTable();
                    toast.success(strings.BULK_CLOSE_ALARM_SUCCESS_MESSAGE);
                }).catch((e) => {
                    console.log(e);
                    toast.success(strings.BULK_CLOSE_ALARM_SUCCESS_MESSAGE);
                    refreshTable();    
                });
            }
        }
    }

    const visibleCloseAlarm = (selectedRows: IRowType[]) => {
        if (AppContextObj.user?.account_status === "active" && !AppContextObj.user?._readonly &&  AppContextObj.user?.can_update_rules) {
            return selectedRows.some((row) => row.state !== "resolved");                        
        } else {
            return false;
        }
    }


   const deleteAlarm =  async (alarm: IRowType) => {
        const confirmDialogeSettings = {
            title:  strings.DELETE_CONFIRM_DIALOG_ALARM,
            description: `${strings.FOR_DEVICE}"${alarm.entityName || alarm.entityId}"`,
            actionLabel: strings.ACTION_DELETE,
        };

        if (await dialog(confirmDialogeSettings) === true) {
            try {

                Promise.resolve(GenericDassQuery("/rest/alarms/" + alarm.alarmUuid, { method: "DELETE" })).then((values) => {

                    refreshTable(true);
                    toast.success(strings.DELETE_ALARM_SUCCESS_MESSAGE);

                });
               
            } catch (e) {
                toast.error(e.message);
            }
        }
    }

    const closeAlarm = async (alarm: IRowType) => {
        const confirmDialogeSettings = {
            title:  strings.CLOSE_CONFIRM_DIALOG_ALARM,
            description: `${strings.FOR_DEVICE}"${alarm.entityName || alarm.entityId}"`,
            actionLabel: strings.ACTION_CLOSE,
        };

        if (await dialog(confirmDialogeSettings) === true) {
            try {
                Promise.resolve(GenericDassQuery("/rest/alarms/close/" + alarm.alarmUuid, { method: "PUT" })).then((values) => {
                    refreshTable();
                    toast.success(strings.CLOSE_ALARM_SUCCESS_MESSAGE);
                });
            } catch (e) {
                toast.error(e.message);
            }
        }
    }

    const renderRule = (ruleUuid: string) => {
        let ruleDefinitions = "";
        try {

            if (deviceData['ruleDefinitions'] && deviceData['ruleDefinitions'].length > 0) {
                const findIndex = deviceData['ruleDefinitions'].findIndex((res) => res.value == ruleUuid);
                if (findIndex != -1) {
                    if (!ruleDefinitions) {
                        ruleDefinitions = ruleDefinitions + deviceData['ruleDefinitions'][findIndex].label
                    } else {
                        ruleDefinitions = ruleDefinitions + ',' + deviceData['ruleDefinitions'][findIndex].label
                    }

                }
            }
            return ruleDefinitions

        } catch (e) {
            console.log("e", e)
        }
        
        return ruleDefinitions
    }

   const initDataTable = () => {
        
        const {actions, bulkActions} = getActions();

        const columns: ColumnType[] = [
            {
                key: 'bulk_action_checkbox',
                type: "bulk_action_checkbox",
                title: 'Bulk Action',
                filterable: false,
                cellWidth: 3,
                newCellWidth: "30px",
                customClass: 'sticky left-first',
            },
            {
              key: 'alarmUuid',
              type: "text",
              title: strings.TABLE_UUID,
              render: (row) => (isMobile ? row.alarmUuid : trustedFormatText(`[[fa-ellipsis]]${row.alarmUuid.slice(-6)}`, row.alarmUuid, null, null)),
              cellWidth: 3,
              newCellWidth: (isMobile ? UUIDCOLWIDTH : '90px'),
              copyLink: true,
              customClass: 'font-monospace fa-80 sticky left-second',
              render_tooltip: (row) => row.alarmUuid,
              filterable: true,
              filterField: "search_alarm_uuid",
              sortable: true,
              sortKey: "sort_by_alarm_uuid",
              filterType: "text",
              dataAlign: "center",
              enabledInColumn: false,
              inputValidation: ID_INPUT_VALIDATION

          },
          {
            key: 'entityId',
            type: "text",
            title: strings.ENTITY_ID,
            render: (row) => row.entityId,
            newCellWidth: '250px',
            customClass: 'ow-datatable-overflow-ellipsis',
            render_tooltip: (row) => row.entityId,
            filterable: true,
            filterField: "search_entity_id",
            sortable: true,
            sortKey: "sort_by_entity_id",
            filterType: "text",
            enabledInColumn: false,
            inputValidation: ID_INPUT_VALIDATION
    
        },
        {
            key: "entityName",
            type: "text",
            title: strings.ENTITY_NAME,
            filterable: true,
            filterField: 'search_entity_name',
            filterType: 'text',
            sortKey: "sort_by_entity_name",
            sortable: false,
            inputValidation: ID_INPUT_VALIDATION,
            filterParams: {
                mapper: (x) => x || undefined
            },
            extraClass: 'ow-datatable-overflow-ellipsis',
            enabledInColumn: true,
            newCellWidth: isMobile ? "180px": "150px",
            render_tooltip: (row) => row.entityName
  
        },
        {
            key: "ruleUuid",
            title: strings.RULE_NAME,
            type: "text",
            filterField: 'search_rule_uuid',
            filterable: true,
            filterType: "multiselect",
            filterParams: {
                optionFetcher: ruleDefinitionFetcher,
                data: [],
                mapper: x => x,
            },
            inputValidation: DEFAULT_INPUT_VALIDATION,
            sortable: false,
            sortKey: "sort_by_alarm",
            cellWidth: 50,
            dataAlign:'left',
            newCellWidth: "150px",
            extraClass: 'ow-datatable-overflow-ellipsis',
            enabledInColumn: true,
            render: x => { return renderRule(x.ruleUuid || '') },
            render_tooltip: x => { return renderRule(x.ruleUuid || '') }
        }, 
        {
            key: "severity",
            title: strings.SEVERITY,
            type: "text",
            filterField: 'search_severity',
            filterable: true,
            filterType: 'select',
            filterWidth: "120px",
            sortable: true,
            sortKey: 'sort_by_severity',
            newCellWidth: "100px",
            enabledInColumn: true,
            filterParams: {
           
            options: [
                { label: strings.ALARM_SEVERITY_CRITICAL, value: "critical" },
                { label: strings.ALARM_SEVERITY_WARNING, value: "warning" },
                { label: strings.ALARM_SEVERITY_INFO, value: "info" },
            ], 
          }
      },
        {
            key: "state",
            type: "text",
            title: strings.STATE,
            filterable: true,
            filterField: 'search_state',
            filterType: 'select', 
            sortKey: "sort_by_state",
            sortable: true,
            inputValidation: ID_INPUT_VALIDATION,
            extraClass: 'ow-datatable-overflow-ellipsis',
            customClass: 'nowarp',
            newCellWidth: "100px",
            enabledInColumn: true,
            filterParams: {
                options: [
                    { label: strings.ALARM_STATE_PENDING, value: "pending" },
                    { label: strings.ALARM_STATE_ACTIVE, value: "active" },
                    { label: strings.ALARM_STATE_RESOLVED, value: "resolved" },
                ],
            }

        },
          {
            key: "entityType",
            type: "text",
            title: strings.ENTITY_TYPE,
            filterable: false,
            filterField: 'search_entity_type',
            filterType: 'text',
            sortKey: "sort_by_entity_type',",
            sortable: false,
            inputValidation: ID_INPUT_VALIDATION,
            filterParams: {
                mapper: (x) => x || undefined
            },
            extraClass: 'ow-datatable-overflow-ellipsis',
            enabledInColumn: false,
            newCellWidth: "80px",

        },
       
          {
            key: "created_time",
            title: strings.CREATED_AT,
            type: "text",
            filterable: true,
            filterField: "date",
            filterType: "daterange",
            inputValidation: DEFAULT_INPUT_VALIDATION,
            sortable: true,
            sortKey: "sort_by_created_time",
            filterParams: {
                startField: "from_created_date",
                endField: "to_created_date",
                mapper: (x) => x && x.format()
            },
            cellWidth: 50,
            dataAlign: "center",
            render: x => dateTimeString(x.created_time),
            enabledInColumn: true,
            newCellWidth: "180px"
        }, 
        {
          key: "resolve_time",
          title: strings.RESOLVE_TIME,
          type: "text",
          filterable: true,
          filterField: "date",
          filterType: "daterange",
          inputValidation: DEFAULT_INPUT_VALIDATION,
          sortable: true,
          sortKey: "sort_by_resolve_time",
          filterParams: {
            startField: "from_resolve_date",
            endField: "to_resolve_date",
            mapper: (x) => x && x.format()
        },
          cellWidth: 50,
          dataAlign:'center',
          render: x => dateTimeString(x.resolve_time),
          enabledInColumn: true,
          newCellWidth: "180px"
      },
      {
        key: "details",
        title: strings.DETAILS,
        type: "text",
        filterable: false,
        filterField: "search_details",
        filterType: "text",
        inputValidation: DEFAULT_INPUT_VALIDATION,
        sortable: false,
        sortKey: "sort_by_details",
        filterParams: {},
        cellWidth: 50,
        dataAlign:'left',
        extraClass: 'ow-datatable-overflow-ellipsis',
        newCellWidth: "350px",
        enabledInColumn: false,
        render_tooltip: (row) => row.details
    }
        ];

        columns.push({
            key: 'action_button',
            type: "action_button",
            title: 'Actions',
            filterable: false,
            cellWidth: 3,
            newCellWidth: "140px",
            customClass: 'sticky right',
        });

        if (isMobile) prioritizedTableColumns(['bulk_action_checkbox', 'entityName'], columns)

        const options:DataTableOption<IRowType> = {
            
            url: "/uiapi/rest/alarms",
            query_param: { all:true, get_pages:true, limit:DEFAULT_RECORD_LIMIT, stream:'progress'},
            serial_number: false,
            id_field: "alarmUuid",
            oboe_path: "pages.*",
            available_key: "alarmUuid",
            modal: false, // pass true to render single select radio buttons in place of checkbox
            columns,
            actions: actions,
            defaultSortField: "sort_by_created_time",
            defaultSortOrder: "desc",
            emptyDataMsg: strings.NO_ALARM_AVAILABLE,
            resizeWidth:['entityName', 'ruleUuid'],
            bulkActions: getVisibleActions(bulkActions)

        }

        return options;
    }
    
 

    const refreshTable = (isRowDeleted=false) => {

        setAlarmState(prevState => {
            return {...prevState, refresh:!prevState.refresh,isRowDeleted:isRowDeleted}
        })
        
    }
    
   const getPageButtons = () => {
        
        const pageButtons: PageButtonType[] = [
            {
              
                title: strings.REFRESH_LIST,
                action: () => { refreshTable() },
                type: 'button',
                icon: faRefresh
            },
            {
                title: strings.COLUMNS,
                action: () => { console.log(strings.ADD_USER) },
                type: 'column',
                icon: faListCheck
            }
        ];

        return pageButtons;
    }


    return (
        <PageContent
            name="my-alarms" 
            id={id} 
            tabname={tabname} 
            actions={getActions()} 
            breadCrumbArr={alarmState.breadCrumbArr} 
            pageButtons={getPageButtons()} 
            countLabel={strings.NAV_MANAGE_ALARMS} 
            dataTableOption={initDataTable()} 
            isRowDeleted={alarmState.isRowDeleted}
            refresh={alarmState.refresh}>
        </PageContent>
    );

}


export default DMPAlarms;

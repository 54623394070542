import React, {useState, useEffect, useContext } from "react";
import { useParams, useNavigate  } from 'react-router-dom';
import { faRefresh, faThumbsUp }  from '@fortawesome/free-solid-svg-icons';
import { faTimesCircle } from '@fortawesome/pro-regular-svg-icons';
import { strings } from "./../../services/Localization";
import { checkLogin } from "./../../services/Login";
import AppContext from '../../context/AppContext'
import { dateTimeString, } from '../../utils/filters';
import { BreadCrumbType, PageButtonType } from '../../datatypes/datatypes';
import { GenericDassQuery } from "../../services/BasicDassQueries";
import { toast } from "./../../utils/Toaster";
import { dialog, dialogDescription } from '../../components/Common';
import PageContent from "../PageContent";
import { appBaseUrl, getAppBase } from '../../utils/consts';
import { IUser } from "../../../src/dassTypes";
import { ActionType, BulkActionType, ColumnType, DataTableOption, OptionType } from "../../../src/components/Common/DataTable/DataTypes";
import { TAB_ALARM_INFO } from '../../datatypes/tabsconstants'
import{ ID_INPUT_VALIDATION, DEFAULT_INPUT_VALIDATION, DEFAULT_RECORD_LIMIT }  from "../../components/Common/DataTable/DataTableConsts";
import { isMobile } from "react-device-detect";
declare const constants;

interface IAlarmStates {
    loggedUser: IUser | null;
    showAlertModal: boolean;
    pageTitle: string;
    editEntityId: string | null;            // The entity in this view is a gateway
    schemaMethod: "post" |"put" | "" | string;
    breadCrumbArr: BreadCrumbType[];
    refresh:boolean;
    isRowDeleted?:boolean;
}

interface IAlarmProps {
    alarmtype: "gateway" | "server";
}

const Alarms: React.FC<IAlarmProps> = (props) =>  {

    const { id, tabname } = useParams();
    const navigate = useNavigate();
    const AppContextObj = useContext(AppContext);

    const stateInit: IAlarmStates = {
        loggedUser: null,
        showAlertModal: false,
        pageTitle: strings.GATEWAYS_TITLE,
        editEntityId: '',
        schemaMethod: "",
        breadCrumbArr: [{label: props.alarmtype === "gateway" ? strings.NAV_NST_GATEWAY_ALARMS : strings.NAV_NST_SERVER_ALARMS, url:''}],
        refresh:false,
        isRowDeleted:false
    };

    const [state, setState] = useState<IAlarmStates>(stateInit)

    useEffect(() => {
        if (constants.enable_profiles !== true) {
            window.location.href = appBaseUrl;
        } else {
            if(checkLogin(AppContextObj.user)) {

                setState(prevState => { return {...prevState, loggedUser: AppContextObj.user}})
                // user = AppContextObj.user;

            }else {
                window.location.href = appBaseUrl + "/signin";
            }
            //LoggedIn();
        }
    },[])

    const detailPageNav = ( navigate, tab, id, row = null) => {
        if(id) {
            navigate(props.alarmtype === "gateway"
                        ? `${getAppBase()}/gateway-alarms/${id}/${tab}`
                        : `${getAppBase()}/server-alarms/${id}/${tab}`, 
                    props.alarmtype ==="gateway"
                        ? {state: {row: row, prevPageUrl: `${getAppBase()}/gateway-alarms`}} 
                        : {state: {row: row, prevPageUrl: `${getAppBase()}/server-alarms`}})
        }
    }

    const deleteAlarm = async ( alarm ) => {

        try {
            await GenericDassQuery(((props.alarmtype === "gateway") ? "/rest/omc/alarms/gtw/" : "/rest/omc/alarms/server/") + alarm.id, {
                method: "DELETE"
            });
            toast.success("Alarm suppressed");
        } catch (e) {
            toast.error(e.message);
        }
        refreshTable();
    }

    const ackAlarm = async ( alarm ) => {

        try {
            await GenericDassQuery(((props.alarmtype === "gateway") ? "/rest/omc/alarms/gtw/" : "/rest/omc/alarms/server/") + alarm.id, {
                method: "PUT",
                data: { state: "ACKNOWLEDGED", ticketComment: alarm?.netype}
            });
            toast.success("Alarm acknowledged");
        } catch (e) {
            toast.error(e.message);
        }
        refreshTable();
    }

    const deleteBulkAlarms = async (selectedRows) => {
        
        const alarmIds = selectedRows.map((row) => {
            return row['id'];
        })

        if(alarmIds && alarmIds.length > 0) {
            const confirmDialogeSettings = {    
                title:  `Do you want to delete ${alarmIds.length} alarms. Only Closed alarms selected will be deleted.`,
                description: await dialogDescription(alarmIds),
                actionLabel: 'Delete',
            };
    
            if (await dialog(confirmDialogeSettings) === true) {
                try {
                    let alarmfails = []
                    for(let alarmId of alarmIds) {
                        try {
                            await GenericDassQuery(((props.alarmtype === "gateway") ? "/rest/omc/alarms/gtw/" : "/rest/omc/alarms/server/") + alarmId , { method: "DELETE" });
                        } catch(e) {  
                            alarmfails.push(alarmId) 
                        }
                    }
                    if(alarmfails.length > 0) {
                        toast.error(`Failed to delete ${alarmfails.length} alarms's \r\n ${alarmfails.join(", <br>")}`);
                    } else {
                        toast.success("Alarms deleted sucessfully");
                        refreshTable();
                    }
                } catch (e) {
                    toast.error(e.message);
                }                
            } 
        }
    } 

    const  getActions = () => {

        const actions: ActionType[] = [
                {
                    type: "action",
                    text: "Acknowledge Alarm",
                    icon: faThumbsUp,
                    action: (alarm) => ackAlarm(alarm),
                    visible: (alarm) => alarm.state !== "ACKNOWLEDGED",
                },
                {
                    type: "action",
                    text: "Delete Alarm",
                    icon: faTimesCircle,
                    action: (alarm) => deleteAlarm(alarm),
                    visible: (alarm) => alarm.state === "CLOSED",
                }
        ];
        const bulkActions: BulkActionType[] = [
            {
                type: "action",
                text: "Delete Alarms", // Fixme Here we need have stings.xxx
                icon: faTimesCircle,
                action: (alarmIds) => deleteBulkAlarms(alarmIds)
            }
        ];

        return { actions, bulkActions };
    }


    type FilterOptionType = {
        label: string;
        value: string;
    }


    const optionFetcher = async (netype) => {
            
        let pageUrl = `/rest/omc/alarm_types`;

        let alarms  = await GenericDassQuery(pageUrl, { method: "GET" });

        let options = alarms.data.filter(ele => {if (ele.system === netype) { return ele}}).map(ele => {return  {label: ele.description,value: ele.description}} )
        
        return options;
    }
    

    const  initDataTable = () => {

        const actions = getActions();

        let columns: ColumnType[];

        let filterOptions: FilterOptionType[] = [
            { label: "ACTIVE",       value: "ACTIVE" },
            { label: "CLOSED",       value: "CLOSED" }
        ];

        if (props.alarmtype === "gateway") {

            columns = [
                {
                    key: "neid",
                    type: "text",
                    title: strings.GATEWAY_ID,
                    filterable: true,
                    newCellWidth: "180px",
                    filterField: 'search_neid',
                    filterType: 'text',
                    filterParams: {
                        mapper: (x) => x || undefined
                    },
                    sortKey: "sort_by_neid",
                    sortable: true,
                    detailLink:true,
                    copyLink: true,
                    inputValidation: ID_INPUT_VALIDATION,
                    detailPageNav: (row) => detailPageNav(navigate, TAB_ALARM_INFO, row.id, row),
                    customClass: 'font-monospace nowarp'
                },
                {
                    key: "gatewayName",
                    type: "text",
                    title: strings.GATEWAY_NAME,
                    sortKey: "sort_by_gateway_name",
                    sortable: true,
                    filterable: true,
                    inputValidation: DEFAULT_INPUT_VALIDATION,
                    filterField: 'search_gateway_name',
                    filterType: 'text',
                    newCellWidth: "180px",
                    filterParams: {
                        mapper: (x) => x || undefined
                    },
                },
                {
                    key: "alarmDescription",
                    title: strings.TABLE_NST_ALARM_TYPE,
                    type: "text",
                    newCellWidth: "200px",
                    filterable: true,
                    inputValidation: DEFAULT_INPUT_VALIDATION,
                    filterField: 'search_alarm_description',
                    filterType: "multiselect",
                    filterParams: {
                        optionFetcher:() => optionFetcher("GTW"),
                        data: [],
                        mapper: x => x,
                    },
                   
                },
            ];

        } else {

            columns = [
                {
                    key: "neid",
                    type: "text",
                    title: strings.TABLE_NST_SERVER_ID,
                    sortable: true,
                    sortKey: 'sort_by_neid',
                    newCellWidth: isMobile ? '250px' : "180px",
                    filterable: true,
                    inputValidation: ID_INPUT_VALIDATION,
                    filterField: 'search_neid',
                    filterType: 'text',
                    filterParams: {
                        mapper: (x) => x || undefined
                    },
                    detailLink:true,
                    detailPageNav: (row) => detailPageNav(navigate, TAB_ALARM_INFO, row.id, row),
                    customClass: 'font-monospace nowarp'
                },

                {
                    key: "netype",
                    title: strings.TABLE_NST_SERVER_TYPE,
                    type: "text",
                    newCellWidth: "150px",
                    // filterable: false,
                    sortable: true,
                    sortKey: 'sort_by_netype',
                    inputValidation: DEFAULT_INPUT_VALIDATION,
                },
                {
                    key: "alarmDescription",
                    title: strings.TABLE_NST_ALARM_TYPE,
                    type: "text",
                    filterable: true,
                    newCellWidth: "250px",
                    inputValidation: DEFAULT_INPUT_VALIDATION,
                    filterField: 'search_alarm_description',
                    filterType: "multiselect",
                    filterParams: {
                        optionFetcher:() => optionFetcher("SERVER"),
                        data: [],
                        mapper: x => x,
                    },
                   
                },
            ];
        }


        columns = [...columns,

            {
                key: "state",
                title: strings.STATUS,
                type: "text",
                filterable: true,
                sortable: true,
                sortKey: 'sort_by_state',
                newCellWidth: "120px",
                filterWidth: "100px",
                inputValidation: DEFAULT_INPUT_VALIDATION,
                filterField: 'search_state',
                filterType: 'select',
                defaultFilterValue: ["ACTIVE"],
                filterParams: {
                    mapper: (x) => x || undefined,
                    options: filterOptions,
                },
                dataAlign:'center'
            },

            {
                key: "acknowledged_tmst",
                title: strings.TABLE_NST_ACKNOWLEDGED_DATE,
                type: "text",
                filterable: true,
                sortable: true,
                render: (x) => dateTimeString(x.acknowledged_tmst),
                sortKey: 'sort_by_acknowledged_tmst',
                newCellWidth: "180px",
                filterWidth: "150px",
                inputValidation: DEFAULT_INPUT_VALIDATION,
                filterField: 'search_acknowledged_tmst',
                filterType: 'date',
                filterParams: {
                    startField: "from_date",
                    endField: "to_date",
                    mapper: (x) => x && x.format()
                }
            },

            {
                key: "starttmst",
                title: strings.TABLE_NST_START_DATE,
                type: "text",
                sortKey: "sort_by_starttmst",
                newCellWidth: "180px",
                filterWidth: "150px",
                sortable: true,
                render: (x) => dateTimeString(x.starttmst),
                filterable: true,
                inputValidation: DEFAULT_INPUT_VALIDATION,
                filterField: "search_starttmst",
                filterType: 'date',
                filterParams: {
                    startField: "from_date",
                    endField: "to_date",
                    mapper: (x) => x && x.format()
                },
            },

            {
                key: "endtmst",
                title: strings.TABLE_NST_END_DATE,
                type: "text",
                sortKey: "sort_by_endtmst",
                newCellWidth: "180px",
                filterWidth: "150px",
                sortable: true,
                filterable: true,
                inputValidation: DEFAULT_INPUT_VALIDATION,
                // defaultFilterValue: null,
                filterField: 'search_endtmst',
                render: (x) =>  dateTimeString(x.endtmst),
                filterType: 'date',
                filterParams: {
                    startField: "from_date",
                    endField: "to_date",
                    mapper: (x) => x && x.format()                }
            }
        ];



        const options: OptionType = {
            url:'/uiapi/rest/omc/alarms',
            query_param: {
                get_pages: true,
                limit: DEFAULT_RECORD_LIMIT,
                stream: 'progress',
                search_netype: props.alarmtype === "gateway" ? "GTW" : "not(GTW)",
                //search_state: 'ACTIVE'
            } as any,                       // TODO: if optional parameters are not provided here, then where?
            serial_number: false,
            id_field: 'id',
            oboe_path: 'pages.*',
            available_key: 'id',

        }

        const allOptions: DataTableOption = {
            ...actions,
            columns: columns,
            ...options,
        }
        if (props.alarmtype === "gateway") {
            Object.assign(allOptions, {
                extraSearchProp: {key:'search_tags', label:'', filterType: 'tags', populateUrl:'/uiapi/rest/omc/tags'}
            });
        }

        return allOptions;
	}

    const refreshTable = () => {
        setState(prevState => {
            return {...prevState, refresh:!prevState.refresh}
        })
    }


    const  getPageButtons = () => {

        var  pageButtons: PageButtonType[] = [
            {
                title: strings.REFRESH,
                action: () => { refreshTable() },
                type: 'button',
                icon: faRefresh,
            },
        ]

        return pageButtons;
    }

    
    return (<PageContent
        name={`${props.alarmtype}-alarm`}
        id={id} 
        tabname={tabname} 
        actions={getActions()} 
        breadCrumbArr={state.breadCrumbArr} 
        pageButtons={getPageButtons()} 
        countLabel={`Alarms`} 
        dataTableOption={initDataTable()} 
        isRowDeleted={state.isRowDeleted}
        refresh={state.refresh}>
    </PageContent>)


   
}

export default Alarms;
export const english = {
    BUTTON_LANG_EN: 'English',
    BUTTON_LANG_ZH: '中文',
    BUTTON_LANG_FR: 'Français',
    BUTTON_LANG_AR: "العربية",  
    BUTTON_LANG_HI: "हिन्दी",
    BUTTON_LANG_DD: 'See text names',

    EDIT_GLOBAL_PUSH_CONFIG: 'Edit Default Push Config',

    // Navigation
    NAV_USER_SETTINGS: 'User Settings',
    NAV_ACCOUNT_SETTINGS: 'Account Settings',
    NAV_ABOUT_SYSTEM: 'About System',
    NAV_RETURN_TO: 'Return to {originalUser}',
    NAV_SOFTWARE_INFO: "License information",

    RETURN_TO_OLD_USER: 'Return to Old user',
    NAV_DEVICES_ITEM: 'Devices',
    NAV_MANAGE_GROUPS: 'Groups',
    NAV_MAP: 'Map',

    NAV_GATEWAYS_ITEM: 'Gateways',
    NAV_GATEWAYS_LIST_GATEWAYS: 'Manage Gateways',
    NAV_GATEWAY_TRACE: "View Network Trace",

    NAV_APPLICATION: 'Application',
    NAV_APPLICATIONS_ITEM: 'Application Accounts',

    NAV_LOGINS_ITEM: 'Login',
    NAV_USERS_ITEM: 'Users',
    ADD_ACCOUNT: "Add Account",
    NAV_LOGINS_LIST_USERS: 'Manage User Logins',
    NAV_USERS_LIST_USERS: 'Manage Users',
    NAV_ORGANIZATION_LIST_USERS: 'Manage Organisation',

    NAV_CUSTOMERS_ITEM: 'Customers',
    NAV_CUSTOMERS_LIST_CUSTOMERS: 'Manage Customers',
    NAV_DASHBOARD: 'Dashboard',
    NAV_SYSTEM: 'System',

    NAV_HELP: 'Help',
    NAV_FUOTA_CAMPAIGNS_ITEM: 'FUOTA Campaigns',

    NAV_SWITCH_TO_NST: "Switch to NST",

    NAV_JOIN_SERVERS: "Join Servers",
    NAV_GLOBAL_JOIN_SERVERS: "Global Join Servers",

    NAV_NST_GATEWAYS: "Gateways",
    NAV_NST_DEVICES: "Devices",
    NAV_NST_RELAYS: "Relays",
    NAV_NST_SERVERS: "Servers",
    NAV_NST_ALARMS: "Alarms",
    NAV_NST_GATEWAY_ALARMS: "Gateway Alarms",
    NAV_NST_SERVER_ALARMS: "Server Alarms",

    NAV_NST_TENANTS: "Tenants",
    NAV_NST_AUDIT_TRAIL: "Audit Trail",
    NAV_NST_AUDIT_TRAIL_GATEWAYS: "Gateway Audit Trail",

    NAV_NST_JOBS: "Jobs",
    NAV_NST_ANALYTICS: "Analytics",
    NAV_NST_GATEWAY_REPORT: "Gateway Report",

    NAV_NST_GENERAL_SETTINGS: "General settings",
    NAV_NST_SERVER_VERSIONS: "Server version",
    NAV_NST_SWITCH_TO_APP: "Switch to APP",

    // Welcome
    WELCOME_LEARN_MORE: 'Learn More',

    // My Apps
    MY_APPS_ADD_APP: 'Add Application',
    MY_APPS_APP: 'Application',
    MY_APPS_ACTION_EDIT: 'Edit App',
    MY_APPS_ACTION_DELETE: 'Delete App',
    MY_APPS_ACTION_BULK_DELETE: 'Delete Apps',
    MY_APPS_STOP_DEFAULT_PUSH: "Stop Default Push",
    MY_APPS_START_DEFAULT_PUSH: "Start Default Push",

    APP_ID: 'App ID',
    UUID: 'UUID',

    // My devices
    MY_DEVICES_TITLE: 'Devices',
    MY_DEVICE_SUSPEND: 'Suspend Device',
    MY_DEVICE_UNSUSPEND: 'Unsuspend Device',
    MY_DEVICES_DETAIL: 'Device Detail',
    MY_DEVICES_ACTION_SEND: 'Send to device',
    MY_DEVICES_ACTION_DATA: 'View Data',
    MY_DEVICES_ACTION_EDIT_DEVICES: 'Edit Devices',
    MY_DEVICES_ACTION_DELETE_DEVICES: 'Delete Devices',
    BULK_DEVICE_DELETE_TOAST_SUCCESS_MESSAGE: "Devices successfully deleted",
    MY_DEVICES_ACTION_DELETE: 'Delete Device',
    MY_DEVICES_DATA: "Device Data",
    FOR_DEVICE: "for Device ",

    MY_DEVICES_APPLICATIONS: "Apps",
    MY_DEVICES_GROUPS: "Groups",
    GROUP_ASSIGNMENT: "Group Assignment",
    APP_ASSIGNMENT: "App Assignment",
    EDIT_GROUP: "Group Info",
    DELETE_GROUP: "Delete Group",
    DELETE_RULE: "Delete Rule",
    SEND_MULTICAST: "Send Multicast",
    GROUP_ID: "Group ID",
    GROUP_TITLE: "Title",
    ADD_GROUP: "Group",
    ADD_GROUP_TEXT: "Add Group",

    // Network Activity

    // Group Activity
    GROUP_ACTIVITY_TITLE: "Group Activity",

    // Applications
    MY_APPLICATIONS_TITLE: "Applications",
    APPLICATIONS_ADD: "Add Application",

    // Find Devices
    FIND_DEVICES_TITLE: "Find Devices",
    DMP_DEVICE_ID: "Device ID",
    DMP_DEVICE_FIRMWARE_VERSION: "FW Ver",

    // Find Users
    FIND_USERS_TITLE: "Find Accounts",
    FIND_USERS_TABLE_ACCOUNT_TYPE: "Account type",
    FIND_USERS_TABLE_ADMINISTRATOR: "Administrator",
    FIND_USERS_TABLE_REGISTRATION_RIGHTS: "Registration Rights",
    FIND_USERS_TABLE_PARENT: "Parent",

    // Batch Registration
    ERROR: "Error",
    ERROR_MESSAGE: "Error Message",
    SERVICE_PARAMS: "Service Params",
    SERVICE_NO_PARAMS: "No Params",
    BATCH_REGISTER_DEVICES_ERROR1: 'There is an empty field at column {column_num}',
    BATCH_REGISTER_DEVICES_ERROR2: 'Missing some of the mandatory fields DevEUI, device_profile_uuid and service_profile_uuid in the header',
    BATCH_REGISTER_DEVICES_SERVER_ERROR: 'Registering the device on row {index} failed because of - {message_error}',
    BATCH_UPDATE_DEVICES_SERVER_ERROR: 'Updating the device on row {index} failed because of - {message_error}',
    BATCH_ERROR_REGISTERING: 'Error registering devices',
    BATCH_ERROR_UPDATE: 'Error updating devices',
    BATCH_REGISTER_DEVICES_MISSING_REQUIRED_FIELDS: 'Mandatory field {field_name} is missing.',

    // Data
    GROUP_MULTICASTS_MESSAGE: 'There {number, plural, =0{are no multicasts} one{is 1 multicast} other{are # multicasts}} for group: ',
    DATA_DELETE_PACKET: 'Delete Packet',
    DATA_DELETE_PACKETS: 'Delete Packets',

    DIRECTION_SHORT: 'Direction',

    PACKET_STATUS_0: 'Enqueued',
    PACKET_STATUS_1: 'Sent, waiting Ack',
    PACKET_STATUS_1_1: 'Sent',
    PACKET_STATUS_2: 'Ack Ok',
    PACKET_STATUS_3: 'Ack Fail',
    PACKET_STATUS_4: 'Error',
    PACKET_STATUS_5: 'Cancelled',

    TIME: 'Time',
    FCNT: 'FCNT',
    PORT: 'Port',
    DATA_RATE: 'Data Rate',
    DATA: 'Data',
    RSSI: 'RSSI',

    // Send Data Modal

    // Gateway Related
    GATEWAYS_TITLE: 'Gateways',
    GATEWAY_ID: 'Gateway ID',
    GATEWAY_NAME: 'Gateway Name',
    VIEW_GATEWAY: 'Gateway Location',
    DELETE_GATEWAY: 'Delete Gateway',
    DELETE_GATEWAYS: 'Delete Gateways',
    ADD_GATEWAY: 'Add Gateway',

    GATEWAY_STATUS_ANY: 'Any',

    // Modal Add Gateway

    // Map
    MAP_FILTER_DEVICES: 'Filter devices',
    MAP_FILTER_GATEWAYS: 'Filter gateways',
    MAP_FILTERS_OPERATIONAL_STATUS: 'Filter By Health Status',
    MAP_SHOW_DEVICES: 'Show Devices',
    MAP_SHOW_GATEWAYS: 'Show Gateways',
    MAP_MOVABLE: 'Movable',
    MAP_REFRESH_GATEWAYS: "Refresh Gateway",
    MAP_GATEWAY_TABLE_NAME: "Name",
    MAP_GATEWAY_TABLE_ID: "ID",
    MAP_GATEWAY_TABLE_STATUS: "Status",

    MAP_GATEWAY_TABLE_STATUS_LABEL: "Status:",
    MAP_PANEL_TAB_GATEWAYS: "Gateways",
    MAP_PANEL_TAB_DEVICES: "Devices",
    LAST_UPDATE_NOT_SEEN: "Never Seen",

    // Map Info Tooltip
    MAP_INFO_NAME: "Name",
    MAP_INFO_DEVEUI: "DevEUI",
    MAP_INFO_DEVICEUUID: "Device UUID",
    MAP_INFO_DEVICEID: "Device ID",
    MAP_INFO_COMMENT:"Comment",
    MAP_INFO_HEALTH: "Health",
    MAP_INFO_HEALTH_MSG: "Health Msg",
    MAP_INFO_LAST_UPDATE: "Last Update",
    MAP_INFO_ALARMS: "Alarms",

    // Position on map modal

    // Gateway Configuration
    GATEWAY_CONFIGURATION: 'Gateway Info',
    GATEWAY_TYPE: 'Gateway type',

    // Customers
    ADD_CUSTOMER: 'Add Customer',
    CUSTOMER: 'Customer',
    EDIT_CUSTOMER: 'Edit Customer',
    ADMINISTRATOR: 'Administrator',

    // Edit Device Modal

    OWNER: 'Owner',

    ADD_DEVICE_BTN: 'Add Device',
    
    DEVICE_BTN: 'Device',
    DEVICE_DATATABLE_VIEW: 'Basic,Detailed,Expanded',
    FUOTA_BTN:'FUOTA Campaign',
    EXPORT_DEVICE_BTN: 'Export',
    EXPORT_DEVICES_BTN_LABEL: 'Export Devices',
    EXPORT_PAYLOADS_BTN_LABEL: 'Export Payloads',
    IMPORT_DEVICES_BTN_LABEL: "Import Devices",
    PROVISION_DEVICES_BTN: 'Provision Devices',
    IMPORT_DEVICE_BTN: 'Import',
    ADD_FUOTA_CAMPAIGN_BTN: 'FUOTA Campaign',

    // HTTP Push config
    HTTP_PUSH_START: 'Start Push',
    HTTP_PUSH_STOP: 'Stop Push',

    // Warnings

    // Add user modal

    // Messages
    MSG_DEVICE_UPDATE_SUCCESS_BODY: 'Device {deveui} successfully updated',
    MSG_DEVICE_UPDATE_FAIL_BODY: 'Failed to update device. "{data}"',
    MSG_GATEWAY_POS_UPDATE_SUCCESS: 'Gateway successfully updated',
    MSG_GATEWAY_POS_UPDATE_FAIL: 'Failed to update position',

    MSG_TOO_MANY_REQUESTS_WAIT: '[[fa-timer]] You have been throttled[[@br]]The request will resume in {seconds} seconds',
    MSG_TOO_MANY_ATTEMPTS_WAIT: '[[fa-timer]] Too many attempts[[@br]]The sign-in will resume in {seconds} seconds',

    // Add User modal

    // Edit User Modal

    GATEWAY: 'Gateway',

    // Manage App Modal

    // Users list
    USER_LIST_REGISTRATION_RIGHTS: 'Registration Rights',
    USER_LIST_DEVICE_COUNT: 'Registered Devices',

    // Organization list
    ORGANIZATION_LIST_ADD_ORGANIZATION: 'Add Organisation',
    ORGANIZATION: 'Organisation',
    ORGANIZATION_LIST_TITLE: 'Organisations',
    EDIT_ORGANIZATION: 'Edit Organisation',

    ADD_LOGIN: 'Add Login',
    ADD_USER: 'Add User',
    EDIT_LOGIN: 'Edit Login',
    LOGIN: 'Login',
    USER: 'User',
    EDIT_USER: 'Edit User',

    // Generic
    DEVEUI: 'DevEUI',
    DEVICE_UUID: "Device UUID",
    COMMENT: 'Comment',
    DESCRIPTION: 'Description',
    MESSAGE: 'Message',
    OPSTATUSPMESSGAE: 'Health Msg',
    STATUS: 'Status',
    STATE: 'State',
    ALARM: 'Alarm',
    OPERATIONALHEALTH: 'Health',
    CONNECTIONSTATUS: 'Conn Status',
    LAST_SEEN: 'Last Update',
    REFRESH_LIST: 'Refresh List',
    REFRESH_DEVICE_LIST: 'Refresh Device List',
    REFRESH_RELAY_LIST: 'Refresh Relay List',
    STOP_AUTO_REFRESH: 'Stop autorefresh',
    AUTO_REFRESH: 'Autorefresh',
    BLOCKED_GATEWAYS : 'Pending Gateways',
    DEFAULT_GATEWAYS_CONFIG : "Default Gateway LoraWAN radio configurations",
    MANAGE_GATEWAY_AUTHORIZATION: "Manage Gateway Authorization",
    GATEWAY_TAGS : "Gateway Tags",
    DEFAULT_GATEWAY_PARAMS : "Default Gateway Parameters",

    SIGN_IN: 'Sign in',
    SIGN_OUT: 'Sign out',
    USER_ID: 'User ID',
    PASSWORD: 'Password',

    PAYLOADS: 'Payloads',

    VIA: 'via {originalUser}',
    POWERED_BY: 'Powered by',
    WELCOME_RIGHT_MESSAGE: 'PARTNER FOR A SMARTER FUTURE',

    REFRESH: 'Refresh',
    REGISTER: 'Register',
    DELETE: 'Delete',
    UPDATE: 'Update',
    CLEAR: 'Clear',

    GTW_STATUS_NEVER_SEEN: 'Never Seen',
    GTW_STATUS_GTW_INIT: "Registered, never seen",
    GTW_STATUS_OK: 'OK',
    GTW_STATUS_OFF: 'Off',
    GTW_STATUS_BACKHAUL_ISSUE: 'Connection Problem',
    GTW_STATUS_OFF_OR_BACKHAUL_ISSUE: 'Off or Connection Problem',
    GTW_STATUS_RADIO_OFF: 'LoRa Radio Off',

    OK: 'OK',
    CANCEL: 'Cancel',
    YES: 'Yes',
    NO: 'No',
    DECRYPTED: 'Decrypted',
    CLICK_TO_UNLOCK: "Click to unlock",

    // Interface Type Labels
    INTERFACE_TYPE_MQTT: "MQTT",
    INTERFACE_TYPE_HTTP: "HTTP",
    INTERFACE_TYPE_TR069: "TR069",
    INTERFACE_TYPE_EXTMQTT: "EXTMQTT",
    INTERFACE_TYPE_TCP: "TCP",

    NAV_ADMIN_DEVICES: "Find Devices",
    NAV_ADMIN_USERS: "Find Accounts",

    // forgot password functionality
    FORGOT_PASSWORD: "Forgot your password?",
    FORGOT_USERNAME: "Forgot your username?",
    FORGOT_PASSWORD_INSTRUCTIONS: "Please enter your username below and we'll send you instructions to your email how to change your password",
    RETURN_TO_LOGIN: "Return to Log in",
    SET_PASSWORD: "Set password",
    SEND_RESET_PASSWORD_EMAIL: "Send Email",
    RESET_PASSWORD_WELCOME_USER: "Welcome!<br>Please set a password to get started",
    RESET_PASSWORD_MESSAGE: "Please set a new password",
    RESET_PASSWORD_TOO_SHORT: "Too short",
    RESET_PASSWORD_NOT_COMPLEX: "Missing mix case, numbers and/or special characters",
    RESET_PASSWORD_DOSNT_MATCH: "Not matching",
    CONFIRM_PASSWORD: "Confirm Password",

    EMAIL_USERNAME_INSTRUCTIONS_ORBIWISE: "[[fa-envelope]] Password Reset Request Submitted",

    INVALID_TOKEN: "The password reset link has expired, please make a new request for resetting your password",
    PASSWORD_RESET_FAILED: "Unable to reset password",
    PASSWORD_RESET_EMAIL_TOO_MANY: "Too many reset email attempts, please try again later",

    // Email verification
    EMAIL_VERIFICATION_SUCCESSFUL: "Email succesfully verified",
    EMAIL_VERIFICATION_INVALID: "Invalid email verification link",
    EMAIL_VERIFICATION_EXPIRED: "Email verification link no longer valid",

    //password policies

    MAP_NO_POSITION_SET: "No position set",
    BATCH_REGISTER_MAX_DEVICES: "The number of devices is bigger that the allowed number of devices for the user",
    NAV_PROFILES_ITEM: "Profiles",
    NAV_DEVICE_PROFILES: "Device Profiles",
    NAV_SERVICE_PROFILES: "Service Profiles",
    NAV_CONNECTIVITY_PROFILES: "Connectivity Profiles",
    NAV_ROAMING_PROFILES: "Roaming Profiles",

    SERVICE_PROFILE: "Service Profile",

    CONNECTIVITY_PROFILE: "Connectivity Profile",
    LOGIN_FAILED: "Login Failed",
    LOGIN_BLOCKED_DUE_TO_TOO_MANY_ATTEMPTS: "Too many failed signin attempts, signin temporarely suspended. Please try again later.",

    ROAMING_PROFILE: "Roaming Profile",

    ACTION_ADD: "Add Profile",
    CONNECTION_ADD: "Add Connection",
    IMPORT_PROFILE: "Import Profile",
    PROFILE_MSG_DELETE_SUCCESS: "Profile successfully deleted",
    CONNECTION_MSG_DELETE_SUCCESS: "Connection successfully deleted",
    PROFILE_MSG_EXPORT_SUCCESS: "Profile successfully exported",
    CONNECTION_MSG_EXPORT_SUCCESS: "Connection successfully exported",
    PROFILE_MSG_UNLINKED_SUCCESS: "Profile successfully unlinked",
    CONNECTION_MSG_UNLINKED_SUCCESS: "Connection successfully unlinked",
    DEVICE_SERVICE_EXECUTE_SUCCESS: "Service executed successfully!",
    DEVICE_SERVICE_SCHEDULED_SUCCESS: "Service scheduled successfully!",
    DEVICE_SERVICE_EXECUTE_ERROR_LIVELOG: "Service not executed. Please check the live log for more information.",
    DEVICE_SERVICE_EXECUTE_ERROR: "Service execution failed. Error:",
    SERVICE_SCHEDULER : "Schedule Service",

    ACTION_DELETE: "Delete",
    ACTION_CLOSE: "Close",
    SELECT_FIRMWARE_WARNING_MESSAGE: " Select a single firmware to proceed. Bulk delete is the only action available for multiple selections, and the 'Next' button will remain disabled until one firmware is selected.",
    TABLE_PROFILE_NAME: "Profile Name",
    TABLE_CONNECTION_NAME: "Connection Name",
    TABLE_IMAGE: "Image",
    TABLE_COMMENT: "Description",
    TABLE_UUID: "UUID",
    TABLE_LINK: "Link",
    TABLE_REGION: "Region",
    
    TABLE_NAME: "Name",
    TABLE_NST_GATEWAY: "Gateway",
    TABLE_NST_GATEWAY_ID: "Gateway ID",
    TABLE_NST_GATEWAY_HAS_BEEN_SEEN: "Seen",
    TABLE_NST_TENANT: "Tenant",
    TABLE_NST_TENANT_UUID: "Tenant UUID",
    TABLE_NST_NUM_ALARMS: "#Alarms",
    TABLE_NST_GATEWAY_LAST_CONNECTION_TIME: "Last connection time",
    TABLE_NST_GATEWAY_DISCONNECT_DURATION: "Disconnect duration",
    TABLE_NST_GATEWAY_TYPE: "Gateway Type",
    TABLE_NST_GATEWAY_INTERFACE_TYPE: "Gateway Interface",
    TABLE_NST_GATEWAY_SW_VERSION: "Software Version",
    TABLE_NST_GATEWAY_BACKHAUL: "Backhaul",
    TABLE_NST_GATEWAY_CELLULAR_RSSI: "Cellular RSSI",
    TABLE_NST_GATEWAY_CELLULAR_CONNECTION: "Cellular Connection",
    TABLE_NST_GATEWAY_PCT_USED_DC: "Duty Cycle (% used)",
    
    TABLE_NST_DEVICE_COMMENT: "Device comment",
    TABLE_NST_RELAY_COMMENT: "Description",
    TABLE_NST_RELAY_STATUS: "Relay status",
    TABLE_NST_RELAY_NUM_DEVICE_RELAYED: "Number of relayed devices",
    TABLE_NST_RELAY_NUM_FILTER_RULES: "Number of filter rules",    TABLE_NST_DEVADDR: "DevAddr",
    TABLE_NST_SPREADING_FACTOR: "SF",
    TABLE_NST_LAST_RECEPTION_TIME: "Lst RX",
    TABLE_NST_LAST_RSSI: "Lst RSSI",
    TABLE_NST_LAST_SNR: "Lst SNR",
    TABLE_NST_MAIN_GATEWAY_ID: "Main Gateway",
    TABLE_NST_NUM_OF_GATEWAYS: "#Gtwys",
    TABLE_NST_MAIN_GATEWAY_NAME: "Main Gtwy NM",

    NST_SERVERS_STOP_SERVER: "Stop Server",
    NST_SERVERS_RESTART_SERVER: "(Re)Start Server",

    TABLE_NST_SERVER: "Server",
    TABLE_NST_IP_ADDRESS: "IP",
    TABLE_NST_METRICS: "Metrics",
    TABLE_NST_SERVER_STATUS: "Status",
    TABLE_NST_ALARM_STATUS: "Alarm Status",
    TABLE_NST_SERVER_STATE: "Server State",

    TABLE_NST_ALARM_TYPE: "Alarm Type",
    TABLE_NST_SERVER_ID: "Server ID",
    TABLE_NST_SERVER_TYPE:  "Server Type",
    TABLE_NST_START_DATE: "Start Date",
    TABLE_NST_END_DATE: "End Date",
    TABLE_NST_START_TIME: "Start Time",
    TABLE_NST_JOB_TYPE: "Type",
    TABLE_NST_DESCRIPTION: "Description",
    TABLE_NST_BATCH_ID: "Batch ID",
    TABLE_NST_OWNER: "Owner",
    TABLE_NST_ACKNOWLEDGED_DATE:"Acknowledged date",

    TABLE_NST_TIME: "Time",
    TABLE_NST_USER: "User",
    TABLE_NST_EVENT: "Event",

    NST_TENANT_CERTIFICATE_ACTION: "Certificate",
    
    DELETE_PROFILE_MODAL_TITLE: "Delete Profile",
    DELETE_CONNECTION_MODAL_TITLE: "Delete Connection",
    DELETE_PROFILE_MESSAGE_MODAL_BODY: "Are you sure you want to delete {ProfileName} profile?",
    DELETE_CONNECTION_MESSAGE_MODAL_BODY: "Are you sure you want to delete {ProfileName} connection?",

    PROFILE_EDIT_ACTION: "Edit Profile",
    PROFILE_DELETE_ACTION: "Delete Profile",
    PROFILE_DELETE_BULK_ACTION: "Delete Profiles",
    CONNECTION_DELETE_ACTION: "Delete Connection",
    CONNECTION_DELETE_BULK_ACTION: "Delete Connections",

    DEVICE_PROFILE: "Device Profile",
    IMPORT_DEVICE_PROFILE: "Import Device Profile",
    IMPORT_CONNECTION_PROFILE: "Import Connection Profile",

    QOS_PROFILE: "QoS Profile",
    NAV_QOS_PROFILES: "QoS Profiles",
    NAV_CHANNEL_PROFILES: "Channel Profiles",
    NAV_RELAY_PROFILES: "Relay Profiles",
    NAV_CONNECTION_PROFILES: "Connection Profiles",
    NAV_CONNECTIONS: "Connections",
    CHANNEL_PROFILE: "Channel Profile",
    RELAY_PROFILE: "Relay Profile",
    CONNECTION_PROFILE: "Connection Profile",
    CONNECTIONS: "Connections",
    CONNECTION: "Connection",

    SELECT_PROFILE_PLACEHOLDER: "Select profile",

    //Static messages

    BATCH_MUST_BE_A_STRING: " must be a string",
    BATCH_FIELD: "Field",
    BATCH_INVALID_VALUE: "contains an invalid value.",
    BATCH_MUST_8: " must be an 8-byte identifier.",
    BATCH_ERROR_MUST_16BIT: " must be an 16-byte identifier.",
    BATCH_ERROR_MUST_32BIT: " must be an 32-bit identifier.",
    BATCH_VALID_VALIE_BETWEEN: " valid values are between ",
    BATCH_TO: " to",
    BATCH_VALID_IS_NOT_SUPPORTED: " is not supported",
    BATCH_MUST_BE_BOOLEN: " must be boolean",
    BATCH_CAN_ONLY_BE_A_NUMBER: "can only be a number",
    BATCH_ALLOWED_VALUES_FOR: "Allowed values for ",
    BATCH_ALLOWED_VALUES_FOR_IS: " are sf7, sf8, sf9, sf10, sf11 and sf12",
    BATCH_ALLOWED_VALUES_FOR_STATIC: " are static mobile indoor and outdoor or combination from static,indoor static,outdoor mobile,indoor mobile,outdoor",
    BATCH_OTAA_ABP: " can only have values OTAA or ABP",
    BATCH_0_9: " valid values are 0 to 100",
    BATCH_0: " can only be 0",
    BATCH_CAN_ONLY_VERSION: " can only be 1.1.1, 1.0.4, 1.0.3, 1.0.2, 1.0.1 or 1.0.0",
    BATCH_CAN_A_B: " can only be A or B",
    BATCH_CAN_EU_US_CH: " can be EU868, US902, China779, EU433, Australia915, China470, AS923 or INDIA",
    BATCH_ERROR_ROW: " Errors found on row ",
    BATCH_ERROR: " error",
    MAC_MSG: "MAC Messages",

    SHOW_DECODE_PAYLOAD_SHORT: "Decoded",
    SHOW_DATA: "Data",
    SHOW_DMP_DEVICE_MESSAGE: "Message",
    SHOW_DMP_DEVICE_TOPIC: "Topic",

    POSITION_ESTIMATES_SHORT: "Pos Est",    
    DUPLICATE_HEADERS: "The csv file contains duplicate headers",

    // Linked Profiles
    NEW_LINKED_PROFILE: "Linked Profile",
    ADD_NEW_LINKED_PROFILE: "Create Linked Profile",
    BATCH_REGISTER_DEVICES_ERROR3: "The csv file must contain the mandatory properties for device with profiles",
    BATCH_WRONG_FORMAT: " format is not correct",
    UNLINK_PROFILE_MESSAGE_MODAL_BODY: "Are you sure you want to unlink {ProfileName} profile?",
    UNLINK_LINKED_PROFILE_MODAL_TITLE: "Unlink Profile",
    UNLINK_LINKED_PROFILE_ACTION: "Unlink Profile",
    UNLINK_PROFILE_MODAL_CONFIRM_BUTTON: "Unlink",

    // API Based Table

    TABLE_SHARED: "Shared",
    TABLE_SORRY_NO_DATA_AVAILABLE: "No data available!",
    TABLE_SHOWING: "Showing {value}",
    TABLE_COUNT_ALL: "All",
    TABLE_COUNT_SELECTED: "Selected",
    TABLE_BUTTON_CLEAR_SELECTED: "Clear",
    TABLE_BUTTON_BULK_ACTIONS: "Bulk Actions",
    TABLE_BUTTON_BULK_SERVICE_ACTIONS: "Services",
    TABLE_CONNECTION_INTERFACE_TYPE: "Interface Type",
    COMPILE_FAILED: "Compile Failed",
    TABLE_DEVICE_TYPE: "Device Type",
    TABLE_DEVICE_TYPE_CUSTOM: "Custom",
    TABLE_DEVICE_TYPE_TR069: "TR069",
    TABLE_DEVICE_TYPE_ORBIWAN: "OrbiWAN",

    // EMPTY RECORD MSG

    NO_DEVICE_AVAILABLE: "No Device Available",
    NO_GATEWAY_AVAILABLE: "No Gateway Available",
    NO_GROUP_AVAILABLE: "No Group Available",
    NO_TAG_AVAILABLE: "No Tag Available",
    NO_RESOURCE_AVAILABLE: "No Resource Available",
    NO_APPLICATION_AVAILABLE: "No Application Available",
    NO_PROFILE_AVAILABLE: "No Profile Available",
    NO_SERVICE_AVAILABLE: "No Service Available",
    NO_CONNECTION_AVAILABLE: "No Connection Available",
    NO_JOB_AVAILABLE: "No Job Available",
    NO_RULE_AVAILABLE: "No Alarm Rule Available",
    NO_ALARM_AVAILABLE: "No Alarm Available",
    NO_NOTIFICATION_POLICY_AVAILABLE: "No Notification Policy Available",
    NO_ALARM_DEFINITION_AVAILABLE: "No Alarm Definition Available",

    // Device Profiles Json Schema Form

    // Connectivity Profiles Json Schema Form

    // Roaming Profiles Json Schema Form

    // QoS Profiles Json Schema Form

    // Channel Profiles Json Schema Form

    TABLE_JOIN_EUI_RANGE: "Join EUI range",
    TABLE_JOIN_SERVER_NAME: "Name",
    TABLE_JOIN_SERVER_TYPE: "Type",
    TABLE_JOIN_SERVER_URL: "URL",

    ADD_JOIN_SERVER: "Join Server",

    FIND: "Find",
    ACCOUNTS: "Accounts",
    NAV_SIGNED_AS: "Signed as",
    ADMIN_TYPE_CUSTOMER: "Customer Administrator",
    ADMIN_TYPE_USER: "User Administrator",
    ADMIN_TYPE_ORGANIZATION: "Organisation Administrator",
    TYPE_CUSTOMER: "Customer",
    TYPE_USER: "User",
    SWITCH_ACCOUNT: "Switch Account",

    SUSPEND_DEVICES: "Suspend Devices",
    UNSUSPEND_DEVICES: "Unsuspend Devices",

    CHANGE_OWNER: "Change Owner",

    CREATABLE_SELECT_LABEL: "UUID",

    CAN_NOT_RETURN_TO_PARENT: "Return to action failed",

    ALERT_MODAL_TITLE_TEXT: "Error",

    MANAGE_APPLICATIONS_NAV_TITLE: "Manage Applications",

    ACTIVE_CONNECTIONS_NAV_TITLE: "Active Connections",
    ACTIVE_CONNECTIONS_PAGE_TITLE: "Active Connections",
    ACTIVE_CONNECTIONS_TABLE_COL_TYPE: "Type",
    ACTIVE_CONNECTIONS_TABLE_COL_STATUS: "Status",
    ACTIVE_CONNECTIONS_TABLE_COL_CONN_TIME: "Connection Time",
    ACTIVE_CONNECTIONS_TABLE_COL_APPLICATION: "Application",
    ACTIVE_CONNECTIONS_TABLE_COL_REMOTE_ADDRESS: "Remote Address / Target URL",
    ACTIVE_CONNECTIONS_TABLE_COL_SUBSCRIPTIONS: "Subscriptions",
    ACTIVE_CONNECTIONS_TABLE_COL_ERRORS: "Errors",

    EVENT_LOG_NAV_TITLE: "Event Log",
    EVENT_LOG_PAGE_TITLE: "Event Log",
    EVENT_LOGS_TABLE_COL_TIMESTAMP: "Timestamp",
    EVENT_LOGS_TABLE_COL_EVENT: "Event",
    EVENT_LOGS_TABLE_COL_APPLICATION: "Application",
    EVENT_LOGS_TABLE_COL_TEXT: "Text",

    EVENT_LOGS_TABLE_LOG_LEVEL: "Log Level",

    RE_PUSH_PAYLOAD: "(Re-)Push Payload",

    PRODUCT_LICENSE_TITLE: "Product License",
    LORA_UPLINK_LOGS: "LoRa uplink logs",
    GET_SERVER_LOGS: "Server logs",

    // HELP
    NAV_HELP_GETTING_STARTED: "Getting started",
    NAV_HELP_PAGE: "Documentation",
    NAV_HELP_SWAGGER: "Swagger",
    NAV_HELP_SUPPORT_EMAIL: "Email support",
    COLUMNS: "Columns",

    TENANT_DELETE: "Delete Tenant",
    TENANTS_DELETE: "Delete Tenants",
    TENANT_ADD: "Add Tenant",
    TENANT_TITLE: "Tenant",
    TENANT_CERTIFICATE: "Certificate",
    TENANT_INFO: "Tenant Info",
    SEARCH_MAP: "Search Maps",
    FUOTA_CAMPAIGN: "FUOTA Campaigns",
    CAMPAIGN_NAME: "Name",
    CAMPAIGN_DESCRIPTION: "Description",
    CAMPAIGN_FIRMWARE: "Firmware",
    CAMPAIGN_DEVICES: "Devices",
    CAMPAIGN_GROUPS: "Groups",
    CAMPAIGN_STATUS: "Status",
    CAMPAIGN_TYPE: "Type",
    CAMPAIGN_STAGE: "Stage",
    CAMPAIGN_START_TIME: "Start time",
    CAMPAIGN_UPLOAD_DATE: "Upload Date",
    CAMPAIGN_END_TIME: "End time",
    MY_CAMPAIGNS_ACTION_START: "Campaign Start",
    MY_CAMPAIGNS_ACTION_STOP: "Campaign Stop",
    ADD_FUOTA_BTN: "FUOTA",
    MY_DEVICES_ADD_FUOTA_BTN: "Add to FUOTA Campaign",
    FIRMWARE_FILE_SIZE: "File Size (bytes)",
    MY_CAMPAIGNS_ACTION_DELETE: "Delete Campaign",
    DELETE_FIRMWARE_IMAGE: "Delete Firmware Image",
    DELETE_FIRMWARE_IMAGES: "Delete Firmware Images",
    DELETE_FIRMWARE_IMAGE_SUCCESS_MESSAGE: "Firmware image successfully deleted",

    // Resource Storage
    ADD_RESOURCE: "Add Resource",
    EDIT_RESOURCE: "Edit Resource",
    RESOURCE_TITLE: "Resources",
    RESOURCES_DELETE: "Delete Resources",
    RESOURCE_DELETE: "Delete Resource",
    VIEW_RESOURCE_SCHEMA: "View Resource",
    NAV_RESOURCE_ITEM: "Resources",
    DOWNLOAD_RESOURCE: "Download Resource",

    // Tags
    MY_TAGS: "My tags",
    ADD_TAG: "Add Tag",
    EDIT_TAG: "Edit Tag",
    TAGS_DELETE: "Delete Tags",
    TAG_DELETE: "Delete Tag",
    NAV_TAG_ITEM: "Tags",
    TAG_ID: "Tag ID",

    // Named Services

    NAV_NAMED_SERVICE_ITEM: "Named Services",
    ADD_NAMED_SERVICE: "Add Named Service",
    NAMED_SERVICE_DELETE: "Delete Named Service",
    NAMED_SERVICES_DELETE: "Delete Named Services",
    NAMED_SERVICE: "Named Service",

    // Livelog
    NAV_LIVELOG_ITEM: "Live Log",

    // API Documentation
    NAV_API_DOC_ITEM: "API Documentation",

    // ErrorMessage
    ACCESS_DENIED_MESSAGE: "Access Denied: You are not authorized to perform this action.",
    SYSTEM_UNREACHABLE_MESSAGE: "System unreachable",

    // Operational Status Labels
    OPERATIONAL_STATUS_OPTION_NORMAL: "OK",
    OPERATIONAL_STATUS_OPTION_WARNING: "Warning",
    OPERATIONAL_STATUS_OPTION_ERROR: "Error",
    OPERATIONAL_STATUS_OPTION_CRITICAL: "Critical",
    OPERATIONAL_STATUS_OPTION_NEVER_SEEN: "Never Seen",
    OPERATIONAL_STATUS_OPTION_SUSPENDED: "Suspended",
    OPERATIONAL_STATUS_OPTION_CONNECTING: "Connecting",

    // Connection Status Labels
    CONNECTION_STATUS_OPTION_CONNECTED: "Connected",
    CONNECTION_STATUS_OPTION_DISCONNECTED: "Disconnected",
    CONNECTION_STATUS_OPTION_ERROR: "Error",
    CONNECTION_STATUS_OPTION_NEVER_SEEN: "Never Seen",

    // Connection Status Tooltip label

    BULK_DEVICE_SUCCESSFUL_SERVICE_MESSAGE: "Bulk device service execution complete: Service executed successfully on devices",
    BULK_GROUP_SUCCESSFUL_SERVICE_MESSAGE: "Group device service execution complete: Service executed successfully on devices",
    BULK_DEVICE_FAILED_SERVICE_MESSAGE: "Bulk device service execution complete: Failed to execute service on devices",
    BULK_GROUP_FAILED_SERVICE_MESSAGE: "Group device service execution complete: Failed to execute service on devices",
    BULK_MESSAGE_IN_BATCH: "in Batch",
    EXECUTE_SERVICE_TITLE: "Execute Service",
    ACTION_EXECUTE: "Execute",
    SERVICE_PROGRESS_MESSAGE: "Service Progress: {progress}%, Total Devices: {totalDevices}{batchMessage}",
    BATCH_MESSAGE: ", Batch: {currentBatch}/{totalBatches}",

    OPERATIONAL_STATUS_MESSAGE_DEVICE_PROFILE_EVENT_HANDLER_FAILED:"Device Profile event handler code failed to compile.",
    OPERATIONAL_STATUS_MESSAGE_CONNECTION_PROFILE_EVENT_HANDLER_FAILED:"Connection Profile event handler code failed to compile.",

    IMPORT_FROM_DASS: "Import from OrbiWAN",
    NO_ORBIWAN_INSTANCE: "You don't have any OrbiWAN instance to import devices from. Please add one in user settings.",
    NO_ORBIWAN_INSTANCE_FOR_LOGIN: "You're logged in as a user login. Your user doesn't have any OrbiWAN instance to import devices from. Please contact your administrator.",

    // Device Import
    DEVICE_IMPORT: "Device Import",
    SET_DEVICE_PROFILE: "Set Device Profile",
    NO_DEVICE_TO_REGISTER: "No device to register. Please set a device profile by selecting devices and using bulk actions.",
    ALL_DEVICES_REGISTERED_ALREADY: "All devices are already registered",
    DEVICES_REGISTERED: "Devices Registered Successfully",
    DEVICE_REGISTRATION_FAILED: "Device Registration Failed",
    UPLOAD_FILE: "Upload File",
    ERROR_PROCESSING_FILE: "Error processing uploaded file",
    LNS_SERVICE_PROFILE: "LNS Service Profile",
    LNS_DEVICE_PROFILE: "LNS Device Profile",
    PROVISION_TOKEN: "Provision Token",
    IN_DMP: "Registered",
    BULK_ACTIONS: "Bulk Actions",
    RESTART: "Restart",
    LORA_DEVICE_CLASS: "Class",
    MISSING_COLUMNS_CSV: "The uploaded csv must have the following columns",
    DEVICES_WITHOUT_CONNECTION: "Device profile assigned to devices requires a mandatory connection",
    DEVICES_WITH_UNNECESSARY_CONNECTION: "Device profile assigned to devices doesn't allow a connection",
    DEVICES_WITHOUT_NAME: "Device(s) being registered have no name to distiguish them after registration. Please set a naming pattern by - Selecting devices > Bulk actions > Set name pattern",

    SELECT_DEVICE_PROFILE: "Select Device Profile",
    SELECT_DEVICE_PROFILE_NOTE: "Select a device profile to register devices. The device profile will be assigned to the all selected devices.",
    SELECT_DEVICE_PROFILE_NOTE_LNS: "Select a device profile to register devices. The device profile will be assigned to the device with valid LNS device profile and service profile.",

    SELECT_CONNECTION: "Select Connection",
    SELECT_CONNECTION_NOTE: "Select a connection to register devices. The connection will be assigned to the all selected devices.",
    SET_CONNECTION: "Set Connection",
    CLOSE: "Close",
    SORRY: "Sorry",
    NO_DEVICE_SELECTED: "No device selected",
    ERROR_LOADING_REDOC_API: "[[fa-triangle-exclamation fa-2x]] Error loading the API",
    DELETE_DEVICE_MSG: "Devices created in system and linked with OrbiWAN will be deleted at OrbiWAN as well. Devices imported via CSV and from OrbiWAN will be deleted from the system only and not from OrbiWAN.",
    MISSING_MANDATORY_COLUMNS: "Missing mandatory columns.",
    LEGACY_DEVICE_FOUND: "One ore more device seems to be a legacy device. To import such devices, please use the Import from OrbiWAN option.",
    REGISTRATION_STATUS: "Registration Status",
    DEVICE_NAME: "Device Name",
    JOB_NAME: "Job Name",
    UPLOAD_CSV_MSG: "Choose CSV/JSON/JSONL files to upload",
    UPLOAD_CSV_SUB_MSG: "Or, Drag and drop files here",
    SET_NAME_PATTERN: "Set Name Pattern",
    SELECT_NAME_PATTERN: "Select Name Pattern",
    SELECT_NAME_PATTERN_NOTE: "Select a name pattern to register devices. The name pattern will be assigned to the all devices with no name.",
    SELECT_NAME_PATTERN_NOTE_1: "If no pattern is selected, then the counter will be used by default.",
    SELECT_NAME_PATTERN_NOTE_2: "If there are already names assigned, you can choose to override them.",
    SELECT_NAME_PATTERN_NOTE_3: "Once you select Use Date or Use Time, you can choose to use Local Date Time Format. By default, UTC format is used.",
    SELECT_NAME_PATTERN_NOTE_4: "A sample name is shown below for the selected pattern.",
    SELECT_NAME_PATTERN_NOTE_5: "To uniquely identify devices, one of the Counter or Last 6 Chars must be selected. If Last 6 Chars is not selected, counter will be added by default.",
    NAME_SUFFIX: "Suffix",
    NAME_SUFFIX_HELP: "Suffix to be added to the device name",
    NAME_PREFIX: "Prefix",
    NAME_PREFIX_HELP: "Prefix to be added to the device name",
    DELETE_DEVICES: "Delete Devices",
    DELETE_DEVICES_NOTE: "Do you want to delete the selected device(s)?",
    USE_COUNTER: "Use Counter",
    USE_DATE: "Use Date",
    USE_TIME: "Use Time",
    USE_LAST_6_CHARS: "Use Last 6 Chars",
    USE_LOCAL_DATE: "Use Local Date Time Format",
    OVERRIDE_EXISTING_NAME: "Override Existing Name?",


    // Rules"De

    NAV_MANAGE_RULES: 'Rules',
    ADD_RULE: "Add Rule",
    EDIT_RULE: "Rule Info",
    DELETE_RULES: "Delete Rules",
    BULK_DELETE_RULE_SUCCESS_MESSAGE: "Bulk Rule deleted successfully",
    DELETE_RULE_SUCCESS_MESSAGE: "Rule successfully deleted",
    BULK_DELETE_CONFIRM_DIALOAG: "Do you want to delete the selected rule(s)",
    DELETE_CONFIRM_DIALOG: "Do you want to delete this rule",
    RULE_NAME: "Rule Name",
    CONDITION_TYPE: 'Condition Type',
    ALARM_DEF_NAME: "Alarm Definition",
    IS_ACTIVE: "Is Active",
    SEVERITY: "Severity",
    DISCONNECTED_TOOLTIP: "Disconnected: Device not connected to server, but may still be active.",

    // Notification Policy

    NAV_MANAGE_NOTIFICATION_POLICY: 'Notification Policy',
    ADD_NOTIFICATION_POLICY: "Add Notification Policy",
    EDIT_NOTIFICATION_POLICY: "Notification Policy Info",
    DELETE_NOTIFICATION_POLICIES: "Delete Notification Policies",
    BULK_DELETE_NOTIFICATION_POLICY_SUCCESS_MESSAGE: "Bulk Notification Policy deleted successfully",
    DELETE_NOTIFICATION_POLICY_SUCCESS_MESSAGE: "Notification Policy successfully deleted",
    BULK_DELETE_CONFIRM_DIALOAG_NOTIFICATION_POLICY: "Do you want to delete the selected notification policy(s)",
    DELETE_CONFIRM_DIALOG_NOTIFICATION_POLICY: "Do you want to delete this notification policy",
    NOTIFICATION_POLICY_UUID: "Notification Policy UUID",
    NOTIFICATION_POLICY: "Notification Policy",

    // Alarm Definitions
    NAV_MANAGE_ALARM_DEFINITIONS: 'Alarm Definitions',
    ADD_ALARM_DEFINITION: "Add Alarm Definition",
    EDIT_ALARM_DEFINITION: "Alarm Definition Info",
    DELETE_ALARM_DEFINITIONS: "Delete Alarm Definitions",
    BULK_DELETE_ALARM_DEFINITION_SUCCESS_MESSAGE: "Bulk Alarm Definition deleted successfully",
    DELETE_ALARM_DEFINITION_SUCCESS_MESSAGE: "Alarm Definition successfully deleted",
    BULK_DELETE_CONFIRM_DIALOAG_ALARM_DEFINITION: "Do you want to delete the selected alarm definition(s)",
    DELETE_CONFIRM_DIALOG_ALARM_DEFINITION: "Do you want to delete this alarm definition",

    // alarms
    NAV_MANAGE_ALARMS: 'Alarms',
    DELETE_ALARM: "Delete Alarm",
    CLOSE_ALARM: "Close Alarm",
    DELETE_ALARMS: "Delete Alarms",
    CLOSE_ALARMS: "Close Alarms",
    BULK_DELETE_CONFIRM_DIALOAG_ALARMS: "Do you want to delete the selected alarm(s)",
    BULK_CLOSE_CONFIRM_DIALOG_ALARMS: "Do you want to close the selected alarm(s)",
    DELETE_CONFIRM_DIALOG_ALARM: "Do you want to delete this alarm",
    CLOSE_CONFIRM_DIALOG_ALARM: "Do you want to close this alarm",
    BULK_DELETE_ALARM_SUCCESS_MESSAGE: "Bulk Alarm deleted successfully",
    BULK_CLOSE_ALARM_SUCCESS_MESSAGE: "Bulk Alarm closed successfully",
    DELETE_ALARM_SUCCESS_MESSAGE: "Alarm successfully deleted",
    CLOSE_ALARM_SUCCESS_MESSAGE: "Alarm successfully closed",
    ENTITY_TYPE: "Type",
    ENTITY_ID: "EntityID",
    ENTITY_NAME: "Entity Name",
    DETAILS: "Details",
    CREATED_AT: "Created At",
    RESOLVE_TIME: "Resolve Time",
    ALARM_SEVERITY_CRITICAL: "Critical",
    ALARM_SEVERITY_WARNING: "Warning",
    ALARM_SEVERITY_INFO: "Info",
    ALARM_STATE_PENDING: "Pending",
    ALARM_STATE_ACTIVE: "Active",
    ALARM_STATE_RESOLVED: "Resolved",

    // Device Jobs Constants

    NAV_MANAGE_JOBS: 'Jobs',
    DELETE_JOBS: "Delete Jobs",
    DELETE_JOB: "Delete Job",
    BULK_DELETE_CONFIRM_DIALOAG_JOBS: "Do you want to delete the selected job(s)",
    DELETE_CONFIRM_DIALOG_JOB: "Do you want to delete ",
    BULK_DELETE_JOB_SUCCESS_MESSAGE: "Bulk Job deleted successfully",
    DELETE_JOB_SUCCESS_MESSAGE: "Job successfully deleted",
    SERVICE_NAME: "Service Name",
    DEVICE_JOB_STATUS: "Job Status",
    DEVICE_JOB_SCHEDULE_STARTTIME: "Start Time",
    DEVICE_JOB_SCHEDULE_ENDTIME: "End Time",
    DEVICE_JOB_SCHEDULE_PERIODICITY: "Periodicity",
    DEVICE_JOB_STATUS_OPTION_SCHEDULED: "Scheduled",
    DEVICE_JOB_STATUS_OPTION_COMPLETED: "Completed",
    DEVICE_JOB_STATUS_OPTION_FAILED: "Failed",
    DEVICE_JOB_STATUS_OPTION_FAILED_PERIODIC: "FailedPeriodic",
    DEVICE_JOB_STATUS_OPTION_VALIDATION_FAILED: "ValidationFailed",
    DEVICE_JOB_STATUS_OPTION_DEVICE_NOT_FOUND: "DeviceNotFound",
    DEVICE_JOB_STATUS_OPTION_STARTED: "Started",
    DEVICE_JOB_STATUS_OPTION_TIMEOUT: "Timeout",

    // Tabs
    NAV_DEVICE_MANAGEMENT: "Device Management",
    NAV_ALERTS: "Alerts",
    NAV_ADMINISTRATIVE: "Administrative",
    NAV_DEVELOPER: "Developer",


// Device Management
    DELETE_DEVICE_CONFIRMATION: "You are about to delete a device",
    DELETE_DEVICE_SUCCESS: "Device deleted successfully",
    BULK_DELETE_DEVICE_CONFIRMATION: "Do you want to delete the {count} selected device(s)",
    SUSPEND_DEVICE_CONFIRMATION: "You are about to suspend a device",
    SUSPEND_ACTION: "Suspend",
    SUSPEND_DEVICE_SUCCESS: "Device suspended successfully",
    BULK_SUSPEND_DEVICE_SUCCESS: "Devices suspended successfully",
    BULK_SUSPEND_DEVICE_CONFIRMATION: "Do you want to suspend {count} the selected device(s)",
    UNSUSPEND_DEVICE_CONFIRMATION: "You are about to unsuspend a device",
    BULK_UNSUSPEND_DEVICE_CONFIRMATION: "Do you want to unsuspend {count} the selected device(s)",
    UNSUSPEND_ACTION: "Unsuspend",
    UNSUSPEND_DEVICE_SUCCESS: "Device unsuspended successfully",
    BULK_UNSUSPEND_DEVICE_SUCCESS: "Devices unsuspended successfully",
    NOTE: "Note",

    // Group Management
    DELETE_GROUP_CONFIRMATION: "You are about to delete a group",
    DELETE_GROUP_SUCCESS: "Group deleted successfully",
    BULK_GROUP_DELETE_CONFIRMATION: "Do you want to delete the {count} selected group(s)",
    BULK_GROUP_DELETE_SUCCESS: "Groups deleted successfully",

    // Application Management
    DELETE_APPLICATION_CONFIRMATION: "You are about to delete an application",
    DELETE_APPLICATION_SUCCESS: "Application deleted successfully",
    BULK_APPLICATION_DELETE_CONFIRMATION: "Do you want to delete the {count} selected application(s)",
    BULK_APPLICATION_DELETE_SUCCESS: "Applications deleted successfully",
    START_APPLICATION_PUSH_SUCCESS: "Application push started successfully",
    STOP_APPLICATION_PUSH_SUCCESS: "Application push stopped successfully",
    BULK_START_APPLICATION_PUSH_CONFIRMATION: "Do you want to Start Push Mode for Selected Applications ?",
    BULK_START_APPLICATION_PUSH_SUCCESS: "Applications push started successfully",
    BULK_STOP_APPLICATION_PUSH_CONFIRMATION: "Do you want to Stop Push Mode for Selected Applications ?",
    BULK_STOP_APPLICATION_PUSH_SUCCESS: "Applications push stopped successfully",
    BULK_START_APPLICATION_LABEL : "Start Push Mode",
    BULK_STOP_APPLICATION_LABEL : "Stop Push Mode",

    // Tag Management
    DELETE_TAG_CONFIRMATION: "You are about to delete a tag",
    DELETE_TAG_SUCCESS: "Tag deleted successfully",
    BULK_TAG_DELETE_CONFIRMATION: "Do you want to delete the {count} selected tag(s)",
    BULK_TAG_DELETE_SUCCESS: "Tags deleted successfully",

    // Profile Management
    BULK_DELETE_PROFILE_CONFIRMATION: "Do you want to delete the {count} selected {uitype}(s)",
    BULK_DELETE_PROFILE_SUCCESS: "deleted successfully",

    // Account Management
    DELETE_ACCOUNT_CONFIRMATION: "You are about to delete an account",
    DELETE_ACCOUNT_SUCCESS: "Account deleted successfully",
    BULK_ACCOUNT_DELETE_CONFIRMATION: "Do you want to delete the {count} selected account(s)",
    BULK_ACCOUNT_DELETE_SUCCESS: "Successfully deleted {count} account(s)",
    DISABLE_ACCOUNT_CONFIRMATION: "You are about to disable an account",
    DISABLE_ACCOUNT_SUCCESS: "Account disabled successfully",
    ACTIVATE_ACCOUNT_CONFIRMATION: "You are about to activate an account",
    ACTIVATE_ACCOUNT_SUCCESS: "Account activated successfully",
    SUSPEND_ACCOUNT_CONFIRMATION: "You are about to suspend an account",
    SUSPEND_ACCOUNT_SUCCESS: "Account suspended successfully",
    ACTIVATE_ACTION: "Activate",
    DISABLE_ACTION: "Disable",
    DELETE_ACCOUNT_ACTION: "Delete Account",
    SUSPEND_ACCOUNT_ACTION: "Suspend Account",
    IMPERSONATE_ACCOUNT_ACTION: "Impersonate Account",
    DISABLE_ACCOUNT_ACTION: "Disable Account",
    ACTIVATE_ACCOUNT_ACTION: "Reactivate Account",
    DELETE_ACCOUNTS_ACTION: "Delete Accounts",


    // Resource Management

    DELETE_RESOURCE_CONFIRMATION: "You are about to delete a resource",
    DELETE_RESOURCE_SUCCESS: "Resource deleted successfully",
    "BULK_RESOURCE_DELETE_CONFIRMATION": "Do you want to delete the {count} selected resource(s)",
    BULK_RESOURCE_DELETE_SUCCESS: "Resources deleted successfully",
    DOWNLOAD_RESOURCE_CONFIRMATION: "You are about to download an incomplete or corrupted resource file",
    DOWNLOAD_RESOURCE_SUCCESS: "Resource downloaded successfully",
    DOWNLOAD_ACTION: "Download",


    // Named Service Management
    DELETE_NAMED_SERVICE_CONFIRMATION: "You are about to delete a named service",
    DELETE_NAMED_SERVICE_SUCCESS: "Named service deleted successfully",
    BULK_NAMED_SERVICE_DELETE_CONFIRMATION: "Do you want to delete the {count} selected named service(s)",
    BULK_NAMED_SERVICE_DELETE_SUCCESS: "Named services deleted successfully",

    IMPERSONATE_ACCOUNT_MESSAGE: "You are currently impersonating user ",


    // Map

    MAP_DEVICE_POSITION_SAVED: "position saved",
    DEFAULT_ACTIONS: "Default Actions",


    



};

import React,  { useContext, useEffect } from "react";
import { Container, Dropdown, Nav, Navbar, NavDropdown, NavItem } from "react-bootstrap";
import { strings } from "../../services/Localization";
import { useNavigate } from 'react-router';
import { Button } from 'react-bootstrap';

import { faArrowRightFromBracket,faArrowUpRightFromSquare , faChartUser, faCircleInfo, faUserGear, faTriangleExclamation } from "@fortawesome/pro-light-svg-icons";

import { IConstants } from "../../types";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"; 

import { isMobile } from 'react-device-detect';

import userCustomerIcon from "../../../resources/images/user_customer.png";


import AppContext from '../../context/AppContext'
import { SchemaModal } from "../SchemaModal/SchemaModal";
import { IUser } from "src/dassTypes";
import { faGears } from "@fortawesome/pro-regular-svg-icons";
import { getLoginMenuTitle, getLoginMenuUserItem, languageMenu } from "./NavBar";

declare const constants: IConstants; 

//const NavBar: React.FC<{}, INavbarState> = () => {
let key = 0;

function skey() { key += 1; return key; }

const NstNavBar: React.FC<{}> = (props) => {
    
    const AppContextObj = useContext(AppContext);

    const portalEl: HTMLElement = document.createElement("div");

    let checkUser:boolean = AppContextObj.navBarState.user !== AppContextObj.user;

    useEffect(() => {
        if(AppContextObj.navBarState.user !== AppContextObj.user) {
            AppContextObj.setNavBarState(prevState => { return {...prevState, user: AppContextObj.user}})
        }
    },[checkUser])

    
    const activateButton = (id: string) => {
        let retry = 10; // continue to try for 2 seconds
        function tryit() {
            const obj = document.getElementById(id);
            if (obj && obj.click) {
                obj.click();
            } else {
                if (retry-- > 0) {
                    setTimeout(tryit, 200);
                }
            }
        }
        // wait 1 sec the start looking for the  button.
        setTimeout(tryit, 1000);
    }

    useEffect(() => {
        async function fetch() {
            
            document.body.insertBefore(portalEl, document.body.children[0]);

            try {
                const hashObj: any = {};
                if (window.location.hash) {
                    for (const arg of window.location.hash.slice(1).split("&")) {
                        const i = arg.indexOf("=");
                        if (i > 0) {
                            hashObj[arg.slice(0, i)] = decodeURIComponent(arg.slice(i + 1));
                        }
                    }
                }

                if (hashObj.click) {
                    AppContextObj.setNavBarState(prevState =>  { return {...prevState, click: hashObj.click }});
                }

            } catch (error) { }

            if (AppContextObj.navBarState.click) {
                activateButton(AppContextObj.navBarState.click);
            }
        }
        
        fetch();
        return () => {
            document.body.removeChild(portalEl);
            clearTimeout( AppContextObj.SessionCheck());
        }

    },[])

        
    const user = AppContextObj?.user || null; 
    return (
        <div id="navbar-root" className="besides-data-table">
            {
            AppContextObj.isSignedIn(user) && AppContextObj.isForwardedSignIn() && AppContextObj.navBarState.forwardedSignIn && 
            <div style={{backgroundColor : "#F6C457", width: "100%", textAlign:"center", padding: "5px"}}>
                <span>{strings.IMPERSONATE_ACCOUNT_MESSAGE}<b>{`${AppContextObj?.user?.userid}`}</b></span>
                <Button style={{marginLeft : "10px"}} variant="dark" size="sm" onClick={ () => { AppContextObj.retrnToOldUser() }}>
                    Exit
                </Button>
            </div>
        }
            {!isMobile && <DesktopMenu />}
            {isMobile && <MobileMenu  />}
        </div>
    );

}

export { NstNavBar };

const DesktopMenu:React.FC<{}> =  ( ) => {
    
    return (
        <div className="desktop-menu">
            <div>
                <TopNavBar  />
            </div>
            <div>
                <MenuBar />
            </div>
        </div>
    )
}


const MobileMenu:React.FC<{}> =  ( props ) => {
    
    return (
        <div className="mobile-menu d-flex flex-row flex-grow-1 bg-dark position-relative" >
             <MobileMenuBar />
        </div>
    )
}

const TopNavBarMobile:React.FC<{}> = ( props ) => { 

    
    return (
        <Navbar className="top_header_desktop navbar navbar-dark bg-dark" style={{width: '100%'}}>
                <RightMenuNav  />
        </Navbar>
    )
}




const MobileMenuBar:React.FC<{}> = () => {

    
    const AppContextObj = useContext(AppContext);
    
    return (
        <Navbar collapseOnSelect className="main_header_desktop flex-grow-1" expand="lg" bg="dark" variant="dark">
                <div className="d-flex flex-grow-1" style={{width: '100%'}}>
                    
                    <div className="d-flex flex-shrink-1" >
                        {AppContextObj.isSignedIn(AppContextObj.user) && <Navbar.Toggle />}
                    </div>
                    <div className="d-flex flex-grow-1 align-items-center ">
                        <NstLogoTitle />
                    </div>
                    <div className="d-flex flex-shrink-1" >
                        <TopNavBarMobile />
                    </div>
                </div>

                {AppContextObj.isSignedIn(AppContextObj.user) && <MainNavBar />}
        </Navbar>
                    
    )
}



const MainNavBar:React.FC<{}> = ({ }) => {

    return (
        <Navbar.Collapse id="responsive-navbar-nav">

            <Nav className="main_header_desktop">

            <SchemaModal 
                SchemaUrl="/rest/users?get_schema=navbar"
                type="navbar"
            />
            </Nav>

        </Navbar.Collapse>
    );
/*
    return (
            <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className=" main_header_desktop ">
                    <BuildNavArray navArray={NavNstArray} />
                </Nav>
            </Navbar.Collapse>
    )
*/
}

const NstLogoTitle:React.FC<{}> = ({ }) => {
    const appContext = useContext(AppContext);
    let user = appContext.user;
    let parentClasses = (isMobile) ? "d-flex flex-column justify-content-center align-items-center" : 'd-flex flex-row';
    const isNst = (String(location.pathname).indexOf('/nst'));
    const redirection = isNst != -1 ? "/nst/network_map" : "/app";
    return (
        <div className={parentClasses}>
            {constants.leftSideLogo ? <a href={redirection}>
                        <img src={constants.left_logo_file} className="img-left-logo" />
                        </a>
                    : <div className="fake_left_logo"></div>}

            <div className={`top_bar_name_instance_holder d-flex`}>
                <div className="padding_left_min app_name_holder">
                    NST
                </div>
                <div className="padding_left_min separator_holder">
                    |
                </div>

                {constants.instance_title &&
                    <div className="padding_left_min instance_holder">
                        {constants.instance_title.substring(0, 29)}
                    </div>
                }
                {appContext.debugMessage && 
                    <div className="ms-1 instance_holder">
                        {appContext.debugMessage}
                    </div>
                }

                {user && user.account_status === "suspended" &&
                    <div className="ms-2 px-2  bg-warning rounded-pill instance_holder">
                        <FontAwesomeIcon icon={faTriangleExclamation} /> this account is currently suspended
                    </div>
                }
                {user && user.sys_admin && user._license_error &&
                    <div className="ms-2 px-2  bg-danger rounded-pill  instance_holder">
                        <FontAwesomeIcon icon={faTriangleExclamation} /> {user._license_error}
                    </div>
                }

            </div>
        </div>
    )
}

const MenuBar:React.FC<{}> = () => {

    
    const appContextObj = useContext(AppContext);
    
    return (
        <Navbar collapseOnSelect className="main_header_desktop px-2" expand="md" bg="dark" variant="dark">
            <Container fluid>
            {appContextObj.isSignedIn(appContextObj.user) && <Navbar.Toggle className="pull-left" />}
            {/*<FontAwesomeIcon icon={faHamburger} />*/}
            {appContextObj.isSignedIn(appContextObj.user) && <MainNavBar />}
            </Container>
        </Navbar>
                    
    )
}

const TopNavBar:React.FC<{}> = ( ) => { 

    return (
        <Navbar className="top_header_desktop px-2 navbar navbar-dark bg-dark" style={{width: '100%'}}>
            <Container fluid>
            <NstLogoTitle   />
                <RightMenuNav  />
             </Container>
        </Navbar>
    )
}




const RightMenuNav:React.FC<{}> = ({ }) => {

    const AppContextObj = useContext(AppContext);

    const navigate = useNavigate();

    const user = AppContextObj.user || ({} as IUser);
    const userenv = (user || {})._environment || {};
    
    // const rightMenuArr = [
    //     {
    //         type: 'dropdown',
    //         title: AppContextObj.navBarState.language === "en" ? strings.BUTTON_LANG_EN : strings.BUTTON_LANG_ZH,
    //         image: `/images/${AppContextObj.navBarState.language}_flag.png`,
    //         dropdownOptions : [
    //             {
    //                 title: strings.BUTTON_LANG_EN,
    //                 action: () => { AppContextObj.changeLanguage() },
    //                 image: `/images/${AppContextObj.navBarState.language}_flag.png`
    //             },
    //             {
    //                 title: strings.BUTTON_LANG_ZH,
    //                 action: () => { AppContextObj.changeLanguage() },
    //                 image: `/images/${AppContextObj.navBarState.language}_flag.png`
    //             }
    //         ]
    //     }
    // ]

    // const canRedirectToApp = () => {
    //     if (user.ui_settings && user.ui_settings.landing_page === "nst") {
    //         return true;
    //     } 
    //     return true
    // }

    return (
        <Nav className="top_header">
            {languageMenu(AppContextObj)}

            {AppContextObj.isSignedIn(user) && (
                <NavDropdown
                    title={getLoginMenuTitle(AppContextObj)}
                    align="end"
                    id="basic-nav-dropdown"
                    className="shadow_dropdown width_mobile"
                >

                    {getLoginMenuUserItem(AppContextObj)}

                    {AppContextObj.isSignedIn(user) && AppContextObj.navBarState.forwardedSignIn &&
                        <Dropdown.Item className="align-left" eventKey={1.1} >
                            <img src={userCustomerIcon} className="img_user_forward" />
                            <div className="forwarded_user">
                                {strings.NAV_SIGNED_AS} {user.userid} <br></br>
                                {strings.formatString(strings.VIA, {
                                    originalUser: AppContextObj.navBarState.originalUser,
                                })}
                                <br></br>
                            ({user.customer_admin ?
                                strings.ADMIN_TYPE_CUSTOMER : strings.ADMIN_TYPE_USER
                            })
                        </div>
                    </Dropdown.Item>}

                    {AppContextObj.isSignedIn(user) && !AppContextObj.navBarState.forwardedSignIn && <Dropdown.Divider />}

                    {AppContextObj.isSignedIn(user) && !AppContextObj.navBarState.forwardedSignIn && AppContextObj.user.loginid &&
                        <Dropdown.Item
                            className="align-left user-settings"
                            eventKey={1.2}
                            onClick={() => { navigate('nst/user-settings')} }
                        >
                            <FontAwesomeIcon icon={faUserGear} ></FontAwesomeIcon>
                            {strings.NAV_USER_SETTINGS}
                        </Dropdown.Item>
                    }

                    {AppContextObj.isSignedIn(user) && !AppContextObj.navBarState.forwardedSignIn &&
                        <Dropdown.Item
                            className="align-left user-settings"
                            eventKey={1.3}
                            onClick={() => { navigate('nst/account-settings')} }
                        >
                            <FontAwesomeIcon icon={faGears} ></FontAwesomeIcon>
                            {strings.NAV_ACCOUNT_SETTINGS}
                        </Dropdown.Item>
                    }


                    {userenv.eula_id &&
                        <>
                            <Dropdown.Item
                                className=""
                                eventKey={1.5}
                                onClick={ () => { navigate("nst/eula") } }
                            >
                                <FontAwesomeIcon icon={faCircleInfo} />
                                {strings.NAV_SOFTWARE_INFO}
                            </Dropdown.Item> 
                        </>
                    }

                    {
                        <Dropdown.Item 
                            eventKey={skey()}  
                            href="#"
                            onClick={(e) => {
                                e.stopPropagation();
                                navigate(`app`)                                    
                            }} 
                            className="hover_menu dropdown-item"
                        >
                            <FontAwesomeIcon icon={faChartUser} />  
                            {strings.NAV_NST_SWITCH_TO_APP}
                            <span onClick={ (e) => { e.stopPropagation(); window.open("/app") } }  className="mt-1 btn-link   float-end">
                                <FontAwesomeIcon size="xl"  icon={faArrowUpRightFromSquare} />
                            </span>
                        </Dropdown.Item>
                    }

                    {AppContextObj.isSignedIn(user) &&
                        <Dropdown.Item
                            className="align-left user-settings"
                            eventKey={1.4}
                            onClick={ () => { AppContextObj.signOut() }}
                        >
                            <FontAwesomeIcon icon={faArrowRightFromBracket} ></FontAwesomeIcon>
                            {strings.SIGN_OUT}
                        </Dropdown.Item>
                    }

                </NavDropdown>
            )}
                    
            {!AppContextObj.isSignedIn(user) &&
                <a className="align-left sign-in" href="/app/signin" >
                    {strings.SIGN_IN}
                </a>
            }

            {constants.rightSideLogo &&
                <NavItem className="right_logo">
                    <div className="img-right-logo">
                        <img src={constants.right_logo_file} className="img-right-logo-img" />
                    </div>
                </NavItem>
            }
        </Nav>
    )
}





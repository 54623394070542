import React, { useEffect, useState, useContext, useRef } from "react";
import { strings, translate } from "./../../services/Localization";
import { checkLogin } from "./../../services/Login";
declare const constants;
import { useNavigate, useParams } from 'react-router-dom';
import { faPaperPlane, faTrashAlt , faAdd,  faRefresh } from '@fortawesome/pro-regular-svg-icons'
import { faClock }  from '@fortawesome/pro-solid-svg-icons';
import { IUser } from "src/dassTypes";
import {  dialog, dialogDescription } from '../../components/Common';
import { TAB_ADD_GROUP, TAB_APP_ASSIGNMENT } from '../../datatypes/tabsconstants'
import AppContext from '../../context/AppContext'
import { BreadCrumbType, PageButtonType, ServiceProgress } from '../../datatypes/datatypes';
import { ActionType, BulkActionType, ColumnType, DataTableOption  } from '../../components/Common/DataTable/DataTypes';
import { getAppBase, getLabelBasedOnServerType } from '../../utils/consts';
import { GenericDassQuery } from "../../services/BasicDassQueries";
import { toast } from "./../../utils/Toaster";
import { getVisibleActions, actionIcon, elipsis } from "./../../utils/filters";
import{ ID_INPUT_VALIDATION, DEFAULT_RECORD_LIMIT }  from "../../components/Common/DataTable/DataTableConsts";
import PageContent from "../PageContent";
import RenderTagsOverlay from "../../components/Common/RenderTagsOverlay";
import { isMobile } from "react-device-detect";
import { trustedFormatText } from "../../schemaengine/client/SchemaTextParser";
import { execService, execScheduleService, visibleScheduleService } from "../Devices/DmpDevices";



interface IGroupsStates {
    loggedUser: IUser | null;
    showAlertModal: boolean;
    editUserId: string;
    pageTitle: string;
    breadCrumbArr: BreadCrumbType[];
    serviceProgress?: ServiceProgress
    refresh:boolean;
    isRowDeleted?:boolean;
}

interface IRowType {

   applications: string;
   appskey: string;
   comment: string;
   devaddr: string;
   fcnt: string;
   groupid: string;
   nwkskey: string;
   description: string;
   services: Array<{
    name: string;
    buttonText: string;
    show_on_ui?: boolean;
    confirm?: boolean;
    has_parameters?: boolean;
    title: string;
}>;

}


const Groups: React.FC<{}> = () => {

    const AppContextObj = useContext(AppContext);
    const navigate = useNavigate();
    let { id, tabname } = useParams();
    const isDmp = constants?.server_type === "dmp";
    const isOrbiwan = !isDmp;

    const canDelete = () => ((isOrbiwan && (AppContextObj?.user?.can_register || AppContextObj?.user?.can_control_devices)) ||
                             (isDmp     &&  AppContextObj?.user?.can_delete_groups)) && !AppContextObj?.user?._readonly;
    const canUpdate = () => ((isOrbiwan && (AppContextObj?.user?.can_register || AppContextObj?.user?.can_control_devices)) ||
                             (isDmp     &&  AppContextObj?.user?.can_update_groups)) && !AppContextObj?.user?._readonly;
    const canCreate = () => ((isOrbiwan && (AppContextObj?.user?.can_register || AppContextObj?.user?.can_control_devices)) ||
                             (isDmp     &&  AppContextObj?.user?.can_create_groups)) && !AppContextObj?.user?._readonly;




    const stateInit = {
        loggedUser: AppContextObj.user,
        pageTitle: strings.MY_DEVICES_GROUPS,
        showAlertModal: false,
        editUserId: '',
        breadCrumbArr: [getLabelBasedOnServerType(strings.NAV_DEVICE_MANAGEMENT, '', ''), {label: strings.NAV_MANAGE_GROUPS, url:''}],
        refresh:false,
        isRowDeleted:false
    };

    const [groupState, setGroupState] = useState<IGroupsStates>(stateInit)

    const serviceProgressRef = useRef<ServiceProgress | null>(null);
    const [serviceProgress, setServiceProgress] = useState<ServiceProgress | null>(null);

    useEffect(() => {
        if(checkLogin(AppContextObj.user)) {
            setGroupState(prevState => { return {...prevState, loggedUser: AppContextObj.user }})
        }

    },[])

     const detailPageNav = ( navigate, tab, groupid, row) => {
        
        navigate(`${getAppBase()}/groups/${groupid}/${tab}`, {state: {row: row, prevPageUrl: `${getAppBase()}/groups`}})
    }

    const detailPageAddNav = ( navigate, tab) => {
        
        navigate(`${getAppBase()}/groups/${tab}`, {state: {prevPageUrl: `${getAppBase()}/groups`}})
    }
    


    const groupBulkAction = ( navigate, tab, selectedIds ) => {
        
        const idArr = selectedIds.map((row) => {
            return row['groupid'];
        })

        navigate(`${getAppBase()}/groups/bulkaction/${tab}`, {state: {tab: tab, selectedIds:idArr, prevPageUrl: `${getAppBase()}/groups`}})

    }

   const getActions = () => {

        let actions: ActionType[] = [
            {
                type: "action",
                text: strings.SERVICE_SCHEDULER,
                render: (row, title) => actionIcon(row.groupid, title, faClock.iconName),
                visible: (row) => row.services?.length >= 1 && canCreate(),
                action: (row) => execScheduleService([row], -1)
            },
        {
            type: "action",
            text: strings.DELETE_GROUP,
            visible: () => canDelete(),
            render: (row, title) => actionIcon(row.groupid, title, faTrashAlt.iconName),
            action: (group) => deleteGroup(group)
        }
       ]

        let bulkActions:BulkActionType[] = [
            {
                type: "header",
                text: strings.DEFAULT_ACTIONS
            },
            {
                type: "action",
                text: strings.SERVICE_SCHEDULER,
                render: (row, title) => actionIcon(row.groupid, title, faClock.iconName),
                action: (rows) => execScheduleService(rows, -1),
                bulkActionVisible: (selectedIds) => {
                    return canCreate() && visibleScheduleService(selectedIds)
                }
            },
          
            {
                type: "action",
                text: strings.APP_ASSIGNMENT,
                render: (row, title) => actionIcon(row.groupid, title, faPaperPlane.iconName),
                visible: () => canUpdate(),
                action: async ( selectedIds ) => groupBulkAction(navigate, TAB_APP_ASSIGNMENT, selectedIds)
            },
            {
                type: "action",
                text: strings.DELETE_GROUP,
                render: (row, title) => actionIcon(row.groupid, title, faTrashAlt.iconName),
                visible: () => canDelete(),
                action: (selectedIds) => deleteGroupInBulk(selectedIds)
            }
        ]

        return {
            actions: actions,
            bulkActions:bulkActions
        }
        
    }


    const deleteGroupInBulk =  async (selectedRows: IRowType[]) => {
        const groupIds = selectedRows.map((row) => {
            return row['groupid'];
        })
        if(groupIds && groupIds.length > 0) {
            const confirmDialogeSettings = {
                title: translate(strings.BULK_GROUP_DELETE_CONFIRMATION, { count: groupIds.length }) as string,
                description: await dialogDescription(groupIds) + "",
                actionLabel: strings.ACTION_DELETE,
            };

            if (await dialog(confirmDialogeSettings) === true) {
                try {
                    Promise.all(groupIds.map((groupid) => {
                        return GenericDassQuery("/rest/groups/" + groupid, { method: "DELETE" });
                    })).then((values) => {
                        refreshTable(true);
                        toast.success(strings.DELETE_GROUP_SUCCESS);
                    });
                } catch (e) {
                    toast.error(e.message);
                }
            }
        }
    }


   const deleteGroup =  async (group) => {
        const confirmDialogeSettings = {
            title:  strings.DELETE_GROUP_CONFIRMATION,
            description: group.groupid,
            actionLabel: strings.ACTION_DELETE,
        };

        if (await dialog(confirmDialogeSettings) === true) {
            try {

                Promise.resolve(GenericDassQuery("/rest/groups/" + group.groupid, { method: "DELETE" })).then((values) => {

                    refreshTable(true);
                    toast.success(strings.DELETE_GROUP_SUCCESS);

                });
               
            } catch (e) {
                toast.error(e.message);
            }
        }
    }
   

    const serviceVisible = (row: IRowType, entry: number, name?: string) => {
        if(isDmp && row && typeof(row.services) == 'object' && row.services && row.services.length > 0 &&  AppContextObj?.user?.account_status === "active" && AppContextObj?.user?.can_register && !AppContextObj?.user?._readonly) {
            const services = (row?.services || []).filter(s => (s.show_on_ui));
            let service;
            if(name) {
                service = findServiceWithName(row, name)
            } else {
                service = services[entry];
            }
            return !!service;
        } else {
            return false;
        }
        
    }

    const findServiceWithName = (row: IRowType, name: string) => {
        for(let service of row.services) {
            if(service.name === name) {
                return service;
            }
        }
        return null;
    }

    const serviceIcon = (row: IRowType, title: string, entry: number, name?: string) => {
        if(row && typeof(row.services) == 'object' && row.services && row.services.length > 0) {
            const services = (row?.services || []).filter(s => (s.show_on_ui && AppContextObj?.user?.account_status === "active" && !AppContextObj?.user?._readonly));
            let service;
            if(name) {
                service = findServiceWithName(row, name)
            } else {
                service = services[entry];
            }
            if(service) {
                let formatButtonText = service.buttonText || service.name
                if(title) {
                    if(typeof(trustedFormatText(`${service.buttonText || service.name}`, row.groupid, { log: console.log }, null)[0]) == 'object') {
                        formatButtonText = `${service.buttonText || service.name} ${title}`
                    } else {
                        formatButtonText = `[[fa-circle-small]] ${title}`
                    }
                } else {
                    if(typeof(trustedFormatText(`${service.buttonText || service.name}`, row.groupid, { log: console.log }, null)[0]) == 'string') {
                        formatButtonText = elipsis(`${service.buttonText || service.name}`, 8)
                    }
                }
                return trustedFormatText(formatButtonText, row.groupid, { log: console.log }, null);
            }  
        } 
        return "";
        
        
    };

    const addServiceActions = (row: IRowType) => {
        let serviceActions = [];
        // Find the service with same name and keep only unique services with name
        let uniqueServices = row.services && row.services.filter((service, index, self) => index === self.findIndex((t) => (t.name === service.name && t.buttonText === service.buttonText)));
        for(let index in uniqueServices) {

            let serviceAction = {
                type: "action",
                text: row.services[index].title || row.services[index].name,
                render: (row, title) => serviceIcon(row, title, parseInt(index)),
                action: (row) => execService([row], parseInt(index), true, true, setServiceProgress, serviceProgressRef),
                visible: (row) => serviceVisible(row, parseInt(index))
            }
            serviceActions.push(serviceAction);
        }
        return [...serviceActions, ...getActions().actions];
    }


    const addBulkServicesActions = (row: IRowType, bulkActions: BulkActionType<IRowType>[]) => {
        let serviceActions = [];
        if(row && bulkActions) {
            for (let index in row.services) {
                let serviceName = row.services[index].name;
                if(bulkActions == null) bulkActions = [];
                // Check if the serviceName is not present in bulkActions
                if (!bulkActions?.find(action => (action.text === serviceName))) {
                    let serviceAction = {
                        type: "action",
                        text: serviceName,
                        render: (row, title, index, name) => serviceIcon(row, title, parseInt(index), name),
                        action: (rows) => execService(rows, parseInt(index), true, true, setServiceProgress, serviceProgressRef, serviceName),
                        visible: (row, index, name) => serviceVisible(row, parseInt(index), name),
                        hasServiceParameters: Boolean(row.services[index].has_parameters)
                    };
                    serviceActions.push(serviceAction);
                }
            }
            return [...serviceActions, ...bulkActions];
        }
        return [];
        
    };





   const initDataTable = () => {
        
        const {actions, bulkActions} = getActions();

        const appBase = getAppBase();
        const tagsFetcher = async () => {
            try {

                let pageUrl = `/rest/tags`;
                let response  = await GenericDassQuery(pageUrl, { method: "GET" });
                const tags =  response.data;
                let tagOptions = tags.map((tag) => {
                    return {label: tag.tagid, value: tag.tagid}
                })
                return tagOptions;

            } catch(e) {
                console.log(e);
            }
        }
        
        const optionFetcher = async () => {
            
            try {

                let pageUrl = `/rest/applications?all=true`;
                let response  = await GenericDassQuery(pageUrl, { method: "GET" });
                const data =  response.data;
                const arr = data.map(function(row) {
                    return {label:row.accountid, value:row.accountid}
                })
                return arr;

            }catch(e) {
                console.log(e);
            }

        }
        
        const columns: ColumnType[] = [
            {
                key: 'bulk_action_checkbox',
                type: "bulk_action_checkbox",
                title: 'Bulk Action',
                filterable: false,
                cellWidth: 3,
                newCellWidth: "30px",
                customClass: 'sticky left-first',
            },

            {
                key: "groupid",
                type: "text",
                title: strings.GROUP_ID,
                filterable: true,
                filterField: 'search_id',
                filterType: 'text',
                sortKey: "sort_by_groupid",
                sortable: true,
                inputValidation: ID_INPUT_VALIDATION,
                filterParams: {
                    mapper: (x) => x || undefined
                },

                detailLink: true,
                detailPageNav: (row) =>  detailPageNav(navigate, "group-info", row.groupid, row ),
                extraClass: 'ow-datatable-overflow-ellipsis',
                newCellWidth: isMobile ? '250px' : "350px"
            }, {
                key: "description",
                title: "Description",
                type: "text",
                inputValidation: "",
                filterable: true,
                filterField: 'search_description',
                filterType: 'text',
                filterParams: {
                    mapper: (x) => x || undefined
                },
                extraClass: 'ow-datatable-overflow-ellipsis',
                render_tooltip: (row) => row.description,
                newCellWidth: '350px'
            }, 

            ...(!isDmp ? [] :
                [{
                    key: "tags",
                    title: "Tags", // FIXME Add string.xx
                    type: "text_with_tooltip",
                    cellWidth: 25,
                    cellWidthType: '%',
                    dataAlign: "left",
                    render: x => { return RenderTagsOverlay(x.tags, "gray", `${appBase}/tags/~tag~/edit-tag`, {prevPageUrl:`${getAppBase()}/groups`,row: { tagid:0 },}, "Tags") },
                    customNavigation: (row) => {
                        if(row.tags){ return null } 
                        else { detailPageNav(navigate, "group-info", row.groupid, row );}
                    },
                    filterable: true,
                    filterField: "search_tags",
                    filterType: "multiselect",                
                    newCellWidth: "200px",
                    filterParams: {
                        optionFetcher: tagsFetcher,
                        data: [],
                        mapper: x => x,
                    }
                } as ColumnType]
            ),
        ];

        if (!AppContextObj?.user?.app_uuid) {
            // Add only the applitions column for non-application users.
            columns.push({
                key: "applications",
                title: strings.MY_DEVICES_APPLICATIONS,
                type: "text_with_tooltip",
                // render_tooltip: x => (x.applications).replace(/,/g, ', '),
                render: x => { return RenderTagsOverlay(x.applications, 'gray', `${getAppBase()}/my-apps/~tag~/edit-app`,{prevPageUrl:`${getAppBase()}/groups`,row: { accountid:0 }},"Applications")},
                // render: x => { return renderTags(x.applications, 'blue', null,null)},
                customNavigation: (x) => {
                    if(x.applications){return null} else {
                    detailPageNav(navigate, "group-info", x.groupid, x )
                }},
                filterable: true,
                filterField: "application",
                newCellWidth: "200px",
                filterType: "multiselect",
                filterParams: {
                    optionFetcher: optionFetcher,
                    data: [],
                    mapper: x => x,
                }
            });
        }

        columns.push({
            key: 'action_button',
            type: "action_button",
            title: 'Actions',
            filterable: false,
            cellWidth: 3,
            newCellWidth: "140px",
            customClass: 'sticky right',
        });


        const options:DataTableOption<IRowType> = {
            
            url:'/uiapi/rest/groups',
            query_param:{ all:true, get_pages:true, limit:DEFAULT_RECORD_LIMIT, stream:'progress'},
            serial_number:false,
            id_field:'groupid',
            oboe_path:'pages.*',
            available_key: 'groupid',
            modal:false, ///pass true to render single select radio buttons in palce of checkbox
            columns,
            actions: actions,
            defaultSortField: 'sort_by_groupid',
            defaultSortOrder: 'asc',
            emptyDataMsg: strings.NO_GROUP_AVAILABLE,
            addServiceActions: addServiceActions,
            addBulkServicesActions: addBulkServicesActions,
            resizeWidth:['groupid', 'description'],
            bulkActions: getVisibleActions(bulkActions)

        }

        return options;
    }
    
 

    const refreshTable = (isRowDeleted=false) => {

        setGroupState(prevState => {
            return {...prevState, refresh:!prevState.refresh,isRowDeleted:isRowDeleted}
        })
        
    }
    
   const getPageButtons = () => {
        
        const pageButtons: PageButtonType[] = [
            {
                title: strings.ADD_GROUP,
                action: () => detailPageAddNav(navigate, TAB_ADD_GROUP ),
                type: 'button_with_icon',
                icon: faAdd,
                visible: () => canCreate(),
            },
            {
                title: strings.REFRESH_LIST,
                action: () => { refreshTable() },
                type: 'button',
                icon: faRefresh
            }
        ];

        return pageButtons;
    }


    return (
        <PageContent
            name="my-groups" 
            id={id} 
            tabname={tabname} 
            actions={getActions()} 
            breadCrumbArr={groupState.breadCrumbArr} 
            serviceProgress={serviceProgress}
            pageButtons={getPageButtons()} 
            countLabel={`Groups`} 
            dataTableOption={initDataTable()} 
            isRowDeleted={groupState.isRowDeleted}
            refresh={groupState.refresh}>
        </PageContent>
    );

}


export default Groups;

import React, { createContext, useContext, useState, useRef } from 'react';
import { IConstants } from "src/types";
declare const constants: IConstants;
import { addDataToDB, openDB } from "./LiveLogUtils";
import { toast } from "../../utils/Toaster";

interface LiveLogRecord {
    meta: {
        level: "error" | "info" | "verbose" | "debug";
        connectionUuid?: string;
        deviceUuid?: string;
        event?: string;
        lambda?: string;
        stats?: any;
        timestamp: Date;
    };
    log: (string | object)[];
}

const WebSocketContext = createContext<any>(null);
const RecContext = createContext<LiveLogRecord | null>(null);

export function WebSocketProvider({ children }) {
    const [url] = useState("/livelog?push_subscription=livelog")
    const liveRef = useRef<boolean>(true);
    const [rec, setRec] = useState(null)
    const [socket, setSocket] = useState(null)
    const [retryCount, setRetryCount] = useState(0);

    const startWebSocket = async (popUp?: boolean) => {
        try {
            console.log("Start Web Socket Connection..");
            const protocolPrefix = (location.protocol === 'https:') ? 'wss://' : 'ws://';
            const host = constants.websocket_host_override || location.host;
            const ws = new WebSocket(protocolPrefix + host + url, ["json"]);
            localStorage.setItem("websocket_status", "true");

            ws.onopen = () => {
                console.log("Socket open");
                popUp && toast.success("Livelog Recording Started");
                setRetryCount(0);
            }
            ws.onmessage = async (ev) => {
                try {
                    const isString = typeof ev.data === "string";
                    const isBlob = !isString && (ev.data instanceof Blob);
                    const json: string = isString ? ev.data : isBlob ? await ev.data.text() : "__unknown__";
                    let obj: any = null;

                    try {
                        obj = JSON.parse(json);
                    } catch (e) {
                        console.log("failed to parse JSON", e.message, isString, isBlob, json);
                        return;
                    }

                    obj?.livelog && gotLogMessage(obj.livelog)
                    obj?.livelog && setRec(obj.livelog)
                } catch (e) {
                    console.log(ev, e);
                }
            }
            ws.onclose = (ev) => {
                console.log("close socket", ev, liveRef.current);
                const reconnect = localStorage.getItem('websocket_status');
                if (reconnect === "true") {
                    const retryDelay = Math.min(1000 * Math.pow(2, retryCount), 30000);
                    setTimeout(() => {
                        setRetryCount(retryCount + 1);
                        startWebSocket();
                    }, retryDelay);
                }
            };

            setSocket(ws);

        } catch (e) {
            toast.error(e.message)
            console.log(e.message);
            return;
        }
    };

    const stopWebSocket = (popUp?: boolean) => {
        if (socket) {
            socket.close();
            localStorage.setItem('websocket_status', "false");
            setSocket(null);
            popUp && toast.success("Livelog Recording Stopped");
        }
    };

    const gotLogMessage = async (rec: LiveLogRecord) => {
        try {
            const db = await openDB("Log", "livelog")
            addDataToDB(db,"livelog", rec)
        } catch (error) {
            console.error('Error in gotLogMessage:', error);
        }
    };

    return (
        <WebSocketContext.Provider value={{ socket, startWebSocket, stopWebSocket }}>
            <RecContext.Provider value={rec}>
                {children}
            </RecContext.Provider>
        </WebSocketContext.Provider>
    );
}

export function useWebSocket() {
    return useContext(WebSocketContext);
}

export function useRec() {
    return useContext(RecContext);
}
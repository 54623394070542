import { IJsonSchemaObject } from "../UiJsonSchemaTypes";
import { Value, ValueRef, vegaLikeJsonSchema, VegaLikeSchemaBase } from "./VegaLikeToolsTypes";

export const gaugeJsonSchemaPreamble = 
    "## Gauge Visualization\n\n" + 
    "The gauge visualization or card, shows a dial and can be used to show things like usage, speed, etc. " + 
    "Anything where a speedometer style visualization make sense. The JSON schema for the definition is shown below. Be sure " +
    'to set the $schema field to "internal:gauge" when creating the JSON definition.';


export interface IAmGauge extends VegaLikeSchemaBase {
	$schema: "internal:gauge",
	encoding: {
		arrow: ValueRef & { }

		dial: {
			startValue: Value;
			endValue: Value;
			tickCount: Value;

			bands: Array<{
				startValue: Value;
				endValue: Value;
				color: Value;
				radius: Value;
				radius2: Value;
			}>;
		}
	}

}







export const gaugeJsonSchema: IJsonSchemaObject = {
	$id: "internal:gauge",
	$schema: "http://json-schema.org/draft-07/schema",
	type: "object",
    $defs: { ...vegaLikeJsonSchema.$defs },

    properties: {
        ...vegaLikeJsonSchema.properties,

		encoding: {
            description: 
`Encoding for the Gauge. There are two basic sections, the arrow which shows the current value, and the dial that put the value in
perspective by showing the possible range of the value, and optionally some bands on the dial that indicate what group (e.g. normal, warning, danger, etc.)
the value is in.`,
			type: "object",

            properties: {
                arrow: {
                    description: `The value of the gauge. The value should normally be inside the start/end range defined for the dial.`,
                    $ref: "#/$defs/numberRefExprDef"
                },

                dial: {
                    description: `The Dial is the numbered disk around the gauge. It has and a start and end value`,
                    type: "object",
                    properties: {

                        startValue: {
                            description: "Start value of the dial, i.e. lowest value of the dial.",
                            $ref: "#/$defs/numberRefExprDef"
                        },
                        endValue: {
                            description: "End value of the dial, i.e. highest value of the dial.",
                            $ref: "#/$defs/numberRefExprDef"
                        },
                        tickCount: {
                            description: "Number of ticks on the dial.",
                            $ref: "#/$defs/numberRefExprDef"
                        },


                        bands: {
                            description: 
`Bands are used to partition the dial into separate bands (typically with different colors), for example if a gauge
is should a value that can be in a valid range, a warning range and an danger range, these ranges could be 
shown with colors green, yellow and red.
`,
                            type: "array",
                            items: {
                                type: "object",
                                properties: {
                                    startValue: {
                                        description: "Start value of the band on the dial, i.e. lowest value of the band.",
                                        $ref: "#/$defs/numberRefExprDef"
                                    },
                                    endValue: {
                                        description: "End value of the band on the dial, i.e. highest value of the band.",
                                        $ref: "#/$defs/numberRefExprDef"
                                    },
                                    color: {
                                        description: 
`[[@md]]Color of the band. The color must we specified as a web color string, which can be either the names of the colors
or a RGB number with a hash prefix, like this: "#112233" where 11, 22 and 33 are hex numbers, for red, green and blue colors
respectively. The named colors can be found [here](https://en.wikipedia.org/wiki/Web_colors), there are 140 defined colors.`,
                                        $ref: "#/$defs/stringRefExprDef"
                                    },
                                    radius: {
                                        description: 
`[[@md]]Inner radius of the dial. The value can be specified as pixels or in percent. To use percent, use a string followed by
a percent, e.g.:
~~~JSON
    "radius": 100,      // value in pixel
    "radius": "95%",    // value in percent
~~~
`,
                                        $ref: "#/$defs/numberStringRefExprDef"
                                    },
                                    radius2: {
                                        description: 
`[[@md]]Optional Outer radios of the dial. The value can be specified as pixels or in percent. To use percent, use a string followed by
a percent, e.g.:
~~~JSON
    "radius2": 200,      // value in pixel
    "radius2": "100%",   // value in percent
~~~

This value defaults to 100%
`,
                                        $ref: "#/$defs/numberStringRefExprDef"
                                    },
                                },
                                required: [],
                                additionalProperties: false,
                            }
                        }
                    },
                    required: [],
                    additionalProperties: false,
                }
            },

            required: [],
            additionalProperties: false,
		}
	},

    required: ["encoding"],
    additionalProperties: false,
}


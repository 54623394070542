/**
 * This file contains all the standard core types (e.g. number, string, boolean, date, etc.).
 * The file is automatically loaded by the SchemaController module.
 */

import React, { ReactNode } from "react";

import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import { Button, ButtonGroup, ButtonToolbar, Form, Table, ToggleButton } from "react-bootstrap";

import { IUiSchemaElemArgs } from "./SchemaController";
import LazyLocaleDatePicker from "./LazyLocaleDatePicker";
import { evalExpr } from "./SchemaTools";
import SchemaForm, { ISchemaFormProps } from "./SchemaForm";
import { registerComponentHandler, registeredExtensionFormsComponents } from "./SchemaExtensions";



/**
 * Dropdown is used for types  "select" and "creatable-select" and is based on the React-Select component
 * 
 * @param args 
 * @returns 
 */
function addDropdown(args: IUiSchemaElemArgs) {

	const { value, readOnly, uiElem, error, fullkey, type } = args;

	const multi = uiElem && uiElem.multiSelect ? true : false;
	const clearable = uiElem && uiElem.clearable ? true : false;


	// Use DROPDOWN
	const selectOptions: any[] = [];
	let entryValue: any = multi ? [] : null;
	for (const en of args.enums || []) {
		const entry = { value: en, label: args.enumLabels[en + ""] != null ? args.enumLabels[en + ""] : en + "" };
		selectOptions.push(entry);

		if (multi && Array.isArray(value) && value.includes(en)) { entryValue.push(entry); }
		if (!multi && en === value) { entryValue = entry; }
	}

    const selectStyles = {
        menu: (styles: any) => ({
            ...styles,
            zIndex: 999, // Ensure the menu appears above other elements
        }),
        menuPortal: (base: any) => ({
            ...base,
            zIndex: 9999, // Ensure the menu portal appears above other UI components
        })
    };
    const classNames = {
        menu: () => 'custom-select-menu',
        option: (state) =>
            `custom-select-option 
           ${state.isSelected ? 'custom-select-option--selected' : ''} 
        ${state.isFocused && !state.isSelected ? 'custom-select-option--focused' : ''}`.trim(),
    };

	const formatOptionLabel: ((data: any) => ReactNode) | undefined = uiElem?.enumFormatString ? (data: any) => {
		return args.stringToComponent(uiElem?.enumFormatString, {...args, value: data});
	} : ((data: any) => args.stringToComponent(data.label, {...args, value: data}))


	return args.embedObject(
		type === "select" ?
		<Select
			key={fullkey}
			className={"gs-no-drag" + (error ? " has-error" : "")}
			isDisabled={readOnly}
            menuPortalTarget={document.body}
            menuPosition={'fixed'}
            options={selectOptions}
			isClearable={clearable}
			placeholder={uiElem.placeholder}
			closeMenuOnSelect={!multi || !uiElem?.componentOptions?.selectKeepOpenOnMultiSelect}
			styles={selectStyles}
			isMulti={multi}
			formatOptionLabel={formatOptionLabel}
			backspaceRemovesValue={true}
            classNames={classNames}
			onChange={(event) => args.update({ value: multi ? event && event.map((a: any) => a.value) : event && event.value })}
			value={entryValue}
		/> :
		<CreatableSelect
            key={fullkey}
			className={"gs-no-drag" + (error ? " has-error" : "")}
			isDisabled={readOnly}
            menuPortalTarget={document.body}
            menuPosition={'fixed'}
			options={selectOptions}
			isClearable={clearable}
			closeMenuOnSelect={!multi}
			placeholder={uiElem.placeholder}
			isMulti={multi}
			styles={selectStyles}
			formatOptionLabel={formatOptionLabel}
			backspaceRemovesValue={true}
            classNames={classNames}
			onChange={(event) => args.update({ value: multi ? event && event.map((a: any) => a.value) : event && event.value })}
			value={entryValue}
		/>
	);
}

registerComponentHandler("select", addDropdown);
registerComponentHandler("creatable-select", addDropdown);







/**
 * 
 * @param args 
 * @returns 
 */

function addCheckbox(args: IUiSchemaElemArgs) {

	const { value, readOnly, uiElem, fullkey} = args;
	const multi = uiElem?.multiSelect;

	// Render ENUM as a RADIO button set
	const vals: JSX.Element[] = [];
	if (args.enums) {
		for (const en of args.enums) {

			let label = args.enumLabels?.[en + ""] != null ? args.enumLabels[en + ""] : (en + "");
			if (uiElem?.enumFormatString) {
				label = args.stringToComponent(uiElem.enumFormatString, {...args, value: { value: en, label }});
			} else {
				label = args.stringToComponent(label);
			}

			const type = multi ? "checkbox" : "radio";
			vals.push(
				<Form.Check type={type} key={fullkey + en}>
					<Form.Check.Input
						type={type}
						checked={multi ? value?.includes(en) || false : value === en}
						disabled={readOnly}
						onChange={() => {
							const res = multi ? [] : en;
							if (multi) {
								for (const e of args.enums) {
									if ((e === en && !value.includes(e)) ||
										(e !== en && value.includes(e))) { res.push(e); }
								}
							}
							!readOnly && args.update({ value: res });
						}}
					/>
					<Form.Check.Label>{label}</Form.Check.Label>
				</Form.Check>
			);
		}
	} else {
		vals.push(
			<Form.Check type="checkbox" key={fullkey} >
				<Form.Check.Input
					type="checkbox"
					checked={!!value}
					disabled={readOnly}
					onChange={() => { !readOnly && args.update({ value: !value }); }}
				/>
			</Form.Check>
		);
	}

	return args.embedObject(
		<div id={fullkey} key={fullkey} onClick={() => console.log("clicking")}>
				{vals}
		</div>, { useFlex: true }
	);
};

registerComponentHandler("checkbox", addCheckbox)




/**
 * 
 * @param args 
 * @returns 
 */
function addBoolean(args: IUiSchemaElemArgs) {

    const { value, readOnly, key } = args;

    return args.embedObject(
        <Form.Switch
            key={args.fullkey}
            id={"switch_" + key}
            checked={value || false}
            disabled={readOnly}
            onChange={(e) =>  {!readOnly && args.update({ value: !value }); }}
        />, { useFlex: true }
    );
};

registerComponentHandler("boolean", addBoolean)







/**
 * 
 * @param args 
 * @returns 
 */
function addRadioButton(args: IUiSchemaElemArgs) {

    const { error, value, readOnly, key, uiElem } = args;
    const multi = uiElem?.multiSelect;

    // Render ENUM as a RADIO button set
    const vals: JSX.Element[] = [];
    for (const en of args.enums) {

        let label = args.enumLabels?.[en + ""] != null ? args.enumLabels[en + ""] : (en + "");
        if (uiElem?.enumFormatString) {
            label = args.stringToComponent(uiElem.enumFormatString, {...args, value: { value: en, label }});
        } else {
            label = args.stringToComponent(label);
        }

        vals.push(
            <ToggleButton key={en} id={"ID" + en}
                type="radio"
                disabled={readOnly}
                value={en}
                checked={multi ? value?.includes(en) || false : value === en}
                variant={error ? "outline-danger" : "outline-secondary"}
                onClick={() => {
                    const res = multi ? [] : en;
                    if (multi) {
                        for (const e of args.enums) {
                            if ((e === en && !value.includes(e)) ||
                                (e !== en && value.includes(e))) { res.push(e); }
                        }
                    }
                    !readOnly && args.update({ value: res });
                }}
            >
                {label}
            </ToggleButton>
        );
    }

    return args.embedObject(
        <ButtonToolbar key={args.fullkey}>
            <ButtonGroup id={key} >
                {vals}
            </ButtonGroup>
        </ButtonToolbar>, { useFlex: true }
    );
}

registerComponentHandler("radio", addRadioButton)








/**
 * The separator can be used to insert a visual separation on a page.
 * @param args 
 * @returns 
 */

function addSeparator(args: IUiSchemaElemArgs) {

    const { title, fullkey } = args;
    return <div key={fullkey}>
        {title && <h2 className="schema-engine-separator-title">{args.stringToComponent(title)}</h2>}
        <hr className="schema-engine-separator-hr" />
    </div>;
}

registerComponentHandler("separator", addSeparator)





/**
 * 
 */
function addShowHtml(args: IUiSchemaElemArgs) {

    const { value, uiElem, fullkey } = args;
    if (uiElem && uiElem.colBehaviour === "fullwidth") {
        return <div className="mb-2" key={fullkey} dangerouslySetInnerHTML={{__html: value}} />
    } else {
        return args.embedObject(<div key={fullkey} dangerouslySetInnerHTML={{__html: value}} />, { isContainer: true });
    }
}

registerComponentHandler("html", addShowHtml)





/**
 * 
 * @param args 
 * @returns 
 */
function addShowText(args: IUiSchemaElemArgs) {

    const { value } = args;
    return args.embedObject(
        <div key={args.fullkey} className="schema-engine-textview" >{args.stringToComponent(value)}</div>, { isContainer: true }
    );
}

registerComponentHandler("text", addShowText)




/**
 * 
 * @param args 
 * @returns 
 */
// TODO: move to separate file
export function addDate(args: IUiSchemaElemArgs) {

    const { value, readOnly, fullkey } = args;
    const date = (value && new Date(value)) || null;

    return args.embedObject(
        <LazyLocaleDatePicker
            key={fullkey}
            disabled={readOnly}
            showTimeSelect
            selected={date}
            timeFormat="p"
            dateFormat="Pp"
            timeIntervals={15}
            onChange={(a: any) => args.update({ value: new Date(a).toJSON() })}
        />,
        { useFlex: true }
    );
}

registerComponentHandler("date", addDate)




/**
 * Add a button. The button will toogle (true/false) the value if no setValue handler is provided
 * 
 * @param args 
 * @returns 
 */
function addButton(args: IUiSchemaElemArgs) {

    const { value, readOnly, key, fullkey, values, uiElem } = args;
    const size = uiElem.type === "button-small" ? "sm" : "";

    return args.embedObject((
        <Button variant="outline-dark" key={fullkey} disabled={readOnly} size={size as any}
            id={"switch_" + key}
            onClick={() =>  {!readOnly && args.update({ value: !values[key] }); }}
        >
            {args.stringToComponent(value)}
        </Button>),
        { useFlex: true}
    );
}

registerComponentHandler("button", addButton)
registerComponentHandler("button-small", addButton)





/**
 * Add a basic HTML table with the data from an object
 * 
 * @param args 
 * @returns 
 */

function addTable(args: IUiSchemaElemArgs) {

    const { value, fullkey } = args;

    const header: string[] = value?.header || [];
    const body: string[][] = value?.body || [];

    const tableJsx = (
        <Table key={fullkey} className="schema-engine-table">
            <thead className="schema-engine-table-header">
                <tr>{header.map((a, i) => <th key={"idx" + i}>{a}</th>)}</tr>
            </thead>
            <tbody>
                {body.map((row, i) => (<tr key={"row" + i}>{row.map((col, i) => <td key={"col" + i}>{typeof col === "number" ? String(col) : col}</td>)}</tr>))}
            </tbody>
        </Table>
    )

    return args.embedObject(tableJsx, { useFlex: true});
}

registerComponentHandler("table", addTable)



/**
 * Add an iframe using the value object in the following way:
 * 
 * value: { 
 *     src: "url",
 *     width?: number | string;
 *     height?: number | string;
 * }
 * 
 * @param args 
 * @returns 
 */
function addIFrame(args: IUiSchemaElemArgs) {

    const { value, fullkey, uiElem } = args;
    const src = value.src;
    const width = value.width != null ? value.width : "100%";
    const height = value.height != null ? value.height :
            uiElem.multiLine != null ? uiElem.multiLine * 12 + "px" : undefined;

    return args.embedObject(
        (<iframe title={fullkey} key={"iframe_" + fullkey} src={src} width={width} height={height}/>),
        { useFlex: true, isContainer: true });
};

registerComponentHandler("iframe", addIFrame);





/**
 * Show image
 * 
 * value: string (url)
 * 
 * or
 * 
 * value: {
 *    src: string (url),
 *    width?: number | string;
 *    height?: number | string;
 * }
 * 
 * 
 * @param args 
 * @returns 
 */
function addShowImage(args: IUiSchemaElemArgs) {

    const { value, fullkey, uiElem } = args;

    if (!value) { return null; }

    const src    = typeof value === "string" ? value : value.src;
    const width  = typeof value === "object" && value.width != null ? value.width : undefined;
    const height = typeof value === "object" && value.height != null ? value.height :
                    uiElem.multiLine != null ? uiElem.multiLine * 12 + "px" : undefined;

    return args.embedObject(
        <div><img className="img-thumbnail ow-device-profile-image" key={fullkey} src={src} width={width} height={height} /></div>
    );
};

registerComponentHandler("image", addShowImage);






/**
 * addInput handles all FORM input type of object.
 * 
 * @param args 
 * @returns 
 */

function addInput(args: IUiSchemaElemArgs) {

    const debugKey = "";
    const { objects, value, elem, uiElem, fullkey, readOnly, error, self } = args;
    const lib = self.lib;
    let   type = uiElem.type || (typeof elem.type === "string" ? elem.type  : "");
    const hidden = !!(args?.uiElem?.security === "secret" || args?.uiElem?.security === "sensitive");


    // The form handling is pretty complex due to performance issues.
    //
    // The issue is that the "normal" react model using a onChange function paired with the value input to the component
    // doesn't work well for form input as this will cause a complete rerender at schema level for each keypress.
    // To avoid that, the SchemaForm component maintain its own value state, but callback to here for each input. The
    // input can be filtered and error checked, etc. and the value is passed back to the component. The global state of
    // the schema however is not updated on each keypress. Instead a 500ms timer is maintained to aggregate fast key types
    // and update the state only after finishing typing.
    //
    // The following out-of-state variables are maintained:
    // this.formInnerState[fullkey + "$text"]   current content of input form
    // this.formInnerState[fullkey + "$value"]  current value (i.e. integer etc) of content of input, ready to be set to state
    // this.formInnerState[fullkey + "$lastSetValue"]   last value that was set to state, meaning what should be the current state
    // this.formInnerState[fullkey + "$context"]        counter updated on every update. Allow the SchemaForm to detect update
    // this.formInnerState[fullkey + "$time"]           time used to update every 500ms.
    //
    self.formInnerState[fullkey + "$context"] = (self.formInnerState[fullkey + "$context"] || 0) + 1;

    const debugInnerState = (where: string) => {
        if (debugKey && fullkey === debugKey) {
            console.log(where, fullkey, value, self.formInnerState[fullkey + "$text"],
                self.formInnerState[fullkey + "$value"], self.formInnerState[fullkey + "$lastSetValue"],
                self.formInnerState[fullkey + "$context"]);
        }
    }
    const safeIsEq = (a: any, b: any) => {
        if (typeof a !== typeof b) { return false; }
        if (typeof a === "number" && isNaN(a) && isNaN(b)) { return true; }	// this is the weird case
        return a === b;
    }
    const safeParseNumber = (str: string, radix?: number) => {
        let res = radix ? parseInt(str, radix) : parseFloat(str);
        return isNaN(res) ? 0 : res;
    }
    const safeToString = (value: any, radix?: number) => {
        if (typeof value === "number") { return isNaN(value) ? "" : (radix ? value.toString(radix) : value.toString()) }
        return (value || "").toString();
    }


    debugInnerState("entry");
    if (self.formInnerState[fullkey + "$text"] != null && (
            self.formInnerState[fullkey + "$lastSetValue"] == null ||
            !safeIsEq(self.formInnerState[fullkey + "$lastSetValue"], value))) {
        clearTimeout(self.formInnerState[fullkey + "$timer"]);
        self.formInnerState[fullkey + "$timer"] = null;
        self.formInnerState[fullkey + "$text"] = null;
        self.formInnerState[fullkey + "$lastSetValue"] = null;

        debugInnerState("clear");
    }

    let cnvVal: string = value == null ? "" 
                        : type === "hex" ? safeToString(value, 16)
                            : type.endsWith("object-json") ? JSON.stringify(value, null, 4)
                                : safeToString(value);
    let strVal: string = self.formInnerState[fullkey + "$text"] != null
                        ? self.formInnerState[fullkey + "$text"]
                        : cnvVal;

    const nullValue = () => {
        return uiElem.treatEmptyAs 
                ? evalExpr(uiElem.trust, uiElem.treatEmptyAs, lib, objects, { fullkey, value, error, readOnly, schema: elem }, null) 
                : null;
    }

    const onTextChange = (text: string) => {

        if (readOnly) { return null; }

        const type = uiElem.type || (typeof elem.type === "string" ? elem.type  : "");
        const stringVal: string = text != null ? text + "" : "";
        let   targetVal: string | number | null;

        if (uiElem.inputFilterPattern && !stringVal.match(new RegExp(uiElem.inputFilterPattern))) {
            return null;
        }
        if (typeof elem.maxLength === "number" && stringVal.length > elem.maxLength) { return null; }

        if (type === "integer" && stringVal !== "" && !stringVal.match(/^[-+]?[0-9]*$/)) { return null; }
        if (type === "number"  && stringVal !== "" && !stringVal.match(/^[-+]?[0-9]*[.]?[0-9]*[eE]?[-+]?[0-9]*$/)) { return null; }
        if (type === "hex"     && stringVal !== "" && !stringVal.match(/^[0-9a-fA-F]*$/)) { return null; }

        const fKey = fullkey.replace(/[.]/g, "/");
        self.innerStates[fKey] = {...self.innerStates[fKey], modified: true };
        
        delete (self.innerStates[fKey] as any)?.error;	// FIXME: type hack

        if (type === "hex") {
            targetVal = stringVal === "" ? nullValue() : safeParseNumber(stringVal, 16);
        } else if (type === "integer" || type === "number") {
            targetVal = stringVal === "" ? nullValue() : safeParseNumber(stringVal);
        } else if (type.endsWith("object-json")) {
            try {
                targetVal = JSON.parse(stringVal);
            } catch (e) {
                targetVal = {} as any;
                self.innerStates[fKey] = {...self.innerStates[fKey], error: "Invalid JSON" };
            }
        } else {
            targetVal = (stringVal === "" && uiElem.treatEmptyAs) ? nullValue() : stringVal;
        }

        // when the user is typing 
        if (typeof targetVal === "number" && isNaN(targetVal)) { targetVal = 0;}


        // Doing delayed update
        self.formInnerState[fullkey + "$text"]  = text;
        self.formInnerState[fullkey + "$value"] = targetVal;


        debugInnerState("update");
        clearTimeout(self.formInnerState[fullkey + "$timer"]);
        self.formInnerState[fullkey + "$timer"] = setTimeout(() => {
            self.formInnerState[fullkey + "$timer"] = null;
            self.formInnerState[fullkey + "$lastSetValue"] = self.formInnerState[fullkey + "$value"];
            debugInnerState("flush");
            args.update({ value: self.formInnerState[fullkey + "$value"] });
        }, 500)

        return text;
    };
    const onBlur = () => {
        if (self.formInnerState[fullkey + "$timer"]) {
            clearTimeout(self.formInnerState[fullkey + "$timer"]);
            self.formInnerState[fullkey + "$timer"] = null;
            self.formInnerState[fullkey + "$text"] = null;			// FIXME: why clear this??

            self.formInnerState[fullkey + "$lastSetValue"] = self.formInnerState[fullkey + "$value"];
            debugInnerState("blur");

            args.update({ value: self.formInnerState[fullkey + "$value"] });
        }
    }

    const props: ISchemaFormProps = {
        args,
        onBlur,
        value: strVal,
        onTextChange,
        hidden,
        context: self.formInnerState[fullkey + "$context"]
    };

    // Default is the SchemaForm component, but if there is another FormObject registered on the type, wy use that
    const Form = registeredExtensionFormsComponents[type] || SchemaForm;

    return args.embedObject(<Form key={"input-" + fullkey} {...props} />);
};


registerComponentHandler("number", addInput);
registerComponentHandler("integer", addInput);
registerComponentHandler("string", addInput);
registerComponentHandler("old-password", addInput);
registerComponentHandler("new-password", addInput);
registerComponentHandler("hex", addInput);
registerComponentHandler("object-json", addInput);

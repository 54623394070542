import React, { useEffect, useState } from "react";
import { faInfoCircle, faCircleExclamation, faPlay, faStop, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowTurnDownRight } from "@fortawesome/pro-thin-svg-icons"
import "./LiveLog.css"
import { IConstants } from "src/types";
import { PageHeader } from "../../components/Common";
import { BreadCrumbType, PageButtonType } from '../../datatypes/datatypes';
import { useRec, useWebSocket } from "./WebSocketLiveLog";
import NoDataImage from '../../components/Common/DataTable/empty-logs.png';
import { Button } from 'react-bootstrap';
import { ObjectInspector } from "react-inspector";
import { useNavigate } from "react-router-dom";
import { GenericDassQuery } from "../../services/BasicDassQueries";
import { dateTimeString } from "../../../src/utils/filters";
import { strings } from "./../../services/Localization";
import AppContext from "../../context/AppContext";
import { getDataFromSessionStorage, removeDataFromSessionStorage, setDataToSessionStorage } from "../../../src/utils/sessionStorage";
import { openDB, clearDataInDB, readDataFromDB, processArray } from "./LiveLogUtils";



declare const constants: IConstants;

const LogLevelIcon = {
  "error": { "icon": faCircleExclamation, "color": "red" },
  "info": { "icon": faInfoCircle, "color": "blue" },
  "verbose": { "icon": faInfoCircle, "color": "green" },
  "debug": { "icon": faInfoCircle, "color": "grey" },
  "code": { "icon": faInfoCircle, "color": "grey" },
};

export interface LiveLogRecord {
  meta: {
    level: "error" | "info" | "verbose" | "debug";
    connectionUuid?: string;
    deviceUuid?: string;
    event?: string;
    lambda?: string;
    stats?: any;
    timestamp: Date;
  };
  log: (string | object)[];
}

const ShowNoDataLiveLog = (status) => {
  const data = status === "false" ? "Live log not active, let's start recording." : "Live log is active, please wait.";
  return <div className='mb-2' dangerouslySetInnerHTML={{ __html: data }} />;
};

const showButton = (button: PageButtonType) => {
  if (button) {
    return (
      <div style={{ margin: '1px', float: 'left' }}>
        <Button size='sm' className="text-nowrap" title={button.title} variant="dark" onClick={button.action}>
          {button.icon && <FontAwesomeIcon className="mr-2" icon={button.icon} />}
          {button.title}
        </Button>
      </div>
    );
  } else {
    return null;
  }
};


export const LiveLog: React.FC<{}> = () => {
  const navigate = useNavigate();
  const { startWebSocket, stopWebSocket } = useWebSocket();
  const rec = useRec();
  const [logArr, setLogArr] = useState<any[]>([]);
  const [wsStatus, setWsStatus] = useState("false");
  const [connection, setConnection] = useState({});
  const [searchTerm, setSearchTerm] = useState("");

  const AppContextObj = React.useContext(AppContext);

  const detailPageNav = (navigate, url, id) => {
    navigate(url, { state: { row: { id }, prevPageUrl: `/app/livelog` } });
  };

  const handleWebSocket = (key) => {
    if (key) {
      startWebSocket(true);
      setWsStatus("true");
    } else {
      removeDataFromSessionStorage("liveloguser");
      stopWebSocket(true);
      setWsStatus("false");
    }
  };

  const clearLiveLog = async () => {
    localStorage.setItem('liveLogData', JSON.stringify([]));
    const db = await openDB("Log", "livelog");
    await clearDataInDB(db, "livelog");
    setLogArr([]);
  };

  const gotLogMessage = (rec: LiveLogRecord) => {
    const line: any = [];
    if (rec) {
      let keyIdx = 1;
      if (rec.meta) {
        line.push(
          <div key={keyIdx++} style={{ display: 'flex', alignItems: 'center' }}>
            <FontAwesomeIcon title={rec.meta.level} icon={LogLevelIcon[rec.meta.level].icon} color={LogLevelIcon[rec.meta.level].color} size="xl" style={{ marginRight: '3px' }} />
            {rec.meta.timestamp && (<span className="badge livelog-date" title="timestamp" key={keyIdx++}> {dateTimeString(rec.meta.timestamp + "")}</span>)}
            {rec.meta.event && (<span className="badge livelog-event" title="event" key={keyIdx++}> {rec.meta.event}</span>)}
            {rec.meta.lambda && (<span className="badge livelog-lamdha" title="lambda" key={keyIdx++}> {rec.meta.lambda}</span>)}
            {rec.meta.connectionUuid && (
              <span
                key={keyIdx++}
                className="badge livelog-connectionUuid"
                title="connectionUuid"
                onClick={() => detailPageNav(navigate, `/app/connections/${rec.meta.connectionUuid}/edit-profile`, rec.meta.connectionUuid)}
                style={{ cursor: 'pointer' }}
              >
                {connection[rec.meta.connectionUuid]?.profile_name ?? rec.meta.connectionUuid}
              </span>
            )}
            {rec.meta.deviceUuid && (
              <span
                key={keyIdx++}
                className="badge livelog-deviceUuid"
                title="deviceUuid"
                onClick={() => detailPageNav(navigate, `/app/dmp-devices/${rec.meta.deviceUuid}/device-detail`, rec.meta.deviceUuid)}
                style={{ cursor: 'pointer' }}
              >
                {rec.meta.deviceUuid}
              </span>
            )}
            {rec.log[1]?.['error'] && (
              <span key={keyIdx++} className="badge livelog-error">
                {rec.log[1]['error']}
              </span>
            )}
          </div>
        );
        line.push(
          <div key={keyIdx++} style={{ display: 'flex', alignItems: 'center' }}>
            <FontAwesomeIcon title={rec.meta.level} icon={faArrowTurnDownRight} size="xl" style={{ marginRight: '3px' }} />
            <ObjectInspector key={keyIdx++} name={"META"} data={rec.meta} depth={2} />
          </div>
        );
      }
      if (rec.log) {
        line.push(
          <div key={keyIdx++} style={{ display: 'flex', alignItems: 'center' }}>
            <FontAwesomeIcon title={rec.meta.level} icon={faArrowTurnDownRight} size="xl" style={{ marginRight: '3px' }} />
            <ObjectInspector key={keyIdx++} name={"LOG"} data={processArray(rec.log)} depth={2} />
          </div>
        );
      }
      setLogArr((prev) => [<div key={prev.length} className="livelog-div">{line}</div>, ...prev]);
    }
  };

  const pageButtons: PageButtonType[] = [
    { title: "Start Log", type: 'button_with_icon', action: () => handleWebSocket(true), icon: faPlay, visible: () => wsStatus === "false" },
    { title: "Stop Log", type: 'button_with_icon', action: () => handleWebSocket(false), icon: faStop, visible: () => wsStatus === "true" },
    { title: "Clear", type: 'button_with_icon', action: () => clearLiveLog(), icon: faXmark }
  ];

  const breadCrumbArr: BreadCrumbType[] = [{ label: strings.NAV_DEVELOPER, url: '' }, { label: strings.NAV_LIVELOG_ITEM, url: '' }];
  let webSocketStatus;

  useEffect(() => {
    gotLogMessage(rec);
  }, [rec]);

  useEffect(() => { }, [wsStatus]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const connection_list = await GenericDassQuery(`/rest/connections`);
        const connection_map = connection_list.data.reduce((acc, v) => { acc[v.profile_uuid] = v; return acc; }, {});
        setConnection(connection_map);
        const db = await openDB("Log", "livelog");
        const data = await readDataFromDB(db, "livelog");

        if (Array.isArray(data) && data.length > 0) {
          data.forEach(log => gotLogMessage(log));
        }
        webSocketStatus = localStorage.getItem('websocket_status') ?? "false";
        setWsStatus(webSocketStatus);
      } catch (error) {
        console.error('Error:', error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    if(AppContextObj?.user?.userid !== getDataFromSessionStorage("liveloguser")) {
      clearLiveLog();
      setDataToSessionStorage("liveloguser", AppContextObj.user.userid)
    }
  }, [AppContextObj.user])

  const filteredLogs = logArr.filter(log => {
    if (!searchTerm) return true;
    return JSON.stringify(log.props.children).toLowerCase().includes(searchTerm.toLowerCase());
  });

  return (
    <div className="child-tab-wrapper">
      <PageHeader breadCrumbArr={breadCrumbArr} pageButtons={pageButtons} />
      <input
          type="text"
          placeholder="Search logs..."
          value={searchTerm}
          onChange={e => setSearchTerm(e.target.value)}
          className="log-search-bar"
        />
      <div className="schema-engine-simple-page">
        {filteredLogs.length === 0 ? (
          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
            <img src={NoDataImage} alt="No Data" />
            {ShowNoDataLiveLog(wsStatus)}
            <br />
            {wsStatus === "false" && showButton(pageButtons[0])}
          </div>
        ) : (
          filteredLogs
        )}
      </div>
    </div>
  );
};


import { IConstants } from "src/types";

declare const constants: IConstants;
declare const headerContentTypeJson;
declare const headerNoContentType;
import { appBaseUrl } from '../utils/consts';
import { strings } from "./Localization";
import { /*gunzip,*/ gunzipSync } from "fflate";
import { waitDialog } from "../components/Common/ConfirmDialog";



export interface IRequestReturnData {
	response: Response;
	abort: () => void;
}

export type IRequestMethod = "PUT" | "GET" | "POST" | "DELETE";
interface IGetDataOptions {
	query?: any;
	method?: IRequestMethod;
	data?: object | string;
	headers?: any;
	prefix?: string; // for debug
	waitOn429?: boolean;
	waitOn429Type?: "throttle" | "password";
	resigninOn401?: boolean;

	chunkCallback?: (data: string, chunkIdx: number ) => Promise<void>;
	returnData?: IRequestReturnData
}

export function objToQueryString(queryObj: {[index: string]: string }, append?: boolean) {
    let query = "";
    for (const key of Object.keys(queryObj || {})) {
        if (queryObj[key] != null) {
            query += (query || append ? "&" : "?") + key + "=" + encodeURIComponent(queryObj[key] + "");
        }
    }
    return query;
}

export const getDassInfo = async () => {
	await constants.wait;
	const response = await fetch("/dass_info", {
		credentials: "same-origin",
		headers: headerNoContentType,
		method: "GET",
	});

	if (response.status > 299) {
		throw { status: response.status, message: await response.text() };
	}
	return { status: response.status, data: await response.json() };
};


export async function decompressBase64(base64: string) {
    const binaryString = atob(base64);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
        bytes[i] = binaryString.charCodeAt(i);
    }

	return new TextDecoder('utf-8').decode(gunzipSync(bytes));


/* the async version was used but was having issues and was replaced with the sync version.

	return new Promise<any>((resolve, reject) => {
		gunzip(bytes, (err, data) => {
			if (err) { reject(err); }
			resolve(new TextDecoder('utf-8').decode(data));
		})
	});
*/

	// This is the code using the native browser decoder. Unfortinately this native API is only
	// from 2022 so it is too new to rely on.
/*
	const blob = new Blob([bytes.buffer], {type: 'application/octet-stream'});
	const ds = new DecompressionStream('gzip');
    const decompressedStream = blob.stream().pipeThrough(ds);
	return await new Response(decompressedStream).text();
*/
}


export const GenericDassQuery = async (dassPath: string, options?: IGetDataOptions) => {
	
	const langMatch = decodeURIComponent(document.cookie).match(/language=([a-zA-Z]+)/);
	const lang = langMatch ? langMatch[1] : "en";

	const query = { lang, ...options?.query }
	const url = (options?.prefix != null ? options.prefix : "/uiapi") +
				 dassPath + objToQueryString(query, dassPath.indexOf("?") > 0);
	const headers = { ...(options && options.headers),  ...headerNoContentType };
	const body: any = options && options.data && (typeof options.data === "object" && !(options.data instanceof FormData) ? JSON.stringify(options.data) : options.data);
	let abort = false;

	if (!headers["Content-Type"] && options && options.data && !(options.data instanceof FormData)) {
		headers["Content-Type"] = typeof options.data === "object" ? "application/json" : "text/plain";
	}

	const abortController = new AbortController();

	// Optionally return some additional data to the caller, such as the response object.
	if (options?.returnData) {
		options.returnData.abort = () => { 
			// console.log("ABORTING HERE", dassPath);
			abort = true;
			abortController.abort(400) 
		};
	}

	await constants.wait;
	let response: Response;
	for (;;) {
		response = await fetch(url, {
			signal: abortController.signal,
			credentials: "same-origin",
			headers,
			method: options && options.method || "GET",
			body,
		});

		if (response.status == 429  &&  options.waitOn429 !== false) {
			const text = await response.text();
			const status = response.status;
			const retryAfter = response.headers.get("retry-after");
			const time = retryAfter && new Date(retryAfter);

			if (time  &&  await waitDialog(time.getTime() + 5000, options.waitOn429Type || "throttle")) { continue; }
			throw { status, message: text ? text : strings.ACCESS_DENIED_MESSAGE };
		}
		break;
	}

	const status = response.status;
	let data: any;


	if (status == 403) {
		data = await response.text();
		if (data.toLowerCase().includes("invalid csrf token")) {
			window.location.reload();
		}
		throw { status, message: data ? data : strings.ACCESS_DENIED_MESSAGE };
	}
	if (status == 401  &&  options?.resigninOn401 !== false) { // the status should be changed!!!
		data = await response.text();
		window.location.href = appBaseUrl + "/signout?resignin";
		console.log("Ran too far")
		return { status, data };
	}

	// Optionally return some additional data to the caller, such as the response object.
	if (options?.returnData) {
		options.returnData.response = response;
	}

	const contentType = response.headers.get("content-type");
	if (contentType?.includes("text/event-stream") && options?.chunkCallback) {

		const decoder = new TextDecoder("utf-8");
		const reader = response.body.getReader();
		let chunkIdx = 0;

		try {

			let body = "";
			let idx = -1;

			while (true) {

				const { done, value } = await reader.read();
				body += decoder.decode(value, { stream: true }) + (done ? decoder.decode() : "");

				while ((idx = body.indexOf("\n\n")) >= 0) {
					const block = body.substring(0, idx);
					body = body.substring(idx + 2);

					for (const evt of block.split("\n")) {

						if (abort) { break; }
						if (evt.startsWith("data: ")) {
							const chunk = evt.substring(6).startsWith("gzip,base64:") 
									? await decompressBase64(evt.substring(6 + 12))
									: evt.substring(6);

							await options.chunkCallback(chunk, chunkIdx);
							chunkIdx++;
						}
					}
				}
				if (done) { break; }
			}

		} catch (e) {
			console.log("Error in GenericDassQuery", e);
			throw { status: 400, message: e.message };
		}

	} else if (contentType?.indexOf("application/json") >= 0) {
		data = await response.json();
	} else {
		data = await response.text();
	}

	if (status > 299) {
		throw { status, message: status >= 500 ? strings.SYSTEM_UNREACHABLE_MESSAGE : data };
	}

	return { status, data };
};
